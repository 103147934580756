import { ClockIcon } from '@heroicons/react/20/solid'
import { fetchQuery, graphql } from 'relay-runtime'
import Button, { ButtonVariant } from '../../buttons/button/button'
import { useMutation, useRelayEnvironment } from 'react-relay'
import { Enum_Rfq_Option_Reason_Enum, Enum_Rfq_Status_Enum } from '../../../gql'
import getIDFromBase64 from '../../../utils/getIDFromBase64/getIDFromBase64'
import { clearExpiredRfqsGetExpiredRfqsQuery } from './__generated__/clearExpiredRfqsGetExpiredRfqsQuery.graphql'
import toast from 'react-hot-toast'
import isEmpty from 'lodash/isEmpty'
import { clearExpiredRfqsMutation } from './__generated__/clearExpiredRfqsMutation.graphql'

/* eslint-disable relay/unused-fields */
export const ClearExpiredRfqsGetExpiredRfqsQuery = graphql`
  query clearExpiredRfqsGetExpiredRfqsQuery {
    rfq_connection(
      where: {
        expireAt: { _lt: "now()" }
        status: { _in: [NewOrder, Quote, QuoteRequest, QuoteRequestAck] }
      }
    ) {
      edges {
        node {
          id
          axeId
          axeOwnerUserId
          rfq_options(order_by: { createdAt: desc }, limit: 1) {
            id
            rfq_options_legs {
              bsDelta
              delta
              pricingVolatility
              premium
              rfqLegId
            }
          }
        }
      }
    }
  }
`
/* eslint-enable relay/unused-fields */

export const ClearExpiredRfqsMutation = graphql`
  mutation clearExpiredRfqsMutation(
    $rfqOptions: [rfq_options_insert_input!]!
    $rfqs: [rfq_updates!]!
    $rfqPublicQueue: [rfq_public_queue_bool_exp!]!
  ) {
    insert_rfq_options(objects: $rfqOptions) {
      returning {
        id
      }
    }
    update_rfq_many(updates: $rfqs) {
      returning {
        id
      }
    }
    delete_rfq_public_queue(where: { _or: $rfqPublicQueue }) {
      returning {
        id
      }
    }
  }
`

type RfqEdge =
  clearExpiredRfqsGetExpiredRfqsQuery['response']['rfq_connection']['edges'][0]

const updateRfqAsRejected = (rfqs: readonly RfqEdge[]) =>
  rfqs.map(({ node: { id } }) => ({
    where: { id: { _eq: getIDFromBase64(id) } },
    _set: { status: Enum_Rfq_Status_Enum.QuoteRequestReject }
  }))

const createExpiredRfqOption = (rfqs: readonly RfqEdge[]) =>
  rfqs.map(({ node: { id, axeOwnerUserId, rfq_options } }) => ({
    rfqId: getIDFromBase64(id),
    status: Enum_Rfq_Status_Enum.QuoteRequestReject,
    reason: Enum_Rfq_Option_Reason_Enum.Expired,
    rfq_options_legs: {
      data: rfq_options[0].rfq_options_legs
    },
    userId: axeOwnerUserId
  }))

const deleteRfqFromPublicQueue = (rfqs: readonly RfqEdge[]) =>
  rfqs.map(({ node: { id, axeId } }) => ({
    rfqId: { _eq: getIDFromBase64(id) },
    axeId: { _eq: axeId }
  }))

function ClearExpiredRfqsButton() {
  const environment = useRelayEnvironment()

  const [commitMutation] = useMutation<clearExpiredRfqsMutation>(
    ClearExpiredRfqsMutation
  )

  async function handleClick() {
    toast.promise(
      new Promise((resolve, reject) => {
        fetchQuery<clearExpiredRfqsGetExpiredRfqsQuery>(
          environment,
          ClearExpiredRfqsGetExpiredRfqsQuery,
          {}
        )
          .toPromise()
          .then((data) => {
            if (!data || isEmpty(data.rfq_connection.edges)) {
              reject('No RFQs found awaiting expiry.')

              return
            }

            const rfqs: readonly RfqEdge[] = data.rfq_connection.edges

            commitMutation({
              variables: {
                rfqs: updateRfqAsRejected(rfqs),
                rfqOptions: createExpiredRfqOption(rfqs),
                rfqPublicQueue: deleteRfqFromPublicQueue(rfqs)
              },
              onCompleted: resolve,
              onError: (err) => {
                console.error(err)
                reject('Failed to expire RFQs.')
              }
            })
          })
          .catch((error) => {
            console.error(error)
            reject('Failed to fetch RFQs awaiting expiry.')
          })
      }),
      {
        loading: 'Expiring RFQs...',
        success: 'All pending RFQs expired.',
        error: (err) => err.toString()
      }
    )
  }

  return (
    <Button
      className="flex justify-center rounded-lg px-2 h-[44px] items-center relative"
      key="quickAxeButton"
      styleVariant={ButtonVariant.PRIMARY}
      onClick={handleClick}
    >
      <ClockIcon className="flex" height="24px" width="24px" color="white" />
    </Button>
  )
}

export default ClearExpiredRfqsButton

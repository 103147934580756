import get from 'lodash/get'
import set from 'lodash/set'
import { InputProps } from '../../../../input/input'
import { OnValuesDiffParams } from '../../config'
import { AxeFormData } from '../../../../dialog/updateAxeDialog/updateAxeDialog'
import isLegFieldPath from '../../../utils/isLegFieldPath/isLegFieldPath'

const setSameAcrossAllLegs = ({
  formik: { values },
  inputStateContext: { dispatch, state },
  path
}: Pick<OnValuesDiffParams, 'formik' | 'inputStateContext' | 'path'>) => {
  if (!isLegFieldPath(path)) return null

  const [arrayName, , fieldName] = path

  const valuesToUpdate = {} as AxeFormData

  // Generate an array of indices starting from the second leg.
  // For example, if there are 3 legs, the array will be [1, 2]. If there is only 1 leg, the array will be empty.
  const legIndexesToUpdate = Array.from(
    { length: values.legs.length - 1 },
    (_, i) => i + 1
  )

  if (legIndexesToUpdate.length > 0) {
    const fieldValue = get(values, [arrayName, 0, fieldName])
    const sourceFieldName = `${arrayName}[0].${fieldName}` as InputProps['name']
    const sourceFieldState = state[sourceFieldName]

    // For each leg to update, set the field value to match the first leg's value and mark the field as disabled.
    legIndexesToUpdate.forEach((legIndexToUpdate) => {
      set(valuesToUpdate, [arrayName, legIndexToUpdate, fieldName], fieldValue)

      const key =
        `${arrayName}[${legIndexToUpdate}].${fieldName}` as InputProps['name']

      const fieldState = state[key]

      if (!fieldState?.isDisabled) {
        dispatch({ type: 'set_is_disabled', key })
      }

      if (!sourceFieldState?.overTyped) {
        dispatch({ type: 'unset_reset_request', key })

        return
      }

      if (sourceFieldState?.overTyped && !fieldState?.overTyped) {
        dispatch({ type: 'set_over_typed', key, payload: fieldValue })
      }

      if (
        sourceFieldState?.containsFetchedValue &&
        !fieldState?.containsFetchedValue
      ) {
        dispatch({ type: 'set_contains_fetched_value', key })
      }
    })
  }

  return {
    values: valuesToUpdate
  }
}

export default setSameAcrossAllLegs

import isNil from 'lodash/isNil'
import set from 'lodash/set'
import {
  AxeFormLegData,
  AxeFormData
} from '../../../../dialog/updateAxeDialog/updateAxeDialog'
import isLegFieldPath from '../../../utils/isLegFieldPath/isLegFieldPath'
import { OnValuesDiffParams } from '../../config'
import {
  getPartyBuySell,
  putGammaAndVegaInCorrectSign,
  putPremiumCorrectSign,
  putDeltaCorrectSign
} from '@optaxe/options-utils'

export default function processOnBuySellDiff({
  formik: { values },
  path,
  inputStateContext: { dispatch },
  formType,
  isUserCounterParty
}: OnValuesDiffParams) {
  if (!isLegFieldPath(path)) return null

  const [arrayName, legIndex] = path
  const sourceLegValues = values.legs[legIndex] as AxeFormLegData
  const { buySell, callPut } = sourceLegValues

  // Override the buySell value if the user viewing the form is the counterparty.
  if (isUserCounterParty) {
    const partyBasedBuySell = getPartyBuySell(!isUserCounterParty, buySell)

    dispatch({
      type: 'set_party_based_value',
      key: `${arrayName}[${legIndex}].buySell`,
      payload: partyBasedBuySell
    })
  }

  if (formType === 'rfq') return null

  const valuesToUpdate = {} as AxeFormData

  const directionBasedFieldNames: Array<keyof AxeFormLegData> = [
    'delta',
    'premium',
    'gamma',
    'vega'
  ]

  // When the buySell value changes, update the sign of the premium, delta, gamma, and vega values.
  // For each field we want updated, check if the field has a value and update it with the correct sign based on the buySell (and callPut) values.
  directionBasedFieldNames.forEach((fieldName) => {
    const value = sourceLegValues[fieldName]

    if (isNil(value)) return

    const isGammaOrVega = ['gamma', 'vega'].includes(fieldName)

    // Delta need to be corrected based on both buySell and callPut values.
    // Gamma and vega need to be corrected based on the buySell value only.
    // The premium is corrected based on the buySell value only but its in reverse of the Gamma/Vega.
    // Reference: /docs/directionRules.md Rules
    // Reference: https://linear.app/optaxe/issue/OPT-1446/bug-check-gamma-and-vega-signing-when-changing-the-buysell-on-an-axe#comment-c1413ef4
    if (isGammaOrVega) {
      set(
        valuesToUpdate,
        [arrayName, legIndex, fieldName],
        putGammaAndVegaInCorrectSign(value, buySell)
      )
    } else if (fieldName === 'premium') {
      set(
        valuesToUpdate,
        [arrayName, legIndex, fieldName],
        putPremiumCorrectSign(value, buySell)
      )
    } else {
      set(
        valuesToUpdate,
        [arrayName, legIndex, fieldName],
        putDeltaCorrectSign(value, callPut, buySell)
      )
    }
  })

  return {
    values: valuesToUpdate
  }
}

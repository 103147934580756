import {
  InputProps,
  FieldArrayPrefix,
  FieldArrayName
} from '../../components/input/input'

const defaultResult = {
  arrayPrefix: null,
  arrayName: null,
  arrayIndex: null,
  fieldName: null
}

export function extractArrayReferenceFromFieldName(
  lastChangedField?: InputProps['name']
) {
  if (!lastChangedField) {
    return defaultResult
  }

  const regex = /^((\w+)\[(\d+)\])?\.(\w+)$/
  const match = lastChangedField.match(regex)

  if (!match) {
    return defaultResult
  }

  return {
    arrayPrefix: match[1] as FieldArrayPrefix,
    arrayName: match[2] as FieldArrayName,
    arrayIndex: parseInt(match[3], 10),
    fieldName: match[4]
  }
}

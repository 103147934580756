import React from 'react'
import { useWorkerContext } from '../../../context/marketDataWorkerContext/marketDataWorkerContext'
import { useFormikContext } from 'formik'
import { InputProps } from '../../input/input'
import capitalize from 'lodash/capitalize'
import { useInputStateContext } from '../../../context/inputStateContext/inputStateContext'
import RefreshIcon, { IconVariant } from '../../icons/refreshIcon/refreshIcon'
import { AxeFormData } from '../../dialog/updateAxeDialog/updateAxeDialog'
import classNames from 'classnames'

interface RefreshButtonProps {
  className?: string
  name: InputProps['name']
}

export default function RefreshButton({ className, name }: RefreshButtonProps) {
  const { getMarketValues, workerFetching } = useWorkerContext()
  const { values } = useFormikContext<AxeFormData>()
  const { state, dispatch } = useInputStateContext()

  const { containsFetchedValue } = state[name] || {}
  const [fetching, setFetching] = React.useState(false)

  /**
   * As we want to only animate the refresh button when this specific component
   * is fetching, it needs its own local fetching state to track this
   */
  React.useEffect(() => {
    const { resetRequest, targetFields } = state[name] || {}

    if (resetRequest && workerFetching) {
      setFetching(true)
    }

    if (resetRequest && fetching && !workerFetching) {
      dispatch({
        type: 'unset_reset_request',
        key: name as InputProps['name']
      })

      // If the related field has target fields that are overTyped, reset them.
      targetFields?.forEach((targetField) => {
        if (state[targetField.name]?.overTyped) {
          dispatch({
            type: 'unset_reset_request',
            key: targetField.name as InputProps['name']
          })
        }
      })

      setFetching(false)
    }
  }, [fetching, dispatch, name, workerFetching, state])

  const handleFetch = () => {
    dispatch({
      type: 'set_reset_request',
      key: name as InputProps['name']
    })

    if (values) {
      getMarketValues({
        refreshField: name as InputProps['name'],
        values
      })
    }
  }

  // Capitalize the first letter of the input name
  const nameCapitalized = name && capitalize(name)

  const isDisabled = !containsFetchedValue

  return (
    <button
      type="button"
      title={`Refresh ${nameCapitalized}`}
      onClick={handleFetch}
      disabled={isDisabled}
      className={classNames(
        'h-full flex items-center justify-center pl-[2px]',
        className
      )}
    >
      <RefreshIcon
        isDisabled={isDisabled}
        isFetching={fetching}
        styleVariant={IconVariant.PRIMARY}
      />
    </button>
  )
}

import { Tier } from '../../components/tierSelector/tierSelector'
import { user_tiers_insert_input } from '../../components/buttons/createAxeDialogButton/__generated__/createAxeDialogButtonMutation.graphql'
import getIDFromBase64 from '../getIDFromBase64/getIDFromBase64'

export const createAxeUserTiersForAxe = (
  axeId: string,
  userId: string,
  tiers: Tier[],
  removedSubOrgIds: string[],
  recreatingAxe: boolean = false
): user_tiers_insert_input[] => {
  const userTiers: user_tiers_insert_input[] = []
  tiers.forEach((tier) => {
    tier.entities.forEach((entity) => {
      entity.members.forEach((member) => {
        const orgId = member.organizationId || ''
        const subOrganizationId = recreatingAxe
          ? member.subOrganizationId
          : member.id
        userTiers.push({
          tierId: tier.id,
          userId: userId,
          subOrganizationId: subOrganizationId,
          organizationId: recreatingAxe ? getIDFromBase64(orgId) : orgId,
          axeId,
          isRemoved: removedSubOrgIds.includes(member.id)
        })
      })
    })
  })

  return userTiers
}

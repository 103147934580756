function findMatchingDateIndexes(dates: string[]): number[] {
  const dateIndexes: { [key: string]: number[] } = {}

  // Iterate through the dates and map their indexes, ignoring undefined values
  dates.forEach((date, index) => {
    if (date) {
      if (!dateIndexes[date]) {
        dateIndexes[date] = []
      }
      dateIndexes[date].push(index)
    }
  })

  // Find the first group with more than one index
  for (const indexes of Object.values(dateIndexes)) {
    if (indexes.length > 1) {
      return indexes
    }
  }

  // Return an empty array if no matches are found
  return []
}

export default findMatchingDateIndexes

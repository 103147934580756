import React from 'react'
import { NumberFormatBase, useNumericFormat } from 'react-number-format'
import classNames from 'classnames'
import { tableClassNames } from '../../../table/table'
import { getMultipliedValue, numericFastKeysKeyDownHelper } from '../utils'
import { NumericBaseProps } from './numericBase'

/**
 * This component is a numeric input that allows for fast key entry of values.
 * It uses NumberFormatBase with removeFormatting and format function to allow non-digit chars input
 */
function NumericFastKeysBase({
  disabled,
  allowed,
  helpers,
  field,
  max,
  min,
  refreshable,
  onBlur,
  onValueChange,
  onFocus,
  onChange,
  decimalScale = 3,
  placeholder,
  thousandSeparator
}: Omit<NumericBaseProps, 'suffix' | 'prefix'>) {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const { format } = useNumericFormat({
    thousandSeparator: thousandSeparator,
    decimalScale: decimalScale + 1, // NumberFormatBase prevents decimal number input based on decimalScale value. Adding 1 to allows to enter "m" and "b" values
    valueIsNumericString: true
  })

  return (
    <NumberFormatBase
      className={classNames(
        'w-full border-none bg-transparent text-[12px] p-0 disabled:text-textLightGray disabled:cursor-not-allowed',
        tableClassNames.cellHeight,
        tableClassNames.cellPadLeft,
        { [tableClassNames.cellPadRight]: disabled },
        { [tableClassNames.cellControlPad]: refreshable }
      )}
      placeholder={placeholder}
      displayType="input"
      valueIsNumericString
      id={field.name}
      max={max}
      min={min}
      {...field}
      getInputRef={inputRef}
      onValueChange={onValueChange}
      onFocus={onFocus}
      onBlur={(e) => {
        const targetValue = (e.target as HTMLInputElement).value
        helpers?.setValue(getMultipliedValue(targetValue, decimalScale))

        if (onBlur) {
          onBlur(e)
        }
      }}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        const targetValue = (e.target as HTMLInputElement).value

        numericFastKeysKeyDownHelper(
          e,
          decimalScale,
          () => {
            const value = getMultipliedValue(targetValue, decimalScale)
            helpers?.setValue(value)

            setTimeout(() => {
              inputRef.current?.focus()
              inputRef.current?.setSelectionRange(
                Number.MAX_SAFE_INTEGER,
                Number.MAX_SAFE_INTEGER
              )
            }, 100)
          },
          min
        )
      }}
      onChange={onChange}
      disabled={disabled}
      isAllowed={allowed}
      format={(value) => (format && format(value)) || value}
      removeFormatting={(value) => value.replaceAll(',', '')}
      getCaretBoundary={(value) =>
        Array(value.length + 1)
          .fill(0)
          .map(() => true)
      }
    />
  )
}

export default NumericFastKeysBase

import { diff } from 'deep-diff'
import pick from 'lodash/pick'
import sortBy from 'lodash/sortBy'
import { rfqAxeDialog_rfqFragment$data } from '../../../../../dialog/rfqAxeDialog/__generated__/rfqAxeDialog_rfqFragment.graphql'
import { AxeFormData } from '../../../../../dialog/updateAxeDialog/updateAxeDialog'
import isDiffEditProperty from '../../../../utils/isDiffEditProperty/isDiffEditProperty'

const legPropertiesToCheck = ['premium', 'delta', 'pricingVolatility']

/**
 * Returns an array of deep-diff objects based on the differences between the RFQ data and the form values.
 */
function getUpdatedValuesDiff(
  rfqData: rfqAxeDialog_rfqFragment$data,
  values: AxeFormData
) {
  // Align the RFQ data format with the form values, including only the properties needed for comparison.
  const firstRfqOption = sortBy(rfqData.rfq_options, [
    ({ updatedAt }) => updatedAt
  ])[0]
  const rfqLegValues = firstRfqOption.rfq_options_legs.map((leg) =>
    pick(leg, legPropertiesToCheck)
  )

  // Pick only the properties needed for comparison from the form values.
  const legValues = values.legs.map((leg) => pick(leg, legPropertiesToCheck))

  return diff(rfqLegValues, legValues)?.filter(isDiffEditProperty) || null
}

export default getUpdatedValuesDiff

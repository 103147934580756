import { getPremiumSuffix } from '@optaxe/options-utils'
import {
  Enum_Axe_Ccypair_Enum,
  Enum_Currency_Notional_Enum,
  Enum_Currency_Premium_Enum
} from '../../../../../gql'
import { AxeFormLegData } from '../../../../dialog/updateAxeDialog/updateAxeDialog'
import { OnValuesDiffParams } from '../../config'
import processOnDeltaOrPremiumDiff from '../processOnDeltaOrPremiumDiff/processOnDeltaOrPremiumDiff'

export default function processOnPremiumDiff(
  onValuesDiffParams: Pick<
    OnValuesDiffParams,
    'formik' | 'inputStateContext' | 'path' | 'isUserCounterParty'
  >
) {
  return processOnDeltaOrPremiumDiff(
    (partyBasedPremium, { formik: { values }, path }) => {
      const [, legIndex] = path
      const { ccyPair, notional, notionalCurrency, premiumCurrency } = values
        .legs[legIndex] as AxeFormLegData

      return getPremiumSuffix({
        ccyPair: ccyPair as Enum_Axe_Ccypair_Enum,
        notional,
        notionalCurrency: notionalCurrency as Enum_Currency_Notional_Enum,
        premium: partyBasedPremium,
        premiumCurrency: premiumCurrency as Enum_Currency_Premium_Enum
      })
    },
    onValuesDiffParams
  )
}

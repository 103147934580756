import set from 'lodash/set'
import { OnValuesDiffParams } from '../../config'
import { InputProps } from '../../../../input/input'
import isLegFieldPath from '../../../utils/isLegFieldPath/isLegFieldPath'
import { AxeFormData } from '../../../../dialog/updateAxeDialog/updateAxeDialog'
import processTargetFields from '../processTargetFields/processTargetFields'
import { calculateForward, calculateSpot } from '@optaxe/options-utils'
import isNil from 'lodash/isNil'

/**
 * Calculate the forward or spot based on the swaps fields, considering the state of the other fields. Additionally,
 * check if this field has target fields (set via expiry date matches) and update their target fields (i.e., swaps on
 * other legs) accordingly.
 */
function processOnSwapsDiff({
  formik,
  inputStateContext,
  path
}: Pick<OnValuesDiffParams, 'formik' | 'inputStateContext' | 'path'>) {
  if (!isLegFieldPath(path)) return null

  const [arrayName, legIndex, fieldName] = path
  const { dispatch, state } = inputStateContext

  const currentFieldName = `${arrayName}[${legIndex}].${fieldName}`
  const currentFieldState = state[currentFieldName]

  // Although all legs share the same spot, we need to use the updated spot for this leg as the reference.
  // This ensures that if the expiry date triggers a change, we are using the latest spot value from this leg.
  const { spot, swaps, forward } = formik.values.legs[legIndex]

  const valuesToUpdate = processTargetFields({
    inputStateContext,
    path,
    targetFields: currentFieldState?.targetFields,
    value: swaps
  }) as AxeFormData

  // If spot & swaps, always calculate forward
  if (!isNil(spot) && !isNil(swaps)) {
    const forward = calculateForward(spot, swaps)
    set(valuesToUpdate, [arrayName, legIndex, 'forward'], forward)

    const forwardFieldName =
      `${arrayName}[${legIndex}].forward` as InputProps['name']
    const forwardFieldState = inputStateContext.state[forwardFieldName]

    // If the forward field is overTyped and the overTyped value is different from the (calculated) field value, reset the field.
    if (
      forwardFieldState?.overTyped &&
      forwardFieldState?.overTypedValue !== forward
    ) {
      dispatch({ type: 'unset_reset_request', key: forwardFieldName })
    }

    currentFieldState?.targetFields?.forEach((targetField) => {
      set(valuesToUpdate, [arrayName, targetField.legIndex, 'forward'], forward)

      dispatch({
        type: 'unset_reset_request',
        key: `${arrayName}[${targetField.legIndex}].forward`
      })
    })
  } else if (!isNil(forward)) {
    const spot = calculateSpot(swaps, forward)

    set(valuesToUpdate, [arrayName, legIndex, 'spot'], spot)

    currentFieldState?.targetFields?.forEach((targetField) => {
      set(valuesToUpdate, [arrayName, targetField.legIndex, 'spot'], spot)
    })
  }

  return {
    values: valuesToUpdate
  }
}

export default processOnSwapsDiff

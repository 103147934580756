import { FormikContextType } from 'formik'
import { FormDataProcessorConfig } from '../../config/config'
import { InputStateContextProps } from '../../../../context/inputStateContext/inputStateContext'
import { FormData } from '../../config/config'
import isNonLegFieldPath from '../isNonLegFieldPath/isNonLegFieldPath'
import isLegFieldPath from '../isLegFieldPath/isLegFieldPath'
import { FormDataProcessorFormType } from '../../formDataProcessor'
import isUserCounterParty from './utils/isUserCounterParty/isUserCounterParty'
import { rfqAxeDialog_axeFragment$data } from '../../../dialog/rfqAxeDialog/__generated__/rfqAxeDialog_axeFragment.graphql'

export interface ProcessFieldConfigParams {
  config: FormDataProcessorConfig
  formik: Pick<
    FormikContextType<FormData>,
    'values' | 'touched' | 'setErrors' | 'setTouched'
  >
  inputStateContext: InputStateContextProps
  path: unknown[] | undefined
  formType?: FormDataProcessorFormType
  axeData?: rfqAxeDialog_axeFragment$data
}

const processFieldConfig = ({
  config,
  formik,
  inputStateContext,
  path,
  formType,
  axeData
}: ProcessFieldConfigParams) => {
  // Make sure that path is either ['legs', number, string] or [string]
  if (!isLegFieldPath(path) && !isNonLegFieldPath(path)) return

  const fieldName: string = isLegFieldPath(path) ? path[2] : path[0]

  const fieldConfig = config[fieldName]

  if (!fieldConfig || !fieldConfig.onValuesDiff) return

  return fieldConfig.onValuesDiff({
    formik,
    inputStateContext,
    path,
    formType,
    isUserCounterParty: isUserCounterParty(formik.values),
    axeData
  })
}

export default processFieldConfig

import set from 'lodash/set'
import { OnValuesDiffParams } from '../../config'
import { InputProps } from '../../../../input/input'
import isLegFieldPath from '../../../utils/isLegFieldPath/isLegFieldPath'
import { AxeFormData } from '../../../../dialog/updateAxeDialog/updateAxeDialog'
import processTargetFields from '../processTargetFields/processTargetFields'
import { calculateSwaps, calculateSpot } from '@optaxe/options-utils'
import isNil from 'lodash/isNil'

/**
 * Calculate the swaps or spot based on the forward fields, considering the state of the other fields. Additionally,
 * check if this field has target fields (set via expiry date matches) and update their target fields (i.e. forwards on
 * other legs) accordingly.
 */
function processOnForwardDiff({
  formik,
  inputStateContext,
  path
}: Pick<OnValuesDiffParams, 'formik' | 'inputStateContext' | 'path'>) {
  if (!isLegFieldPath(path)) return null

  const [arrayName, legIndex, fieldName] = path
  const { dispatch, state } = inputStateContext

  const currentFieldName = `${arrayName}[${legIndex}].${fieldName}`
  const currentFieldState = state[currentFieldName]

  // Although all legs share the same spot, we need to use the updated spot for this leg as the reference.
  // This ensures that if the expiry date triggers a change, we are using the latest spot value from this leg.
  const { spot, swaps, forward } = formik.values.legs[legIndex]

  const valuesToUpdate = processTargetFields({
    inputStateContext,
    path,
    targetFields: currentFieldState?.targetFields,
    value: forward
  }) as AxeFormData

  // If spot & forward, always calculate swaps
  if (!isNil(spot) && !isNil(forward)) {
    const swaps = calculateSwaps(spot, forward)
    set(valuesToUpdate, [arrayName, legIndex, 'swaps'], swaps)

    const swapsFieldName =
      `${arrayName}[${legIndex}].swaps` as InputProps['name']
    const swapsFieldState = inputStateContext.state[swapsFieldName]

    // If the swaps field is overTyped and the overTyped value is different from the (calculated) field value, reset the field.
    if (
      swapsFieldState?.overTyped &&
      swapsFieldState?.overTypedValue !== swaps
    ) {
      dispatch({ type: 'unset_reset_request', key: swapsFieldName })
    }

    currentFieldState?.targetFields?.forEach((targetField) => {
      set(valuesToUpdate, [arrayName, targetField.legIndex, 'swaps'], swaps)

      dispatch({
        type: 'unset_reset_request',
        key: `${arrayName}[${targetField.legIndex}].swaps`
      })
    })
  } else if (!isNil(swaps)) {
    const spot = calculateSpot(swaps, forward)

    set(valuesToUpdate, [arrayName, legIndex, 'spot'], spot)

    currentFieldState?.targetFields?.forEach((targetField) => {
      set(valuesToUpdate, [arrayName, targetField.legIndex, 'spot'], spot)
    })
  }

  return {
    values: valuesToUpdate
  }
}

export default processOnForwardDiff

import { InputProps, InputVariant } from '../input'
import classNames from 'classnames'
import { generateOptionsFromStringEnum } from '../../buttons/createAxeDialogButton/tableRows'
import { useField } from 'formik'
import { Enum_Tenor_Enum } from '../../../gql'
import { normalizeTenorNames } from '../../../utils/normalizeTenorNames/normalizeTenorNames'
import { InputSelectOption } from '../../../types/inputSelect'
import generateFieldName from '../../../utils/generateFieldName/generateFieldName'
import { useWorkerContext } from '../../../context/marketDataWorkerContext/marketDataWorkerContext'
import ComboBox from '../../comboBox/comboBox'
import { tableClassNames } from '../../table/table'

export interface DateTenorProps extends Omit<InputProps, 'value'> {
  value?: string | null
}

/**
 * Tenors e.g. 1D, 2D, 1W, 1M, 6M, 1Y need to be sorted in the correct order
 * @param a ListBoxOption
 * @param b ListBoxOption
 */
const sortTenors = (a: InputSelectOption, b: InputSelectOption) => {
  let aValue = 0,
    bValue = 0

  if (a.name.endsWith('D')) {
    aValue = parseInt(a.name.slice(0, -1)) * 1
  } else if (a.name.endsWith('W')) {
    aValue = parseInt(a.name.slice(0, -1)) * 7
  } else if (a.name.endsWith('M')) {
    aValue = parseInt(a.name.slice(0, -1)) * 30
  } else if (a.name.endsWith('Y')) {
    aValue = parseInt(a.name.slice(0, -1)) * 365
  }

  if (b.name.endsWith('D')) {
    bValue = parseInt(b.name.slice(0, -1)) * 1
  } else if (b.name.endsWith('W')) {
    bValue = parseInt(b.name.slice(0, -1)) * 7
  } else if (b.name.endsWith('M')) {
    bValue = parseInt(b.name.slice(0, -1)) * 30
  } else if (b.name.endsWith('Y')) {
    bValue = parseInt(b.name.slice(0, -1)) * 365
  }

  return aValue - bValue
}

const tenorOptions = [
  {
    name: 'Tenor',
    unavailable: true
  },
  ...normalizeTenorNames(generateOptionsFromStringEnum(Enum_Tenor_Enum)).sort(
    sortTenors
  )
]

export default function DateTenor({
  placeholder,
  value,
  className,
  fieldArrayPrefix,
  ...field
}: DateTenorProps) {
  const [expiryDateFieldProps] = useField(
    generateFieldName('expiryDate', fieldArrayPrefix)
  )
  const [tenorProps, , tenorFieldHelpers] = useField(
    generateFieldName('tenor', fieldArrayPrefix)
  )
  const { setLastChangedField } = useWorkerContext()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastChangedField(field.name as InputProps['name'])
    tenorFieldHelpers.setValue(null)
    if (field.onChange) field.onChange(e)
  }

  /**
   * Remove the tenor input when this field is disabled
   * as the tenor aids input UX, but the expiry date is the important value for display
   */
  return (
    <div className={classNames(className, 'flex w-full h-full pl-0 py-0')}>
      {!field.disabled || (field.disabled && tenorProps.value) ? (
        <div className="shrink-0 basis-[64px] border-r-[1px] dark:border-r-lightBlue border-r-borderGray">
          <ComboBox
            options={tenorOptions}
            name={generateFieldName('tenor', fieldArrayPrefix)}
            disabled={field.disabled}
            variant={InputVariant.CELL}
          />
        </div>
      ) : null}
      <input
        {...field}
        id={field.name}
        className={classNames(
          'flex-grow p-0 border-none bg-transparent text-[12px] disabled:text-textLightGray disabled:cursor-not-allowed',
          tableClassNames.cellHeight,
          tableClassNames.cellPadLeft,
          tableClassNames.cellPadRight
        )}
        aria-label="Choose Expiration Date"
        placeholder={placeholder}
        value={expiryDateFieldProps.value || ''}
        onChange={handleChange}
      />
    </div>
  )
}

import set from 'lodash/set'
import { RFQFormValues } from '../../../../forms/rfq/rfqAxeFormWrapper/rfqAxeFormWrapper'
import isNonLegFieldPath from '../../../utils/isNonLegFieldPath/isNonLegFieldPath'
import { OnValuesDiffParams } from '../../config'

const processOnSpecifyDeltaHedgeDiff = ({
  formik: { values },
  inputStateContext,
  path
}: Pick<OnValuesDiffParams, 'formik' | 'inputStateContext' | 'path'>) => {
  if (!isNonLegFieldPath(path)) return null
  const rfqValues = values as RFQFormValues
  const valuesToUpdate = {} as RFQFormValues

  if (rfqValues.specifyDeltaHedge) {
    // When checkbox is checked, populate the deltaHedge input
    const netDelta = values.legs.reduce((acc, leg) => acc + (leg.delta || 0), 0)

    set(valuesToUpdate, 'deltaHedge', netDelta)
  } else {
    // Otherwise clear any warning and reset the deltaHedge value
    values.legs.forEach((leg, legIndex) => {
      set(valuesToUpdate, ['legs', legIndex, 'delta'], leg.bsDelta)
    })
    set(valuesToUpdate, 'deltaHedge', undefined)
    inputStateContext.dispatch({ type: 'unset_warning', key: 'deltaHedge' })
  }

  return {
    values: valuesToUpdate
  }
}

export default processOnSpecifyDeltaHedgeDiff

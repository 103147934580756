import get from 'lodash/get'
import set from 'lodash/set'
import { AxeFormData } from '../../../../dialog/updateAxeDialog/updateAxeDialog'
import isLegFieldPath from '../../../utils/isLegFieldPath/isLegFieldPath'
import { OnValuesDiffParams } from '../../config'
import { InputProps, NumericInputProps } from '../../../../input/input'
import { getTableRow } from '../../../../buttons/createAxeDialogButton/tableRows'
import { calculateNotionalRatio, roundToDecimal } from '@optaxe/options-utils'

export function setMinimumNotionalAmounts({
  formik: { values },
  inputStateContext: { state, dispatch },
  path
}: Pick<OnValuesDiffParams, 'formik' | 'inputStateContext' | 'path'>) {
  // If there are no legs, return early
  if (!isLegFieldPath(path) || values.legs.length <= 1) return null

  const [arrayName] = path
  const fieldName = 'minimumNotionalAmount'

  const valuesToUpdate = {} as AxeFormData

  const minimumNotionalAmountLeg1 = get(values, [arrayName, 0, fieldName])
  const notionalRatios = values.legs
    .map((leg) => leg.notional)
    .map(calculateNotionalRatio)

  // For each leg to update, set the field value to be based off the first leg's minimum notional amount and the calculated ratio value and mark the field as disabled.
  // Skip the first leg as it is the source of the ratio
  values.legs.forEach((_, legIndex) => {
    if (legIndex === 0) return

    const key = `${arrayName}[${legIndex}].${fieldName}` as InputProps['name']

    if (!key) return

    // If the field is not disabled, set it to be disabled
    if (!state[key]?.isDisabled) {
      dispatch({ type: 'set_is_disabled', key })
    }

    if (!values.legs[legIndex].notional) return

    const legRatio = notionalRatios[legIndex]
    const tableRowInputProps = getTableRow(fieldName)
      ?.rowInputProps as NumericInputProps

    const legMinimumNotionalAmount = roundToDecimal(
      minimumNotionalAmountLeg1 * legRatio,
      tableRowInputProps.decimalScale || 0
    )

    set(valuesToUpdate, key, legMinimumNotionalAmount)
  })

  return {
    values: valuesToUpdate
  }
}

export default setMinimumNotionalAmounts

import { enum_axe_ccypair_enum } from '../../components/rfqBlotterRow/__generated__/rfqBlotterTableRowRfqFragment.graphql'
import { FixCurrencies } from '../fixes/fixes'

export type CutCurrencies = Exclude<enum_axe_ccypair_enum, FixCurrencies>

const cuts: Record<CutCurrencies, Array<string>> = {
  EURUSD: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  USDJPY: [
    'NY1000',
    'TOK1500',
    'WMR1300LON',
    'WMR1600LON',
    'WMR1000NY',
    'WMR1500TKY',
    'BFIX1000LON',
    'BFIX1000TOK',
    'BFIX1000NY',
    'BFIX1000HK',
    'BFIX1500LON',
    'BFIX1500TOK',
    'BFIX1500HK',
    'BFIX1600LON'
  ],
  EURJPY: [
    'NY1000',
    'TOK1500',
    'WMR1300LON',
    'WMR1600LON',
    'WMR1000NY',
    'WMR1500TKY',
    'BFIX1000LON',
    'BFIX1000TOK',
    'BFIX1000NY',
    'BFIX1000HK',
    'BFIX1500LON',
    'BFIX1500TOK',
    'BFIX1500HK',
    'BFIX1600LON'
  ],
  GBPUSD: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  EURGBP: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  USDCAD: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  USDCHF: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  EURCHF: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  AUDUSD: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  NZDUSD: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  USDSEK: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  USDNOK: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  EURSEK: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  EURNOK: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  EURAUD: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  EURNZD: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  EURCAD: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  GBPAUD: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  GBPNZD: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  GBPCAD: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  GBPNOK: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  GBPSEK: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  GBPCHF: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  CHFNOK: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  CHFSEK: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  AUDNZD: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  NZDCAD: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  AUDCAD: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  CADCHF: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  GBPJPY: [
    'NY1000',
    'TOK1500',
    'WMR1300LON',
    'WMR1600LON',
    'WMR1000NY',
    'WMR1500TKY',
    'BFIX1000LON',
    'BFIX1000TOK',
    'BFIX1000NY',
    'BFIX1000HK',
    'BFIX1500LON',
    'BFIX1500TOK',
    'BFIX1500HK',
    'BFIX1600LON'
  ],
  CADJPY: [
    'NY1000',
    'TOK1500',
    'WMR1300LON',
    'WMR1600LON',
    'WMR1000NY',
    'WMR1500TKY',
    'BFIX1000LON',
    'BFIX1000TOK',
    'BFIX1000NY',
    'BFIX1000HK',
    'BFIX1500LON',
    'BFIX1500TOK',
    'BFIX1500HK',
    'BFIX1600LON'
  ],
  CHFJPY: [
    'NY1000',
    'TOK1500',
    'WMR1300LON',
    'WMR1600LON',
    'WMR1000NY',
    'WMR1500TKY',
    'BFIX1000LON',
    'BFIX1000TOK',
    'BFIX1000NY',
    'BFIX1000HK',
    'BFIX1500LON',
    'BFIX1500TOK',
    'BFIX1500HK',
    'BFIX1600LON'
  ],
  AUDJPY: [
    'NY1000',
    'TOK1500',
    'WMR1300LON',
    'WMR1600LON',
    'WMR1000NY',
    'WMR1500TKY',
    'BFIX1000LON',
    'BFIX1000TOK',
    'BFIX1000NY',
    'BFIX1000HK',
    'BFIX1500LON',
    'BFIX1500TOK',
    'BFIX1500HK',
    'BFIX1600LON'
  ],
  NZDJPY: [
    'NY1000',
    'TOK1500',
    'WMR1300LON',
    'WMR1600LON',
    'WMR1000NY',
    'WMR1500TKY',
    'BFIX1000LON',
    'BFIX1000TOK',
    'BFIX1000NY',
    'BFIX1000HK',
    'BFIX1500LON',
    'BFIX1500TOK',
    'BFIX1500HK',
    'BFIX1600LON'
  ],
  SEKJPY: [
    'NY1000',
    'TOK1500',
    'WMR1300LON',
    'WMR1600LON',
    'WMR1000NY',
    'WMR1500TKY',
    'BFIX1000LON',
    'BFIX1000TOK',
    'BFIX1000NY',
    'BFIX1000HK',
    'BFIX1500LON',
    'BFIX1500TOK',
    'BFIX1500HK',
    'BFIX1600LON'
  ],
  NOKJPY: [
    'NY1000',
    'TOK1500',
    'WMR1300LON',
    'WMR1600LON',
    'WMR1000NY',
    'WMR1500TKY',
    'BFIX1000LON',
    'BFIX1000TOK',
    'BFIX1000NY',
    'BFIX1000HK',
    'BFIX1500LON',
    'BFIX1500TOK',
    'BFIX1500HK',
    'BFIX1600LON'
  ],
  NOKSEK: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  EURDKK: [
    'NY1000',
    'TOK1500',
    'WMR0900LON',
    'WMR1000LON',
    'WMR1600LON',
    'BFIX',
    'BFIX0800LON',
    'BFIX0900LON',
    'BFIX1000LON',
    'BFIX1100LON',
    'BFIX1200LON',
    'BFIX1300LON',
    'BFIX1400LON',
    'BFIX1500LON',
    'BFIX1600LON',
    'BFIX1000HK',
    'BFIX1400HK',
    'WMR1000NY',
    'WMR1500TOK',
    'WMR1600TOK'
  ],
  TRYJPY: [
    'L12',
    'WMR1000NY',
    'WMR1200LON',
    'WMR1300LON',
    'WMR1600LON',
    'BFIX1300LON',
    'BFIX1600LON'
  ],
  MXNJPY: [
    'MXN',
    'NY1000',
    'TOK1500',
    'BFIX1400HK',
    'WMR1600LON',
    'BFIX1600LON'
  ],
  USDCNH: [
    'TOK1500',
    'NY1000',
    'HK',
    'CNHFIX',
    'BFIX1400HK',
    'BFIX',
    'WMR0800LON'
  ],
  USDMXN: [
    'MXN',
    'NY1000',
    'TOK1500',
    'BFIX1400HK',
    'WMR1600LON',
    'BFIX1600LON',
    'BFIX1230NY'
  ],
  USDTRY: ['L12', 'WMR1200LON', 'BFIX1200LON', 'WMR1600LON'],
  USDZAR: ['NY1000', 'TOK1500', 'WMR1600LON', 'BFIX1400HK'],
  USDRUB: ['MOS', 'RUB MOEX'],
  USDILS: [
    'TEL',
    'NY1000',
    'TOK1500',
    'BFIX1400HK',
    'BFIX1300LON',
    'WMR1000LON',
    'WMR1300LON'
  ],
  USDSGD: [
    'TOK1500',
    'NY1000',
    'BFIX1400HK',
    'BFIX1500TOK',
    'WMR1300LON',
    'WMR1600LON'
  ],
  USDHKD: [
    'TOK1500',
    'NY1000',
    'HK1',
    'BFIX1400HK',
    'WMR1600LON',
    'BFIX1500TOK'
  ],
  EURCNH: [
    'TOK1500',
    'NY1000',
    'HK',
    'BFIX1400HK',
    'BFIX1500HK',
    'BFIX1300LON',
    'WMR0800LON',
    'WMR1600LON'
  ],
  EURMXN: [
    'MXN',
    'NY1000',
    'TOK1500',
    'BFIX1400HK',
    'WMR1600LON',
    'BFIX1600LON',
    'BFIX1230NY'
  ],
  EURTRY: ['L12', 'WMR1200LON', 'BFIX1200LON', 'WMR1600LON'],
  EURZAR: ['NY1000', 'TOK1500', 'WMR1600LON', 'BFIX1400HK'],
  EURRUB: ['MOS', 'RUB MOEX'],
  EURSGD: [
    'TOK1500',
    'NY1000',
    'BFIX1400HK',
    'BFIX1500TOK',
    'WMR1300LON',
    'WMR1600LON'
  ],
  EURHKD: [
    'TOK1500',
    'NY1000',
    'HK1',
    'BFIX1400HK',
    'WMR1600LON',
    'BFIX1500TOK'
  ],
  CNHJPY: [
    'TOK1500',
    'NY1000',
    'HK',
    'BFIX1400HK',
    'BFIX1500HK',
    'BFIX1300LON',
    'WMR0800LON',
    'WMR1600LON'
  ],
  TRYZAR: ['NY1000', 'TOK1500', 'BFIX1400HK'],
  GBPHKD: [
    'NY1000',
    'TOK1500',
    'HK1',
    'BFIX1400HK',
    'WMR1600LON',
    'BFIX1500TOK'
  ],
  HKDJPY: [
    'NY1000',
    'TOK1500',
    'HK1',
    'BFIX1400HK',
    'WMR1600LON',
    'BFIX1500TOK'
  ],
  EURPLN: [
    'WAR',
    'WMR1000LON',
    'WMR1300LON',
    'BFIX1300LON',
    'WMR1500LON',
    'WMR1600LON'
  ],
  EURHUF: ['HUF', 'WMR1000NY', 'WMR1300LON', 'WMR1000LON', 'WMR1600LON'],
  EURCZK: [
    'NY1000',
    'TOK1500',
    'BFIX1400HK',
    'WMR1000LON',
    'WMR1300LON',
    'WMR1600LON',
    'BFIX1300LON'
  ],
  USDPLN: [
    'WAR',
    'WMR1000LON',
    'WMR1300LON',
    'BFIX1300LON',
    'WMR1500LON',
    'WMR1600LON'
  ],
  USDHUF: ['HUF', 'WMR1000NY', 'WMR1300LON', 'WMR1000LON', 'WMR1600LON'],
  USDCZK: [
    'NY1000',
    'TOK1500',
    'BFIX1400HK',
    'WMR1000LON',
    'WMR1300LON',
    'WMR1600LON',
    'BFIX1300LON'
  ],
  // This value is required but we don't want the warning as it won't be used
  // eslint-disable-next-line relay/no-future-added-value
  '%future added value': []
}

export const isCutCurrency = (
  ccyPair: enum_axe_ccypair_enum | null | undefined
) => ccyPair && Object.keys(cuts).includes(ccyPair)

export default cuts

import isUndefined from 'lodash/isUndefined'
import mapValues from 'lodash/mapValues'
import omit from 'lodash/omit'
import pickBy from 'lodash/pickBy'
import { OnValuesDiffParams } from '../../config'
import { isCutCurrency } from '../../../../../utils/cuts/cuts'
import { isFixCurrency } from '../../../../../utils/fixes/fixes'

type resetAxeFormParams = {
  formik: Pick<
    OnValuesDiffParams['formik'],
    'setErrors' | 'setTouched' | 'values'
  >
  inputStateContext: Pick<OnValuesDiffParams['inputStateContext'], 'dispatch'>
}

export default function resetAxeForm({
  formik: { setErrors, setTouched, values },
  inputStateContext: { dispatch }
}: resetAxeFormParams) {
  const ccyPair = values.legs[0].ccyPair

  const leg1 = mapValues(
    pickBy(
      {
        ...omit(values.legs[0], [
          'ccyPair',
          'notionalCurrency',
          'premiumCurrency',
          'product',
          isCutCurrency(ccyPair) ? 'cut' : '',
          isFixCurrency(ccyPair) ? 'fix' : ''
        ])
      },
      (value) => !isUndefined(value)
    ),
    () => null
  )

  // Reset Formik form state imperatively
  setTouched({}, false)
  setErrors({})

  // Reset inputState state
  dispatch({ type: 'reset', key: 'all' })

  return {
    values: { legs: [leg1] }
  }
}

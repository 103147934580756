import { calculatePercentage, formatPercentage } from '@optaxe/options-utils'
import { OnValuesDiffParams } from '../../config'
import processOnDeltaOrPremiumDiff from '../processOnDeltaOrPremiumDiff/processOnDeltaOrPremiumDiff'

export default function processOnDeltaDiff(
  onValuesDiffParams: Pick<
    OnValuesDiffParams,
    'formik' | 'inputStateContext' | 'path' | 'isUserCounterParty'
  >
) {
  return processOnDeltaOrPremiumDiff(
    (partyBasedDelta, { formik: { values }, path }) => {
      const [, legIndex] = path
      const { notional } = values.legs[legIndex]

      return formatPercentage(calculatePercentage(partyBasedDelta, notional))
    },
    onValuesDiffParams
  )
}

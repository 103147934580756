import React, { useEffect } from 'react'
import Disclosure from '../../disclosure/disclosure'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import CheckBox from '../../checkbox/checkbox'
import { useDrag } from 'react-dnd'
import { ItemTypes } from '../constants'
import {
  TierDisclosureProps,
  TierDisclosureVariants
} from '../tierDisclosure/tierDisclosure'

import {
  ReducerAction,
  TierSelectorState,
  useTierSelectorContext
} from '../hooks/tierSelectorContext'
import classNames from 'classnames'
import ScrollContainer from '../../scrollContainer/scrollContainer'
import Search from '../../search/search'
import { queriedEntities } from '../utils/queriedEntities/queriedEntities'

export interface MemberUser {
  name?: string | null
  id: string
  subOrgId?: string | null
}

export interface Member {
  name: string
  id: string
  organizationId?: string
  subOrganizationId?: string
  isRemoved?: boolean
  users?: Array<MemberUser>
}

export interface Entity {
  name: string
  id: string
  members: Member[]
  open?: boolean
  query?: string
}

interface EntityDisclosureProps extends Entity {
  readonly tierId: string
  readonly variant?: TierDisclosureProps['variant']
  isSales?: boolean
}

export interface TierListProps extends TierDisclosureProps {
  readonly variant: TierDisclosureVariants
  isSales?: boolean
}

const SubArrow = ({ className }: React.HTMLProps<HTMLOrSVGElement>) => (
  <svg
    width="10"
    height="13"
    viewBox="0 0 10 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <line
      x1="0.5"
      y1="0.5"
      x2="0.5"
      y2="8.5"
      strokeWidth="3"
      className="stroke-textLightGray"
    />
    <path
      className=" stroke-textLightGray"
      d="M9.35355 8.85355C9.54882 8.65829 9.54882 8.34171 9.35355 8.14645L6.17157 4.96447C5.97631 4.7692 5.65973 4.7692 5.46447 4.96447C5.2692 5.15973 5.2692 5.47631 5.46447 5.67157L8.29289 8.5L5.46447 11.3284C5.2692 11.5237 5.2692 11.8403 5.46447 12.0355C5.65973 12.2308 5.97631 12.2308 6.17157 12.0355L9.35355 8.85355ZM9 8L0 8V9L9 9V8Z"
    />
  </svg>
)

const highlightQueryOnString = (string: string, query?: string) => {
  if (!query) return string

  const queryIndex = string.toLowerCase().indexOf(query.toLowerCase())

  if (queryIndex === -1) {
    return string
  }
  const queryLength = query.length

  return (
    <span>
      {string.substring(0, queryIndex)}
      <span className="font-bold">
        {string.substring(queryIndex, queryIndex + queryLength)}
      </span>
      {string.substring(queryIndex + queryLength)}
    </span>
  )
}

const findEntityCheckedState = (
  entityId: string,
  tierId: string,
  state: TierSelectorState
): boolean => {
  return (
    state.tiers
      .find((t) => t.id === tierId)
      ?.entities.find((entity) => entity.id === entityId)?.checked || false
  )
}

const findEntityOpenState = (
  entityId: string,
  tierId: string,
  state: TierSelectorState
): boolean => {
  return (
    state.tiers
      .find((t) => t.id === tierId)
      ?.entities.find((entity) => entity.id === entityId)?.open || false
  )
}

const findMemberCheckedState = (
  memberId: string,
  entityId: string,
  tierId: string,
  state: TierSelectorState
): boolean => {
  return (
    state.tiers
      .find((t) => t.id === tierId)
      ?.entities.find((entity) => entity.id === entityId)
      ?.entities.find((entity) => entity.id === memberId)?.checked || false
  )
}

export function EntityDisclosure(props: EntityDisclosureProps) {
  const { name, id: entityId, tierId, members, open, query } = props
  const buttonRef = React.useRef<HTMLButtonElement | null>(null)
  const [isButtonRefSet, setIsButtonRefSet] = React.useState(false)
  const { state, dispatch } = useTierSelectorContext()
  const defaultVariant = props.variant === TierDisclosureVariants.DEFAULT
  const dataOpenRef = React.useRef(false)
  const [disclosureOpen, setDisclosureOpen] = React.useState(false)
  const entityOpenState = open || findEntityOpenState(entityId, tierId, state)

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: ItemTypes.ENTITY,
      item: { name, id: entityId, tierId, members, open },
      canDrag: () => defaultVariant && !props.isSales,
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      }),
      end: (_item, monitor) => {
        if (monitor.didDrop()) {
          dispatch({
            type: 'open_entity',
            payload: { id: entityId, open: dataOpenRef.current }
          })
        }
      }
    }),
    [{ name, id: entityId, tierId, members }]
  )

  React.useEffect(() => {
    if (isButtonRefSet && entityOpenState) {
      buttonRef.current?.click()
      setDisclosureOpen(true)
    }
  }, [isButtonRefSet, entityOpenState])

  React.useEffect(() => {
    // Handle disclosure state based on search terms
    if (!isButtonRefSet || query === undefined) return

    // Determine current disclosure state from both internal (ref) and external state
    const disclosureIsOpen = disclosureOpen || dataOpenRef.current
    const disclosureIsClosed = !disclosureOpen || !dataOpenRef.current

    // Sync disclosure state with entityOpenState
    if (entityOpenState && disclosureIsClosed) {
      buttonRef.current?.click()
      setDisclosureOpen(true)
    } else if (!entityOpenState && disclosureIsOpen) {
      buttonRef.current?.click()
      setDisclosureOpen(false)
    }
  }, [entityOpenState, disclosureOpen, isButtonRefSet, name, query])

  // set the button ref along with state so that we can run the above use effect
  // once the button ref is set
  const setButtonRef = (node: HTMLButtonElement) => {
    if (node) {
      buttonRef.current = node
      setIsButtonRefSet(true)
    }
  }

  return (
    <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <div role="handle" ref={drag}>
        <Disclosure
          className="mb-2 px-2"
          buttonContent={(Button, open) => {
            const entityChecked = findEntityCheckedState(
              entityId,
              tierId,
              state
            )

            dataOpenRef.current = open

            return (
              <div
                className={classNames('flex gap-2 items-center pt-2 dmb-2', {
                  'border-b-[1px] border-baseGray dark:border-lightBlue pb-2 mb-3':
                    open
                })}
              >
                {defaultVariant && !props.isSales && (
                  <CheckBox
                    onChange={() =>
                      dispatch({
                        type: 'update',
                        payload: {
                          id: entityId,
                          checked: !entityChecked
                        }
                      })
                    }
                    name={name}
                    checked={entityChecked}
                  />
                )}
                <Button
                  ref={setButtonRef}
                  className={classNames(
                    'group flex flex-row justify-between items-center w-full text-sm',
                    {
                      'text-textGrayLight dark:text-textLightGray':
                        !entityChecked && !props.isSales,
                      'text-black dark:text-white':
                        entityChecked || props.isSales
                    }
                  )}
                >
                  {highlightQueryOnString(name, query)}
                  <ChevronDownIcon
                    height="24px"
                    width="24px"
                    className="group-data-[open]:rotate-180"
                  />
                </Button>
              </div>
            )
          }}
          panelContent={
            <div className="flex flex-col justify-between gap-2" role="list">
              {members.map(
                (
                  {
                    name: memberName,
                    id,
                    organizationId,
                    subOrganizationId,
                    users
                  },
                  index
                ) => {
                  const checkedState = findMemberCheckedState(
                    id,
                    entityId,
                    tierId,
                    state
                  )

                  return (
                    <SubOrganization
                      checkedState={checkedState}
                      name={memberName}
                      key={index}
                      defaultVariant={defaultVariant}
                      dispatch={dispatch}
                      id={id}
                      entityId={entityId}
                      tierId={tierId}
                      topLevelOrganizationId={organizationId || ''}
                      subOrganizationId={subOrganizationId || ''}
                      isSales={props.isSales}
                      users={users}
                    />
                  )
                }
              )}
            </div>
          }
        />
      </div>
    </div>
  )
}

interface SubOrganizationProps
  extends Pick<EntityDisclosureProps, 'id' | 'query' | 'name'> {
  checkedState: boolean
  defaultVariant: boolean
  dispatch: React.Dispatch<ReducerAction>
  entityId: string
  tierId: string
  subOrganizationId: string
  topLevelOrganizationId: string
  isSales?: boolean
  users?: Array<MemberUser>
}

const SubOrganization = ({
  checkedState,
  name,
  defaultVariant,
  id,
  query,
  dispatch,
  entityId,
  tierId,
  topLevelOrganizationId,
  subOrganizationId,
  isSales,
  users
}: SubOrganizationProps) => {
  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: ItemTypes.MEMBER,
      item: {
        id,
        name,
        entityId,
        tierId,
        organizationId: topLevelOrganizationId,
        subOrganizationId,
        users
      },
      canDrag: () => defaultVariant && !isSales,
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    }),
    [{ id, name, entityId, isSales }]
  )

  return (
    <div ref={dragPreview} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <div className="flex flex-col gap-2 items-top" role="Handle" ref={drag}>
        <div className="flex gap-2 items-center">
          <SubArrow
            className={classNames('mb-1', {
              'ml-2': !isSales
            })}
          />
          <div className="flex gap-2 items-center">
            {defaultVariant && !isSales && (
              <CheckBox
                id={id}
                name={name}
                onChange={() =>
                  dispatch({
                    type: 'update',
                    payload: {
                      id,
                      checked: !checkedState
                    }
                  })
                }
                checked={checkedState}
              />
            )}

            <label
              htmlFor={id}
              className={classNames('text-sm', {
                'text-black dark:text-white': checkedState || isSales,
                'text-textGrayLight dark:text-textLightGray':
                  !checkedState && !isSales
              })}
            >
              {highlightQueryOnString(name, query)}
            </label>
          </div>
        </div>
        {users && (
          <div className="gap-4 flex ml-[32px]">
            <div className="w-[1px] bg-textLightGray/60" />
            <div className="gap-1 flex flex-col">
              {users.map((user) => (
                <span
                  key={user.id}
                  className={classNames('text-sm text-black', {
                    'dark:text-white': checkedState || isSales,
                    'dark:text-textLightGray text-textGrayLight':
                      !checkedState && !isSales
                  })}
                >
                  {user.name}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default function TierList({
  tierId,
  entities,
  variant,
  query,
  isSales
}: TierListProps) {
  const [tierListQuery, setTierListQuery] = React.useState(query ?? '')
  useEffect(() => {
    setTierListQuery(query ?? '')
  }, [query])
  const defaultVariant = variant === TierDisclosureVariants.DEFAULT

  const filteredTiers = queriedEntities(entities, tierListQuery)

  return (
    <div
      className={classNames(
        'relative shadow-lg rounded-xl border-[1px] border-baseGray dark:border-lightBlue',
        {
          'px-4 py-2': !filteredTiers.length,
          'px-4 pt-4': filteredTiers.length
        }
      )}
    >
      <div className="w-full absolute px-4 pt-2 left-0 top-0 z-10">
        <div className="relative">
          <Search
            name="search tier"
            placeholder="Search entities/client"
            onInputChange={(event) => setTierListQuery(event.target.value)}
            onComboBoxChange={() => {
              // Noop
            }}
          />
        </div>
      </div>
      <ScrollContainer
        show={filteredTiers.length > 0}
        className={classNames('relative overflow-auto max-h-[200px]', {
          'mt-0': !filteredTiers.length,
          'mt-10 py-2': filteredTiers.length
        })}
        role="list"
        data-testid="tier-list"
      >
        {!filteredTiers.length ? (
          <div className="p-4 mt-14 mb-2 rounded-xl border-dashed dark:border-darkBlueGray border-borderGray border-2 text-borderGray dark:text-darkBlueGray text-center">
            <p>No entities found</p>
            {tierListQuery ? (
              <p>Try a different search term</p>
            ) : (
              defaultVariant && <p>Drag and drop entities here</p>
            )}
          </div>
        ) : (
          filteredTiers.map((tier, index) => (
            <EntityDisclosure
              key={index}
              {...tier}
              query={tierListQuery}
              tierId={tierId}
              variant={variant}
              isSales={isSales}
            />
          ))
        )}
      </ScrollContainer>
    </div>
  )
}

import { axes_insert_input } from '../../components/buttons/createAxeDialogButton/__generated__/createAxeDialogButtonMutation.graphql'

import omit from 'lodash/omit' // Lodash is curiously not tree-shakeable so you have to import the specific function, not the named import
import { AxeFormLegData } from '../../components/dialog/updateAxeDialog/updateAxeDialog'

export const omitNonAxeSpecificFormFields = (
  data: axes_insert_input | AxeFormLegData
): Omit<
  Partial<axes_insert_input>,
  'tiers' | 'pricingVolatilityT2' | 'pricingVolatilityT3' | 'removedSubOrgIds'
> => {
  return omit(data, [
    'tiers',
    'pricingVolatilityT2',
    'pricingVolatilityT3',
    'removedSubOrgIds',
    'marketStrike',
    'bsDelta'
  ])
}

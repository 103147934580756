import isArray from 'lodash/isArray'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'

export type LegFieldPath = ['legs', number, string]

export const isLegFieldPath = (
  path: unknown[] | undefined
): path is LegFieldPath =>
  isArray(path) && path[0] === 'legs' && isNumber(path[1]) && isString(path[2])

export default isLegFieldPath

import { calculateNotionalRatio, roundToDecimal } from '@optaxe/options-utils'
import isNil from 'lodash/isNil'
import set from 'lodash/set'
import { AxeFormData } from '../../../../dialog/updateAxeDialog/updateAxeDialog'
import isLegFieldPath from '../../../utils/isLegFieldPath/isLegFieldPath'
import { OnValuesDiffParams } from '../../config'

export default function setSecondaryLegNotionalsBasedOnRatio({
  formik: { values },
  path,
  axeData
}: Pick<OnValuesDiffParams, 'formik' | 'axeData' | 'path'>) {
  if (!isLegFieldPath(path) || isNil(axeData) || values.legs.length <= 1)
    return null

  const valuesToUpdate = {} as AxeFormData

  const [arrayName] = path

  const leg1Notional = values.legs[0].notional
  const axeNotionals = axeData.axe_legs.map((leg) => leg.notional || 0)

  axeNotionals.forEach((axeNotional, index) => {
    if (index === 0) return

    const ratio = calculateNotionalRatio(axeNotional, index, axeNotionals)
    const newNotional = roundToDecimal(leg1Notional * ratio, 0)

    set(valuesToUpdate, [arrayName, index, 'notional'], newNotional)
  })

  return {
    values: valuesToUpdate
  }
}

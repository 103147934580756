import { InputProps } from '../../../../input/input'
import { InputStateContextProps } from '../../../../../context/inputStateContext/inputStateContext'
import { InputState } from '../../../../../context/inputStateContext/inputStateReducer'
import { LegFieldPath } from '../../../utils/isLegFieldPath/isLegFieldPath'
import set from 'lodash/set'

interface ProcessTargetFieldsParams {
  inputStateContext: InputStateContextProps
  path: LegFieldPath
  targetFields: InputState['targetFields']
  value: string | number
}

/**
 * Process any target fields associated with the supplied path found in the input state, and update these target fields
 * with the provided value. This is primarily used when fields should have the same values under certain conditions.
 * For example, the swaps or forward fields should match those of other legs only when the expiry dates are the same.
 */
function processTargetFields({
  inputStateContext,
  path,
  targetFields,
  value
}: ProcessTargetFieldsParams) {
  const { dispatch, state } = inputStateContext
  const [arrayName, legIndex, fieldName] = path

  const currentFieldName = `${arrayName}[${legIndex}].${fieldName}`

  const valuesToUpdate = {}

  targetFields?.forEach((targetField) => {
    const name =
      `${arrayName}[${targetField.legIndex}].${fieldName}` as InputProps['name']

    set(valuesToUpdate, [arrayName, targetField.legIndex, fieldName], value)

    if (state[currentFieldName]?.overTyped) {
      dispatch({ type: 'set_over_typed', key: name, payload: value })
    }
  })

  return valuesToUpdate
}

export default processTargetFields

import { OnValuesDiffParams } from '../../config'
import isLegFieldPath from '../../../utils/isLegFieldPath/isLegFieldPath'
import { setForwardAndSwapsSameForSameExpiryLegs } from '../setForwardAndSwapsSameForSameExpiryLegs/setForwardAndSwapsSameForSameExpiryLegs'

function processOnExpiryDateDiff({
  formik: { values },
  inputStateContext: { dispatch, state },
  path
}: Pick<OnValuesDiffParams, 'formik' | 'inputStateContext' | 'path'>) {
  if (!isLegFieldPath(path)) return null

  // Identify and handle matching expiry dates
  return setForwardAndSwapsSameForSameExpiryLegs({
    formik: { values },
    inputStateContext: { dispatch, state },
    path
  })
}

export default processOnExpiryDateDiff

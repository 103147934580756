import { useFormikContext } from 'formik'
import React from 'react'
import isEmpty from 'lodash/isEmpty'
import sum from 'lodash/sum'
import upperFirst from 'lodash/upperFirst'
import { tableClassNames } from '../../../table/table'
import classNames from 'classnames'
import {
  AxeFormData,
  AxeFormLegData
} from '../../../dialog/updateAxeDialog/updateAxeDialog'
import { enum_axe_buysell_enum } from '../../../rfqBlotterRow/__generated__/rfqBlotterTableRowRfqFragment.graphql'
import { VanillaFormNames } from '../../../input/input'
import isUndefined from 'lodash/isUndefined'
import { RFQFormValues } from '../../../forms/rfq/rfqAxeFormWrapper/rfqAxeFormWrapper'
import { useInputStateContext } from '../../../../context/inputStateContext/inputStateContext'
import {
  calculateNotionalRatio,
  invertSignUnlessInAuthorSubOrg,
  getPartyBuySell,
  joinSummaryValues,
  summariseToIdenticalOrConcatenated,
  summariseDates,
  calculatePercentage,
  formatPercentage,
  getPremiumSuffix
} from '@optaxe/options-utils'
import { NumericFormatProps, numericFormatter } from 'react-number-format'
import { Enum_Axe_Buysell_Enum } from '@optaxe/options-utils/dist/gql'

const keysToUseFirstLegValue: Array<VanillaFormNames> = [
  'product',
  'ccyPair',
  'cut',
  'fix',
  'spot',
  'hedgeType'
]

// Formats a numerical value with thousand separators
export const formatNumber = (value: number, props?: NumericFormatProps) => {
  return numericFormatter(value.toString(), {
    thousandSeparator: ',',
    ...props
  })
}

// Formats summary for notional values by converting notional values to millions and optionally appending the currency.
export const formatNotionalSummary = (
  rowValues: number[],
  separator = ' x '
) => {
  // Display 0 if the first leg is 0, as the first leg defines the ratio
  if (Number(rowValues[0]) === 0) return '0'

  const chainedNotionalValues = rowValues.map((value, index, array) =>
    formatNumber(calculateNotionalRatio(value, index, array), {
      decimalScale: 2
    })
  )

  return chainedNotionalValues.join(separator)
}

// Formats summary for premium values by calculating the total premium and optionally appending the currency.
export const summarisePremium = (rowValues: number[], values: AxeFormData) => {
  const isInAuthorSubOrg = getIsInAuthorSubOrgFromFormik(values)

  const total = calculatePartyBasedTotal(rowValues, isInAuthorSubOrg)

  const firstLeg = values.legs[0]

  const { ccyPair, notional, notionalCurrency, premiumCurrency } = firstLeg
  const suffix = getPremiumSuffix({
    ccyPair,
    notional,
    notionalCurrency,
    premium: total,
    premiumCurrency
  })

  return `${premiumCurrency} ${formatNumber(total)}${suffix}`
}

export const calculatePartyBasedTotal = (
  rowValues: number[],
  isInAuthorSubOrg: boolean
) => {
  const total = sum(rowValues)

  return invertSignUnlessInAuthorSubOrg(total, isInAuthorSubOrg)
}

// Formats summary for delta values by calculating total delta and providing a percentage relative to the first leg's
// notional value.
const summariseDelta = (rowValues: number[], values: AxeFormData) => {
  const isInAuthorSubOrg = getIsInAuthorSubOrgFromFormik(values)

  const total = calculatePartyBasedTotal(rowValues, isInAuthorSubOrg)

  const firstLegNotional = values.legs[0].notional
  const suffix = formatPercentage(calculatePercentage(total, firstLegNotional))

  return `${formatNumber(total)} ${suffix}`
}

const summariseGammaAndVega = (rowValues: number[], values: AxeFormData) => {
  const isInAuthorSubOrg = getIsInAuthorSubOrgFromFormik(values)

  const total = calculatePartyBasedTotal(rowValues, isInAuthorSubOrg)

  return formatNumber(total)
}

export const getIsInAuthorSubOrg = (
  ownerSubOrg: string | undefined,
  userSubOrg: string | undefined
) => {
  // if either the owner or user sub org is undefined, we can't determine if the user is part of the author's sub org
  if (isUndefined(ownerSubOrg) || isUndefined(userSubOrg)) return true

  return ownerSubOrg === userSubOrg
}

export type AxeUserIds = {
  ownerSubOrganizationId: string
  userSubOrganizationId: string
} & Record<string, unknown>

export const getIsInAuthorSubOrgFromFormik = (
  values: AxeFormData | RFQFormValues
) => {
  const { ownerSubOrganizationId, userSubOrganizationId } = values

  return getIsInAuthorSubOrg(ownerSubOrganizationId, userSubOrganizationId)
}

export const summariseDirection = (
  rowValues: enum_axe_buysell_enum[],
  values: AxeFormData
) => {
  const isInAuthorSubOrg = getIsInAuthorSubOrgFromFormik(values)

  if (isUndefined(isInAuthorSubOrg)) return ''

  const summaryDirection = isInAuthorSubOrg
    ? rowValues
    : rowValues.map((buySell) =>
        getPartyBuySell(isInAuthorSubOrg, buySell as Enum_Axe_Buysell_Enum)
      )

  return joinSummaryValues(summaryDirection)
}

// Generates a formatted summary value based on the property name and leg values
const generateSummaryValue = (
  name: keyof AxeFormLegData,
  values: AxeFormData
): string => {
  const rowValues = values.legs
    .map((leg) => leg[name])
    .filter((value) => value !== null && value !== undefined)

  if (isEmpty(rowValues)) return ''

  switch (name) {
    case 'buySell':
      return summariseDirection(rowValues, values)
    case 'strike':
    case 'callPut':
    case 'volatility':
    case 'pricingVolatility':
      return joinSummaryValues(rowValues)
    case 'forward':
    case 'swaps':
      return summariseToIdenticalOrConcatenated(rowValues)
    case 'expiryDate':
    case 'deliveryDate':
    case 'premiumDate':
      return summariseDates(rowValues)
    case 'notional':
    case 'minimumNotionalAmount':
      // Always display the ratio based on the notional values for minimumNotionalAmount
      return Number(values.legs[0][name]) > 0
        ? formatNotionalSummary(
            values.legs.map((leg) => leg.notional as number)
          )
        : '0'
    case 'premium':
      return summarisePremium(rowValues, values)
    case 'gamma':
    case 'vega':
      return summariseGammaAndVega(rowValues, values)
    case 'delta':
    case 'bsDelta':
      return summariseDelta(rowValues, values)
    default:
      console.warn('No summary formatter found for', name)

      return ''
  }
}

interface SummaryCellProps {
  name: keyof AxeFormLegData
}

export default function SummaryCell({ name }: SummaryCellProps) {
  const { values } = useFormikContext<AxeFormData>()
  const [summaryValue, setSummaryValue] = React.useState<string>('')
  const { state } = useInputStateContext()

  React.useEffect(() => {
    if (!values) return

    // The content of the summary cell varies depending on the 'name' property. It either displays the value from the
    // first leg of an array (for certain fields) or a combined representation of values from all legs.

    // Check if the field is one that should use the value from the first leg.
    if (keysToUseFirstLegValue.includes(name)) {
      const firstLeg = values.legs[0]

      const summaryValue =
        firstLeg[name] === 'vanilla'
          ? 'European Vanilla'
          : upperFirst(firstLeg[name])

      setSummaryValue(summaryValue)
    } else {
      // For fields requiring a combination of values from all legs, apply specific formatting rulesets to each field.
      setSummaryValue(generateSummaryValue(name, values))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(values), name])

  const updatedValue = state[`summary.${name}`]?.updatedValue

  return (
    <span
      className={classNames(
        'overflow-x-auto',
        tableClassNames.stringCell,
        tableClassNames.cellHeight,
        { [tableClassNames.cellState.warning]: updatedValue },
        { [tableClassNames.summaryCell]: !updatedValue }
      )}
    >
      {summaryValue}
    </span>
  )
}

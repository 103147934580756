import * as Sentry from '@sentry/browser'

export default function logBrowserError(
  error: Error | unknown,
  message?: string
) {
  Sentry.captureException(error)
  if (message) Sentry.captureMessage(message)

  const errorMessage = message
    ? [message, JSON.stringify(error, undefined, 4)]
    : [JSON.stringify(error, undefined, 4)]

  console.error(...errorMessage)
}

import {
  InputStateReducerState,
  InputState
} from '../../../../../../context/inputStateContext/inputStateReducer'
import { SummaryName, InputProps } from '../../../../../input/input'

/**
 * Returns an array of keys from the input state where a specific state key is set to true.
 */
function getInputStateKeys(
  state: InputStateReducerState,
  stateKey: keyof InputState
) {
  return Object.entries(state).reduce<(InputProps['name'] | SummaryName)[]>(
    (acc, [key, value]) => {
      if (value?.[stateKey]) {
        acc.push(key as InputProps['name'] | SummaryName)
      }

      return acc
    },
    []
  )
}

export default getInputStateKeys

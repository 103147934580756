'use client'

import React from 'react'
import Table, { AllPossibleKeys, Row } from '../../table/table'
import ButtonGroup from '../../buttonGroup'
import Button, { ButtonVariant } from '../../buttons/button/button'
import { FieldArray, Form, FormikErrors, useFormikContext } from 'formik'
import RefreshAllButton from '../../buttons/refreshAllButton/refreshAllButton'
import UsersListBox from '../../listBox/usersListBox/usersListBox'
import { usersListBoxFragment$key } from '../../listBox/usersListBox/__generated__/usersListBoxFragment.graphql'
import { AxeFormData } from '../../dialog/updateAxeDialog/updateAxeDialog'
import { ArrowsPointingInIcon, PlusIcon } from '@heroicons/react/20/solid'
import TextButton from '../../buttons/textButton/textButton'
import PopOverWithText from '../../popOverWithText/popOverWithText'
import { Variant } from '../../popOver/popOver'
import TableHeader from '../../tableHeader/tableHeader'
import { leg } from '../../buttons/createAxeDialogButton/createAxeDialogButton'
import useUpdateSearchParams from '../../../hooks/useUpdateSearchParams/useUpdateSearchParams'
import omit from 'lodash/omit'
import { useInputStateContext } from '../../../context/inputStateContext/inputStateContext'
import isEmpty from 'lodash/isEmpty'
import { TargetField } from '../../../context/inputStateContext/inputStateReducer'
import { InputProps } from '../../input/input'
import pick from 'lodash/pick'
import { formatDateFromISO } from '@optaxe/options-utils'

interface CreateAxeFormProps {
  readonly setOpen: React.Dispatch<React.SetStateAction<boolean>>
  readonly isMutationInFlight: boolean
  readonly formRef: React.RefObject<HTMLFormElement>
  readonly usersConnection?: usersListBoxFragment$key | usersListBoxFragment$key
  readonly setPanelIndex?: React.Dispatch<React.SetStateAction<number>>
  readonly validateForm: () => Promise<FormikErrors<AxeFormData>>
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleReset: () => void
  tableRows: Array<Row<keyof AllPossibleKeys>>
  tableRowGreeks: Array<Row<keyof AllPossibleKeys>>
}

export default function CreateAxeForm({
  setOpen,
  isMutationInFlight,
  formRef,
  usersConnection,
  setPanelIndex,
  validateForm,
  setIsDialogOpen,
  handleReset,
  tableRows,
  tableRowGreeks
}: CreateAxeFormProps) {
  const { isValid, values, dirty } = useFormikContext<AxeFormData>()

  const searchParamsUpdater = useUpdateSearchParams()
  const { dispatch, state } = useInputStateContext()

  const handleClick = React.useCallback(() => {
    if (dirty) {
      setIsDialogOpen(true)
    } else {
      setOpen(false)
      searchParamsUpdater({ axeId: '', rfqId: '' })
    }
  }, [setIsDialogOpen, dirty, setOpen, searchParamsUpdater])

  const [showSummaryOnly, setShowSummaryOnly] = React.useState(false)
  const disabled = isMutationInFlight

  const maxAxeLegs = 3
  const totalAxeLegs = values.legs.length

  /**
   * Relationship between spot/forward/swaps is handled on the client.
   * Changes to spot and forward values affects each other.
   * Changes to the swap impact the forward.
   * This is handled in the following component and supporting files:
   * * src/components/formDataProcessor/formDataProcessor.tsx
   * * src/components/formDataProcessor/config/config.ts
   */

  /**
   * When a leg is removed, we need to update or remove any targetFields that
   * reference that leg from the input state. Additionally, if the leg removal
   * results in a single leg, we need to remove the targetFields from the input
   * state.
   *
   * TODO: Shift this logic into FDP (OPT-1516)
   */
  function removeLegFromTargetFields(index: number) {
    Object.entries(state).forEach(([key, value]) => {
      // If the input state has no target fields, ignore.
      if (!value.targetFields || isEmpty(value.targetFields)) return

      const name = key as InputProps['name']

      // If the leg removal results in a single leg, remove the target fields (we check for a length of 2 because the leg is not yet removed from the state).
      if (values.legs.length <= 2) {
        dispatch({ type: 'unset_target_fields', key: name })

        return
      }

      // If the leg being removed (index) is not in the target fields, ignore.
      if (!value.targetFields.some(({ legIndex }) => legIndex === index)) return

      // If the leg being removed (index) is in the target fields, remove its reference in the target field array. To do this, create a new array without the target field that references the leg being removed
      const updatedTargetFields = value.targetFields.filter(
        (targetField: TargetField) => targetField.legIndex !== index
      )

      if (updatedTargetFields.length > 0) {
        dispatch({
          type: 'set_target_fields',
          key: name,
          payload: updatedTargetFields
        })
      } else {
        dispatch({ type: 'unset_target_fields', key: name })
      }
    })
  }

  /**
   *  When a leg is removed, we need to re-enable the ccyPair if only one leg
   *  remains. This should only occur on the create form, as the ccyPair is
   *  disabled on the update and RFQ forms.
   *
   * TODO: Shift this logic into FDP (OPT-1516)
   */
  function resetCcyPairDisabledState() {
    // If the leg removal results in a single leg, reset the disabled state on the ccyPair (we check for a length of 2 because the leg is not yet removed from the state).
    if (values.legs.length <= 2 && state['legs[0].ccyPair']?.isDisabled) {
      dispatch({ type: 'unset_is_disabled', key: 'legs[0].ccyPair' })
    }
  }

  return (
    <Form
      className="flex"
      role="form"
      ref={formRef}
      onReset={() => {
        if (!dirty) handleReset()
      }}
      noValidate
    >
      <FieldArray name="legs">
        {({ push, remove }) => (
          <div className="grow flex flex-col gap-4 max-h-[calc(100vh-135px)] min-h-[300px] -mx-3">
            <div className="flex justify-end shrink-0 px-3 z-20 gap-4">
              {totalAxeLegs > 1 && (
                <TextButton
                  className="grow-0"
                  icon={
                    <ArrowsPointingInIcon
                      height={14}
                      width={14}
                      aria-label="contract icon"
                    />
                  }
                  onClick={() => setShowSummaryOnly(!showSummaryOnly)}
                  type="button"
                >
                  {showSummaryOnly ? 'Maximise table' : 'Minimise table'}
                </TextButton>
              )}
              {isValid ? (
                <TextButton
                  className="grow-0"
                  icon={
                    <PlusIcon height={14} width={14} aria-label="plus icon" />
                  }
                  onClick={() => {
                    if (showSummaryOnly) {
                      setShowSummaryOnly(false)
                    }
                    // Copy all values that should remain consistent across all legs to prevent unnecessary looping by the form data processor.
                    push({
                      ...leg,
                      ...pick(values.legs[0], [
                        'product',
                        'ccyPair',
                        'notionalCurrency',
                        'premiumCurrency',
                        'hedgeType',
                        'spot'
                      ])
                    })
                  }}
                  type="button"
                  disabled={totalAxeLegs >= maxAxeLegs}
                >
                  Add new leg
                </TextButton>
              ) : (
                <PopOverWithText
                  button={
                    <TextButton
                      as="span"
                      className="grow-0"
                      icon={
                        <PlusIcon
                          height={14}
                          width={14}
                          aria-label="plus icon"
                        />
                      }
                    >
                      Add new leg
                    </TextButton>
                  }
                  popOverText={`Complete leg ${totalAxeLegs} before adding a new leg`}
                  anchor="left"
                  portal={false}
                  variant={Variant.ERROR}
                />
              )}
            </div>
            <div className="flex flex-col overflow-y-auto">
              <div className="px-3 relative">
                <TableHeader
                  maxColumns={maxAxeLegs}
                  onDelete={(index) => {
                    remove(index)
                    removeLegFromTargetFields(index)
                    resetCcyPairDisabledState()
                  }}
                  onDuplicate={(index) => {
                    push(omit(values.legs[index], 'id'))
                  }}
                  totalColumns={totalAxeLegs}
                  showSummaryOnly={showSummaryOnly}
                  values={values}
                  isSticky
                />
                <div className="flex flex-col gap-5">
                  <Table
                    rows={tableRows}
                    isUnderHeader
                    totalColumns={totalAxeLegs}
                    fieldArrayName="legs"
                    showSummaryOnly={showSummaryOnly}
                  />
                  <Table
                    rows={tableRowGreeks}
                    totalColumns={totalAxeLegs}
                    fieldArrayName="legs"
                    showSummaryOnly={showSummaryOnly}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between shrink-0 px-3 mt-2">
              {/* pl-[9px] is to cater for the table cell horizontal padding + border width */}
              <div className="flex flex-col gap-1 pl-[9px]">
                <RefreshAllButton />
                {values.updatedAt && (
                  <p className="text-xs font-semibold text-textLightGray">
                    Last updated:{' '}
                    {formatDateFromISO(values.updatedAt, 'shortDateTime')}
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-4">
                {!!usersConnection && (
                  <div className="flex flex-row justify-center items-center gap-2">
                    <span className="font-semibold text-sm flex-shrink-0">
                      Axed By:
                    </span>
                    <UsersListBox
                      isDisabled
                      name="axeAuthor"
                      users={usersConnection}
                    />
                  </div>
                )}
                <ButtonGroup
                  className="justify-end"
                  buttons={[
                    <Button
                      key="resetForm"
                      type="button"
                      onClick={handleClick}
                      styleVariant={ButtonVariant.SECONDARY}
                      tabIndex={-1}
                    >
                      Cancel
                    </Button>,
                    <Button
                      key="proceedForm"
                      onClick={async () => {
                        const validationResult = await validateForm()

                        if (Object.keys(validationResult).length === 0) {
                          if (setPanelIndex) {
                            setPanelIndex(1)
                          }
                        }
                      }}
                      disabled={disabled}
                      type="submit"
                    >
                      Proceed
                    </Button>
                  ]}
                />
              </div>
            </div>
          </div>
        )}
      </FieldArray>
    </Form>
  )
}

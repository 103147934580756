import isNil from 'lodash/isNil'
import { ProcessFieldConfigParams } from '../../processFieldConfig'

export default function isUserCounterParty({
  ownerSubOrganizationId,
  userSubOrganizationId
}: Pick<
  ProcessFieldConfigParams['formik']['values'],
  'ownerSubOrganizationId' | 'userSubOrganizationId'
>) {
  return (
    !isNil(userSubOrganizationId) &&
    !isNil(ownerSubOrganizationId) &&
    userSubOrganizationId !== ownerSubOrganizationId
  )
}

import merge from 'lodash/merge'
import set from 'lodash/set'
import isNil from 'lodash/isNil'
import { OnValuesDiffParams } from '../../config'
import setSameAcrossAllLegs from '../setSameAcrossAllLegs/setSameAcrossAllLegs'
import { AxeFormData } from '../../../../dialog/updateAxeDialog/updateAxeDialog'
import isLegFieldPath from '../../../utils/isLegFieldPath/isLegFieldPath'
import { calculateSwaps, calculateForward } from '@optaxe/options-utils'

/**
 * Synchronise changes to the initial spot field across all other legs.
 * Additionally, calculate the swaps or forward for each leg based on the spot
 * field, taking into account the state of the other fields.
 */
function processOnSpotDiff(
  onValuesDiffParams: Pick<
    OnValuesDiffParams,
    'formik' | 'inputStateContext' | 'path'
  >
) {
  const {
    formik: { values },
    inputStateContext,
    path
  } = onValuesDiffParams
  if (!isLegFieldPath(path)) return null

  const [arrayName, legIndex] = path

  const valuesToUpdate = {} as AxeFormData

  merge(valuesToUpdate, setSameAcrossAllLegs(onValuesDiffParams)?.values)

  const legIndexesToUpdate = Array.from(
    { length: values.legs.length },
    (_, i) => i
  )

  // Although all legs share the same spot, we need to use the updated spot for this leg as the reference.
  // This ensures that if the expiry date triggers a change, we are using the latest spot value from this leg.
  const { spot } = values.legs[legIndex]

  // When adding a new leg, the spot for the new leg won’t be set at this stage.
  // Since there’s no need to update any previous spots or calculate any swaps or forwards in this use case, it’s safe to exit early (with valuesToUpdate as we still need the spot provided via setSameAcrossAllLegs).
  if (isNil(spot)) {
    return { values: valuesToUpdate }
  }

  legIndexesToUpdate.forEach((legIndexToUpdate) => {
    const { swaps, forward } = values.legs[legIndexToUpdate]

    set(valuesToUpdate, [arrayName, legIndexToUpdate, 'spot'], spot)

    const swapsFieldState =
      inputStateContext.state[`${arrayName}[${legIndexToUpdate}].swaps`]

    const isSwapsFieldOverTypedValueValid =
      !isNil(swapsFieldState?.overTypedValue) &&
      !isNaN(Number(swapsFieldState?.overTypedValue))

    const isSwapsFieldOverTyped =
      swapsFieldState?.overTyped && isSwapsFieldOverTypedValueValid

    if (!isNil(forward) && !isSwapsFieldOverTyped) {
      const swaps = calculateSwaps(spot, forward)
      set(valuesToUpdate, [arrayName, legIndexToUpdate, 'swaps'], swaps)
    } else if (!isNil(swaps)) {
      const forward = calculateForward(spot, swaps)
      set(valuesToUpdate, [arrayName, legIndexToUpdate, 'forward'], forward)
    }
  })

  return {
    values: valuesToUpdate
  }
}

export default processOnSpotDiff

import {
  Disclosure as HeadlessDisclosure,
  DisclosurePanel,
  DisclosureButton
} from '@headlessui/react'
import React from 'react'

type ButtonProps = React.FC<
  React.PropsWithChildren<React.HTMLProps<HTMLButtonElement>>
>

interface DisclosureProps extends React.HTMLProps<HTMLDivElement> {
  buttonContent: (arg: ButtonProps, open: boolean) => JSX.Element
  panelContent: React.ReactNode
  className?: string
  testId?: string
}

const Disclosure = React.forwardRef(
  (
    { buttonContent, panelContent, className, testId }: DisclosureProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <HeadlessDisclosure
        ref={ref}
        as="div"
        role="accordion"
        className={className}
        data-testid={testId}
      >
        {({ open }) => (
          <>
            {buttonContent(DisclosureButton as ButtonProps, open)}
            <DisclosurePanel className="text-textGray">
              {panelContent}
            </DisclosurePanel>
          </>
        )}
      </HeadlessDisclosure>
    )
  }
)

Disclosure.displayName = 'Disclosure'

export default Disclosure

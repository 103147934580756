import { useFormikContext } from 'formik'
import { AxeFormData } from '../../components/dialog/updateAxeDialog/updateAxeDialog'
import { useWorkerContext } from '../../context/marketDataWorkerContext/marketDataWorkerContext'
import { useInputStateContext } from '../../context/inputStateContext/inputStateContext'
import { useCallback } from 'react'

const useResetForm = (callback: () => void) => {
  const { resetForm } = useFormikContext<AxeFormData>()
  const { resetMarketValues } = useWorkerContext()
  const { dispatch } = useInputStateContext()

  const handleReset = useCallback(() => {
    resetForm()
    resetMarketValues()
    dispatch({ type: 'reset', key: 'all' })
    callback()
  }, [dispatch, resetForm, resetMarketValues, callback])

  return handleReset
}

export default useResetForm

/**
 * @generated SignedSource<<9273800d10b1bf868b4a1b085b39674e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type clearExpiredRfqsGetExpiredRfqsQuery$variables = Record<PropertyKey, never>;
export type clearExpiredRfqsGetExpiredRfqsQuery$data = {
  readonly rfq_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly axeId: any;
        readonly axeOwnerUserId: string;
        readonly id: string;
        readonly rfq_options: ReadonlyArray<{
          readonly id: string;
          readonly rfq_options_legs: ReadonlyArray<{
            readonly bsDelta: any | null | undefined;
            readonly delta: any | null | undefined;
            readonly premium: number | null | undefined;
            readonly pricingVolatility: any | null | undefined;
            readonly rfqLegId: any;
          }>;
        }>;
      };
    }>;
  };
};
export type clearExpiredRfqsGetExpiredRfqsQuery = {
  response: clearExpiredRfqsGetExpiredRfqsQuery$data;
  variables: clearExpiredRfqsGetExpiredRfqsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "expireAt": {
        "_lt": "now()"
      },
      "status": {
        "_in": [
          "NewOrder",
          "Quote",
          "QuoteRequest",
          "QuoteRequestAck"
        ]
      }
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "axeId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "axeOwnerUserId",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "limit",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "createdAt": "desc"
    }
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bsDelta",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "delta",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pricingVolatility",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "premium",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rfqLegId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "clearExpiredRfqsGetExpiredRfqsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "rfqConnection",
        "kind": "LinkedField",
        "name": "rfq_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "rfqEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "rfq",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "rfq_options",
                    "kind": "LinkedField",
                    "name": "rfq_options",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "rfq_options_legs",
                        "kind": "LinkedField",
                        "name": "rfq_options_legs",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "rfq_options(limit:1,order_by:{\"createdAt\":\"desc\"})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "rfq_connection(where:{\"expireAt\":{\"_lt\":\"now()\"},\"status\":{\"_in\":[\"NewOrder\",\"Quote\",\"QuoteRequest\",\"QuoteRequestAck\"]}})"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "clearExpiredRfqsGetExpiredRfqsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "rfqConnection",
        "kind": "LinkedField",
        "name": "rfq_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "rfqEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "rfq",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "rfq_options",
                    "kind": "LinkedField",
                    "name": "rfq_options",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "rfq_options_legs",
                        "kind": "LinkedField",
                        "name": "rfq_options_legs",
                        "plural": true,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "rfq_options(limit:1,order_by:{\"createdAt\":\"desc\"})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "rfq_connection(where:{\"expireAt\":{\"_lt\":\"now()\"},\"status\":{\"_in\":[\"NewOrder\",\"Quote\",\"QuoteRequest\",\"QuoteRequestAck\"]}})"
      }
    ]
  },
  "params": {
    "cacheID": "63af2ac7200b5e3da25530d78d855d5d",
    "id": null,
    "metadata": {},
    "name": "clearExpiredRfqsGetExpiredRfqsQuery",
    "operationKind": "query",
    "text": "query clearExpiredRfqsGetExpiredRfqsQuery {\n  rfq_connection(where: {expireAt: {_lt: \"now()\"}, status: {_in: [NewOrder, Quote, QuoteRequest, QuoteRequestAck]}}) {\n    edges {\n      node {\n        id\n        axeId\n        axeOwnerUserId\n        rfq_options(order_by: {createdAt: desc}, limit: 1) {\n          id\n          rfq_options_legs {\n            bsDelta\n            delta\n            pricingVolatility\n            premium\n            rfqLegId\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c5b0d6fa5984a596b5ef2daf1bdd12ea";

export default node;

/* eslint-disable relay/must-colocate-fragment-spreads */
/* eslint-disable relay/unused-fields */
'use client'

import React from 'react'
import Button from '../button/button'
import Dialog, { SizeVariant } from '../../dialog/dialog'
import { Formik, FormikErrors, useFormikContext } from 'formik'
import CreateAxeForm from '../../forms/createAxe/createAxe'
import WorkerContextProvider, {
  useWorkerContext
} from '../../../context/marketDataWorkerContext/marketDataWorkerContext'
import InputStateContextProvider from '../../../context/inputStateContext/inputStateContext'
import { PencilSquareIcon } from '@heroicons/react/24/solid'
import {
  graphql,
  PreloadedQuery,
  useFragment,
  useMutation,
  usePreloadedQuery,
  useQueryLoader
} from 'react-relay/hooks'
import { DateTime } from 'luxon'
import nestedObjectHasAllNilValues from '../../../utils/nestedObjectHasAllNilValues/nestedObjectHasAllNilValues'
import schema from './schema.yup'
import toast from 'react-hot-toast'
import classNames from 'classnames'
import {
  axes_insert_input,
  createAxeDialogButtonMutation,
  enum_axe_depoccy1daycount_enum,
  enum_axe_depoccy2daycount_enum
} from './__generated__/createAxeDialogButtonMutation.graphql'
import {
  AxeFormData,
  AxeFormLegData
} from '../../dialog/updateAxeDialog/updateAxeDialog'
import getIDFromBase64 from '../../../utils/getIDFromBase64/getIDFromBase64'
import { createAxeDialogButtonQuery } from './__generated__/createAxeDialogButtonQuery.graphql'
import { useUser } from '@auth0/nextjs-auth0/client'
import Stepper from '../../stepper/stepper'
import CreateAxeTiers from '../../forms/createAxe/createAxeTiers'
import { v4 as uuidv4 } from 'uuid'
import { createAxeUserTiersForAxe } from '../../../utils/createAxeUserTiersForAxe/createAxeUserTiersForAxe'
import { createAxePricingTiers } from '../../../utils/createAxePricingTiers/createAxePricingTiers'
import { omitNonAxeSpecificFormFields } from '../../../utils/omitNonAxeSpecificFormFields/omitNonAxeSpecificFormFields'
import { Tier } from '../../tierSelector/tierSelector'
import {
  getRemovedData,
  getTierData
} from '../../../app/counterparties/components/userTierComponent'
import { createAxeDialogButtonTiersFragment$key } from './__generated__/createAxeDialogButtonTiersFragment.graphql'
import useUpdateSearchParams from '../../../hooks/useUpdateSearchParams/useUpdateSearchParams'
import ConfirmCancelAxeDialog from '../../forms/createAxe/ConfirmCancelAxeDialog'
import useResetForm from '../../../hooks/useResetForm/useResetForm'
import { useEnvVariablesContext } from '../../../context/variablesContext/variablesContext'
import { hasOptaxeAdminRole } from '../../../utils/roles/roles'
import {
  getSubOrgOnClient,
  subOrgKeyFE
} from '../../../utils/getSubOrg/getSubOrg'
import useSubOrgUserRef from '../../tierSelector/hooks/useSubOrgUserRef'
import FormDataProcessor from '../../formDataProcessor/formDataProcessor'
import { Enum_Axe_Product_Enum } from '../../../gql'
import { tableRows, tableRowGreeks } from './tableRows'

export const CreateAxeDialogButtonAuthorFragment = graphql`
  fragment createAxeDialogButtonAuthorFragment on axe_authors {
    user {
      id
      subOrganizationId
    }
  }
`

export const CreateAxeDialogButtonFragment = graphql`
  fragment createAxeDialogButtonFragment on axes {
    updatedAt
    axe_authors {
      __typename
      ...createAxeDialogButtonAuthorFragment
      ...profileBadgeAxeAuthorFragment
    }
    axe_legs(order_by: { orderIndex: asc }) {
      product
      ccyPair
      buySell
      tenor
      expiryDate
      deliveryDate
      cut
      fix
      strike
      callPut
      notional
      notionalCurrency
      premium
      premiumCurrency
      spot
      swaps
      forward
      volatility
      hedgeType
      minimumNotionalAmount
      delta
      gamma
      vega
      pricingVolatility
      premiumType
    }
  }
`

export const CreateAxeDialogButtonAxeLegsFragment = graphql`
  fragment createAxeDialogButtonAxeLegsFragment on axe_legs {
    updatedAt
    product
    ccyPair
    buySell
    tenor
    expiryDate
    deliveryDate
    cut
    fix
    strike
    callPut
    notional
    notionalCurrency
    premium
    premiumCurrency
    spot
    swaps
    forward
    volatility
    hedgeType
    minimumNotionalAmount
    delta
    gamma
    vega
    pricingVolatility
    premiumType
  }
`

export const leg: AxeFormLegData = {
  product: Enum_Axe_Product_Enum.Vanilla,
  ccyPair: undefined as unknown as AxeFormLegData['ccyPair'],
  buySell: undefined,
  tenor: undefined,
  expiryDate: undefined,
  deliveryDate: undefined,
  cut: undefined,
  fix: undefined,
  strike: undefined,
  callPut: undefined,
  notional: undefined,
  notionalCurrency: undefined,
  premium: undefined as unknown as AxeFormLegData['premium'],
  premiumCurrency: undefined,
  premiumDate: undefined,
  premiumType: undefined,
  spot: undefined,
  swaps: undefined,
  forward: undefined,
  volatility: undefined,
  pricingVolatility: undefined,
  pricingVolatilityT2: undefined,
  pricingVolatilityT3: undefined,
  hedgeType: undefined,
  minimumNotionalAmount: undefined,
  delta: undefined,
  gamma: undefined,
  vega: undefined
}

export const initialValues: AxeFormData = {
  id: undefined,
  axeAuthor: undefined,
  tiers: [],
  legs: [leg]
}

export const createAxeMutation = graphql`
  mutation createAxeDialogButtonMutation(
    $axe: axes_insert_input!
    $axePricingTiers: [axe_tier_pricing_insert_input!]!
    $userTiers: [user_tiers_insert_input!]!
  ) {
    insert_axes_one(object: $axe) {
      id
      ...createAxeDialogButtonFragment
    }
    insert_axe_tier_pricing(objects: $axePricingTiers) {
      returning {
        id
      }
    }
    insert_user_tiers(objects: $userTiers) {
      returning {
        id
      }
    }
  }
`

const getISO = (date: Date | string) =>
  typeof date === 'string' ? date : DateTime.fromJSDate(date).toISO()

/**
 * Some values aren't set in the form, but are received from the market API and
 * should be stored. Our API doesn't support the returned values in their raw
 * form, so we modify them slightly
 */
export const parseAxeLegValues = (values: AxeFormLegData) => ({
  ...values,
  tenor: values.tenor || null,
  baseCurrDepo: values.depoCcy1,
  pricingCurrDepo: values.depoCcy2,
  expiryDate: getISO(values.expiryDate),
  premiumDate: getISO(values.premiumDate),
  // premiumType: values.hedgeType,
  spotDate: getISO(values.spotDate),
  strike: Number(values.marketStrike) || Number(values.strike),
  depoCcy1DayCount: values.depoCcy1DayCount?.replace(
    '/',
    '_'
  ) as enum_axe_depoccy1daycount_enum,
  depoCcy2DayCount: values.depoCcy2DayCount?.replace(
    '/',
    '_'
  ) as enum_axe_depoccy2daycount_enum
})

export const parseCreateAxeValues = ({
  axeAuthor,
  axeAuthorSubOrganizationId,
  axeAuthorOrganizationId,
  id
}: {
  axeAuthor: string
  axeAuthorSubOrganizationId: string
  axeAuthorOrganizationId: string
  id: string
}): axes_insert_input => ({
  id,
  axe_authors: {
    data: [
      {
        user_id: getIDFromBase64(axeAuthor || ''),
        subOrganizationId: axeAuthorSubOrganizationId,
        organizationId: axeAuthorOrganizationId
      }
    ]
  }
})

export const CreateAxeDialogButtonQuery = graphql`
  query createAxeDialogButtonQuery(
    $subOrganizationId: uuid!
    $userId: String!
  ) {
    users_connection(
      where: { subOrganizationId: { _eq: $subOrganizationId } }
    ) {
      ...usersListBoxFragment
    }
    user_main_tiering_connection(where: { ownerId: { _eq: $userId } }) {
      ...createAxeDialogButtonTiersFragment
    }
  }
`

export const CreateAxeDialogButtonTiersFragment = graphql`
  fragment createAxeDialogButtonTiersFragment on user_main_tieringConnection {
    edges {
      node {
        id
        organizationId
        tier
        subOrganizationId
        isRemoved
        user_main_tiering_organization {
          displayName
          id
        }
        user_main_tiering_subOrganization {
          id
          name
        }
      }
    }
  }
`

interface CreateAxeFormDialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  userQueryRef: PreloadedQuery<createAxeDialogButtonQuery>
  isMutationInFlight: boolean
  formRef: React.RefObject<HTMLFormElement>
  validateForm: () => Promise<FormikErrors<AxeFormData>>
}

export function CreateAxeFormDialog({
  open,
  setOpen,
  userQueryRef,
  isMutationInFlight,
  formRef,
  validateForm
}: CreateAxeFormDialogProps) {
  const data = usePreloadedQuery(CreateAxeDialogButtonQuery, userQueryRef)
  const orgDataForTiers = useFragment(
    CreateAxeDialogButtonTiersFragment,
    data.user_main_tiering_connection as createAxeDialogButtonTiersFragment$key
  )
  const subOrgUsersQueryRef = useSubOrgUserRef(orgDataForTiers)
  const tiers: Tier[] = getTierData(orgDataForTiers?.edges)
  const removedSubOrgs = getRemovedData(orgDataForTiers?.edges)

  const [panelIndex, setPanelIndex] = React.useState(0)
  const searchParamsUpdater = useUpdateSearchParams()

  const handleReset = useResetForm(() => {
    setPanelIndex(0)
  })

  const { resetWorkerCache } = useWorkerContext()

  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const { dirty } = useFormikContext<AxeFormData>()

  return (
    <Dialog
      open={open}
      setOpen={setOpen}
      title={'Create Axe'}
      subtitle={`Step ${panelIndex + 1} of 2`}
      size={SizeVariant.LARGE_5X}
      fitWidthToContent
      onClose={(byXMark) => {
        if (dirty && !byXMark) {
          setIsDialogOpen(true)
        } else {
          setOpen(false)
        }
      }}
      closeWithXMark={false}
    >
      <Stepper
        panelIndex={panelIndex}
        panels={[
          <CreateAxeForm
            key={0}
            setOpen={setOpen}
            isMutationInFlight={isMutationInFlight}
            formRef={formRef}
            setPanelIndex={setPanelIndex}
            usersConnection={data?.users_connection}
            validateForm={validateForm}
            handleReset={handleReset}
            setIsDialogOpen={setIsDialogOpen}
            tableRows={tableRows}
            tableRowGreeks={tableRowGreeks}
          />,
          <>
            {subOrgUsersQueryRef && (
              <CreateAxeTiers
                key={1}
                disabled={isMutationInFlight}
                handleClick={() => {
                  searchParamsUpdater({ axeId: '', rfqId: '' })
                  setPanelIndex(0)
                  setOpen(!open)
                  handleReset()
                }}
                backAction={() => {
                  setPanelIndex(0)
                }}
                tierData={tiers}
                removedSubOrgs={removedSubOrgs}
                subOrgUsersQueryRef={subOrgUsersQueryRef}
              />
            )}
          </>
        ]}
      />
      <ConfirmCancelAxeDialog
        open={isDialogOpen}
        onConfirm={() => {
          handleReset()
          setIsDialogOpen(false)
          setOpen(!open)
          resetWorkerCache()
        }}
        onCancel={() => {
          setIsDialogOpen(false)
        }}
        setOpen={() => {
          setIsDialogOpen(false)
        }}
      />
    </Dialog>
  )
}

export default function DialogButton({
  navigationIsOpen
}: {
  readonly navigationIsOpen: boolean
}) {
  const { user, isLoading } = useUser()
  const [open, setOpen] = React.useState(false)
  const [commitMutation, isMutationInFlight] =
    useMutation<createAxeDialogButtonMutation>(createAxeMutation)
  const formRef = React.useRef<HTMLFormElement>(null)
  const [userQueryRef, userLoadQuery] =
    useQueryLoader<createAxeDialogButtonQuery>(CreateAxeDialogButtonQuery)

  const variablesContext = useEnvVariablesContext()
  const isOptAxeAdmin = hasOptaxeAdminRole(
    user,
    variablesContext?.rolesKey || ''
  )
  const subOrg = getSubOrgOnClient(user || {})

  React.useEffect(() => {
    if (open && user?.sub && subOrg) {
      userLoadQuery({
        userId: user?.sub,
        subOrganizationId: subOrg
      })
    }

    if (!subOrg && !isLoading) {
      console.error('No subOrganizationId found for user', subOrgKeyFE)
    }
  }, [open, userLoadQuery, user?.sub, isLoading, subOrg])

  const dynamicInitialValues = React.useMemo(() => {
    return user?.sub
      ? {
          ...initialValues,
          axeAuthor: btoa(`[1, "public", "users", "${user.sub}"]`)
        }
      : initialValues
  }, [user])

  return (
    <Formik
      initialValues={dynamicInitialValues}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={async (values) => {
        toast.promise(
          new Promise((resolve, reject) => {
            const axeId = uuidv4()
            const leg1Values = values.legs[0]

            const axeValues = parseCreateAxeValues({
              axeAuthor: values.axeAuthor || '',
              axeAuthorSubOrganizationId: subOrg || '',
              axeAuthorOrganizationId: user?.org_id || '',
              id: axeId
            })

            // As pricingVolatilityT2 and pricingVolatilityT3 are not saved in
            // the Axe table We need to remove them from the values object
            // before sending to the server
            const axeValuesWithoutKeys = omitNonAxeSpecificFormFields(axeValues)

            const axeLegs = values.legs.map((leg, index) => {
              const legValues = parseAxeLegValues(leg)
              const legValuesWithoutKeys =
                omitNonAxeSpecificFormFields(legValues)

              return {
                ...legValuesWithoutKeys,
                orderIndex: index + 1
              }
            })
            const userTierData = createAxeUserTiersForAxe(
              axeId,
              user?.sub || '',
              values.tiers || [],
              values.removedSubOrgIds || []
            )
            commitMutation({
              variables: {
                axe: {
                  ...axeValuesWithoutKeys,
                  axe_legs: {
                    data: axeLegs
                  }
                },
                axePricingTiers: createAxePricingTiers(
                  axeId,
                  leg1Values.pricingVolatility,
                  leg1Values.pricingVolatilityT2 ||
                    leg1Values.pricingVolatility,
                  leg1Values.pricingVolatilityT3 ||
                    leg1Values.pricingVolatility,
                  values.tiers || []
                ),
                userTiers: userTierData
              },
              onError: (error) => {
                console.error(error)
                reject(error)
              },
              onCompleted: (value) => {
                resolve(value)
                setOpen(false)
                formRef.current?.reset()
              }
            })
          }),
          {
            loading: 'Creating Axe...',
            success: 'New Axe Created',
            error: 'Error creating axe. Please try again.'
          }
        )
      }}
      onReset={() => {
        setOpen(false)
      }}
    >
      {({ values, validateForm }) => (
        <InputStateContextProvider>
          <WorkerContextProvider>
            <FormDataProcessor formType="createAxe">
              <Button
                className="w-full flex flex-row gap-2 px-0 justify-center"
                onClick={() => setOpen(!open)}
                navigationButton
                navigationIsOpen={navigationIsOpen}
                data-testid="create-axe-button"
                disabled={isOptAxeAdmin}
              >
                <PencilSquareIcon height="20px" width="20px" />
                <span className={classNames({ hidden: !navigationIsOpen })}>
                  {nestedObjectHasAllNilValues(values)
                    ? 'Add New Axe'
                    : !open
                      ? 'Resume Draft'
                      : 'Add New Axe'}
                </span>
              </Button>
              {userQueryRef && (
                <CreateAxeFormDialog
                  open={open}
                  setOpen={setOpen}
                  userQueryRef={userQueryRef}
                  isMutationInFlight={isMutationInFlight}
                  formRef={formRef}
                  validateForm={validateForm}
                />
              )}
            </FormDataProcessor>
          </WorkerContextProvider>
        </InputStateContextProvider>
      )}
    </Formik>
  )
}

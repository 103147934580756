import React from 'react'

const isDecimal = (value: string) => value.includes('.')

export const lengthInRange = (
  value: string,
  end: number,
  maxDecimals: number
) =>
  isDecimal(value)
    ? value.length > 0 && value.split('.')[1].length <= maxDecimals
    : value.length > 0 && value.length <= end

export const getMultipliedValue = (value: string, decimalScale: number) => {
  const multiplyValue = (value: string, multiplier: number) =>
    parseFloat(value) * multiplier
  const clearValue = (value: string, reg: RegExp) => value.replace(reg, '')

  const lowerCaseValue = value.toLowerCase()
  const clearedValue = clearValue(value, /[mb]/gi)
  const absoluteValue = String(Math.abs(Number(clearedValue)))

  if (
    lowerCaseValue.endsWith('m') &&
    lengthInRange(absoluteValue, 3, decimalScale)
  ) {
    return multiplyValue(clearedValue, 1000000)
  }

  if (
    lowerCaseValue.endsWith('b') &&
    lengthInRange(absoluteValue, 1, decimalScale)
  ) {
    return multiplyValue(clearedValue, 1000000000)
  }

  return parseFloat(clearValue(value, /,/gi))
}

const inputCtrlHotKeys = ['a', 'c', 'v', 'x', 'r']
const inputAlphaNumericKeysWithNegative = '[mb0-9\\-]'
const inputAlphaNumericKeysWithoutNegative = '[mb0-9]'
const inputFunctionalKeys =
  '(?:Backspace|Delete|Home|End|ArrowLeft|ArrowRight|Shift|CapsLock|Control|NumLock|Tab|Paste|Redo|Undo)'

const numericHotKeys = [
  { char: 'm', maxLength: 3 },
  { char: 'b', maxLength: 1 }
]

export const numericFastKeysKeyDownHelper = (
  e: React.KeyboardEvent<HTMLInputElement>,
  decimalScale: number,
  onEnter: () => void,
  min?: number | string
) => {
  const allowNegative = min === undefined || Number(min) < 0
  //support hotkeys for copy, paste, cut, select all
  if (e.ctrlKey && inputCtrlHotKeys.includes(e.key.toLowerCase())) return

  const value = (e.target as HTMLInputElement).value

  //Prevent entering more than {decimalScale} decimal places
  if (isDecimal(value) && /[0-9]/.test(e.key)) {
    if (value.split('.')[1].length >= decimalScale) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  numericHotKeys.forEach(({ char, maxLength }) => {
    const maxLengthWithNegative =
      allowNegative && value[0] === '-' ? maxLength + 1 : maxLength

    if (
      e.key.toLowerCase() === char &&
      (/[mb]/i.test(value) ||
        !lengthInRange(value, maxLengthWithNegative, decimalScale))
    ) {
      e.preventDefault()
      e.stopPropagation()

      return
    }
  })

  if (e.key === 'Enter') {
    onEnter()
    e.preventDefault()
    e.stopPropagation()

    return
  }

  const regexPattern = allowNegative
    ? `^(?:${inputAlphaNumericKeysWithNegative}|${inputFunctionalKeys})$`
    : `^(?:${inputAlphaNumericKeysWithoutNegative}|${inputFunctionalKeys})$`

  const regex = new RegExp(regexPattern, 'i')

  if (!regex.test(e.key)) {
    e.preventDefault()
    e.stopPropagation()
  }
}

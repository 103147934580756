import { InformationCircleIcon } from '@heroicons/react/24/solid'
import PopOverWithText, {
  PopOverProps
} from '../popOverWithText/popOverWithText'
import classNames from 'classnames'

interface InfoLinkProps extends Omit<PopOverProps, 'icon'> {
  className?: string
  linkText: string
}

const InfoLink = ({ className, linkText, ...rest }: InfoLinkProps) => {
  return (
    <div
      className={classNames(
        'dark:text-lightGray text-textGray text-sm font-semibold flex',
        className
      )}
    >
      <PopOverWithText
        {...rest}
        button={
          <div className="flex gap-1">
            <span>{linkText.trim()}</span>
            <InformationCircleIcon
              className={
                'rounded-full bg-red-300 h-[20px] w-[20px] cursor-pointer bg-white dark:bg-darkBlue shadow-2xl'
              }
            />
          </div>
        }
      />
    </div>
  )
}

export default InfoLink

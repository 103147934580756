import uniq from 'lodash/uniq'
import { useInputStateContext } from '../../../../context/inputStateContext/inputStateContext'
import { rfqAxeDialog_rfqFragment$data } from '../../../dialog/rfqAxeDialog/__generated__/rfqAxeDialog_rfqFragment.graphql'
import { AxeFormData } from '../../../dialog/updateAxeDialog/updateAxeDialog'
import { SummaryName } from '../../../../components/input/input'
import getDiffKeys from './utils/getDiffKeys/getDiffKeys'
import getUpdatedValuesDiff from './utils/getUpdatedValuesDiff/getUpdatedValuesDiff'
import getInputStateKeys from './utils/getInputStateKeys/getInputStateKeys'

type UseHighlightUpdatedValuesProps = {
  rfqData?: rfqAxeDialog_rfqFragment$data
  showUpdatedValueHighlights?: boolean
}

/**
 * This hook is responsible for determining which form values differ from the stored RFQ data and updating the
 * `inputStateContext` to highlight them appropriately.
 */
const useHighlightUpdatedValues = ({
  rfqData,
  showUpdatedValueHighlights
}: UseHighlightUpdatedValuesProps) => {
  const { dispatch, state } = useInputStateContext()

  return (values: AxeFormData) => {
    if (!showUpdatedValueHighlights || !rfqData) return

    // Get the keys of the form values that differ from the stored RFQ data.
    const valuesDiff = getUpdatedValuesDiff(rfqData, values)
    const valuesDiffKeys = getDiffKeys(valuesDiff)

    // Generate keys for the summary values that correspond to the updated leg values.
    const summaryKeys = uniq(
      valuesDiffKeys?.map((key) => `summary.${key.split('.')[1]}`)
    ) as SummaryName[]

    // Get the keys from the input state where the `updatedValue` state is set to true, so that we can determine which
    // keys to set or unset.
    const inputStateValuesChangedKeys = getInputStateKeys(state, 'updatedValue')

    const keysToSetAsUpdatedValue = [...valuesDiffKeys, ...summaryKeys]

    keysToSetAsUpdatedValue.forEach((key) => {
      if (inputStateValuesChangedKeys?.includes(key)) return

      dispatch({ type: 'set_updated_value', key })
    })

    inputStateValuesChangedKeys.forEach((key) => {
      if (keysToSetAsUpdatedValue.includes(key)) return

      dispatch({ type: 'unset_updated_value', key })
    })
  }
}

export default useHighlightUpdatedValues

import isArray from 'lodash/isArray'
import isString from 'lodash/isString'

export type NonLegFieldPath = [string]

export const isNonLegFieldPath = (
  path: unknown[] | undefined
): path is NonLegFieldPath =>
  isArray(path) && path.length === 1 && isString(path[0])

export default isNonLegFieldPath

import { DateTime } from 'luxon'
import {
  Enum_Axe_Product_Enum,
  Enum_Axe_Ccypair_Enum,
  Enum_Axe_Buysell_Enum,
  Enum_Tenor_Enum,
  Enum_Axe_Callput_Enum,
  Enum_Currency_Notional_Enum,
  Enum_Currency_Premium_Enum,
  Enum_Axe_Depoccy1daycount_Enum,
  Enum_Axe_Depoccy2daycount_Enum,
  Enum_Axe_Hedgetype_Enum,
  Enum_Axe_Premiumtype_Enum
} from '../../../gql'
import {
  AxeFormData,
  AxeFormLegData
} from '../../dialog/updateAxeDialog/updateAxeDialog'

export const quickAxeLegData: AxeFormData = {
  legs: [
    {
      product: Enum_Axe_Product_Enum.Vanilla,
      ccyPair: Enum_Axe_Ccypair_Enum.Eurusd,
      buySell: Enum_Axe_Buysell_Enum.Buy,
      tenor: Enum_Tenor_Enum.T_3M,
      spotDate: DateTime.now().plus({ months: 3, days: 2 }).toISO(),
      expiryDate: DateTime.now().plus({ months: 3 }).toISO(),
      deliveryDate: DateTime.now().plus({ months: 3 }).toISO(),
      premiumDate: DateTime.now().plus({ months: 3 }).toISO(),
      cut: 'NY1000',
      strike: 1.07005,
      callPut: Enum_Axe_Callput_Enum.Put,
      notional: 100000000,
      notionalCurrency: Enum_Currency_Notional_Enum.Eur,
      premium: 1282500,
      premiumCurrency: Enum_Currency_Premium_Enum.Eur,
      baseCurrDepo: 0.0392261771,
      depoCcy1: 0.0392261771,
      depoCcy1DayCount: Enum_Axe_Depoccy1daycount_Enum.Act_360,
      depoCcy2: 0.0567,
      depoCcy2DayCount: Enum_Axe_Depoccy2daycount_Enum.Act_360,
      spot: 1.07005,
      swaps: 0.00468,
      forward: 1.07473,
      volatility: 7.5,
      hedgeType: Enum_Axe_Hedgetype_Enum.Forward,
      minimumNotionalAmount: 25000000,
      delta: 46000000,
      gamma: 11000000,
      vega: 195000,
      pricingVolatility: 7.5,
      pricingVolatilityT2: 7.8,
      pricingVolatilityT3: 8,
      premiumType: Enum_Axe_Premiumtype_Enum.Forward,
      orderIndex: 1
    },
    {
      product: Enum_Axe_Product_Enum.Vanilla,
      ccyPair: Enum_Axe_Ccypair_Enum.Eurusd,
      buySell: Enum_Axe_Buysell_Enum.Buy,
      tenor: Enum_Tenor_Enum.T_6M,
      spotDate: DateTime.now().plus({ months: 6, days: 2 }).toISO(),
      expiryDate: DateTime.now().plus({ months: 6 }).toISO(),
      deliveryDate: DateTime.now().plus({ months: 6 }).toISO(),
      premiumDate: DateTime.now().plus({ months: 6 }).toISO(),
      cut: 'NY1000',
      strike: 1.27005,
      callPut: Enum_Axe_Callput_Enum.Put,
      notional: 50000000,
      notionalCurrency: Enum_Currency_Notional_Enum.Eur,
      premium: 3282500,
      premiumCurrency: Enum_Currency_Premium_Enum.Eur,
      baseCurrDepo: 0.0592261771,
      depoCcy1: 0.0592261771,
      depoCcy1DayCount: Enum_Axe_Depoccy1daycount_Enum.Act_360,
      depoCcy2: 0.0767,
      depoCcy2DayCount: Enum_Axe_Depoccy2daycount_Enum.Act_360,
      spot: 1.47005,
      swaps: 0.20468,
      forward: 1.27473,
      volatility: 8,
      hedgeType: Enum_Axe_Hedgetype_Enum.Forward,
      minimumNotionalAmount: 12500000,
      delta: 66000000,
      gamma: 31000000,
      vega: 395000,
      pricingVolatility: 9.5,
      pricingVolatilityT2: 9.8,
      pricingVolatilityT3: 10,
      premiumType: Enum_Axe_Premiumtype_Enum.Forward,
      orderIndex: 2
    },
    {
      product: Enum_Axe_Product_Enum.Vanilla,
      ccyPair: Enum_Axe_Ccypair_Enum.Eurusd,
      buySell: Enum_Axe_Buysell_Enum.Sell,
      tenor: Enum_Tenor_Enum.T_9M,
      spotDate: DateTime.now().plus({ months: 9, days: 2 }).toISO(),
      expiryDate: DateTime.now().plus({ months: 9 }).toISO(),
      deliveryDate: DateTime.now().plus({ months: 9 }).toISO(),
      premiumDate: DateTime.now().plus({ months: 9 }).toISO(),
      cut: 'NY1000',
      strike: 1.47005,
      callPut: Enum_Axe_Callput_Enum.Put,
      notional: 75000000,
      notionalCurrency: Enum_Currency_Notional_Enum.Eur,
      premium: 2282500,
      premiumCurrency: Enum_Currency_Premium_Enum.Eur,
      baseCurrDepo: 0.0492261771,
      depoCcy1: 0.0492261771,
      depoCcy1DayCount: Enum_Axe_Depoccy1daycount_Enum.Act_360,
      depoCcy2: 0.0667,
      depoCcy2DayCount: Enum_Axe_Depoccy2daycount_Enum.Act_360,
      spot: 1.37005,
      swaps: 0.20468,
      forward: 1.17473,
      volatility: 8,
      hedgeType: Enum_Axe_Hedgetype_Enum.Forward,
      minimumNotionalAmount: 18750000,
      delta: 56000000,
      gamma: 21000000,
      vega: 295000,
      pricingVolatility: 8.5,
      pricingVolatilityT2: 8.8,
      pricingVolatilityT3: 9,
      premiumType: Enum_Axe_Premiumtype_Enum.Forward,
      orderIndex: 3
    }
  ]
}

export const eurGBPQuickAxeLegDataLeg: AxeFormLegData = {
  product: Enum_Axe_Product_Enum.Vanilla,
  ccyPair: Enum_Axe_Ccypair_Enum.Eurgbp,
  buySell: Enum_Axe_Buysell_Enum.Buy,
  tenor: Enum_Tenor_Enum.T_3M,
  spotDate: DateTime.now().toISO(),
  expiryDate: DateTime.now().plus({ months: 3 }).toISO(),
  deliveryDate: DateTime.now().plus({ months: 3, days: 2 }).toISO(),
  premiumDate: DateTime.now().plus({ months: 3 }).toISO(),
  cut: 'NY1000',
  strike: 0.85,
  callPut: Enum_Axe_Callput_Enum.Call,
  notional: 100000000,
  notionalCurrency: Enum_Currency_Notional_Enum.Eur,
  premium: 1782500,
  premiumCurrency: Enum_Currency_Premium_Enum.Eur,
  baseCurrDepo: 0.0392261771,
  depoCcy1: 0.0392261771,
  depoCcy1DayCount: Enum_Axe_Depoccy1daycount_Enum.Act_360,
  depoCcy2: 0.0567,
  depoCcy2DayCount: Enum_Axe_Depoccy2daycount_Enum.Act_360,
  spot: 0.85503,
  swaps: 0.002741,
  forward: 0.858031,
  volatility: 7.5,
  hedgeType: Enum_Axe_Hedgetype_Enum.Forward,
  minimumNotionalAmount: 25000000,
  delta: 52000000,
  gamma: 10000000,
  vega: 195000,
  pricingVolatility: 7.5,
  pricingVolatilityT2: 7.8,
  pricingVolatilityT3: 8,
  premiumType: Enum_Axe_Premiumtype_Enum.Forward,
  orderIndex: 1
}

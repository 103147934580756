import React from 'react'
import Input, {
  VanillaFormNames,
  FieldArrayName,
  InputVariant,
  NumericInputProps
} from '../input/input'
import {
  Row,
  RowInputValue,
  RowState,
  tableClassNames,
  gridCols
} from '../table/table'
import { FormikErrors, useField, useFormikContext } from 'formik'
import classNames from 'classnames'
import { Axe_Authors, Axe_Authors_Aggregate, Axes } from '../../gql'
import { useInputStateContext } from '../../context/inputStateContext/inputStateContext'
import { toFormValues } from '../../types/toFormValues'
import { ComboBoxProps } from '../comboBox/comboBox'
import ListBox, { ListBoxProps } from '../listBox/listBox'
import CallPutListBox from '../listBox/callPutListBox/callPutListBox'
import CutComboBox from '../comboBox/cutComboBox/cutComboBox'
import { RFQDialogFormValues } from '../forms/rfq/rfqAxeFormWrapper/rfqAxeFormWrapper'
import get from 'lodash/get'
import generateModifiedRowInputProps from './utils/generateModifiedRowInputProps/generateModifiedRowInputProps'
import SummaryCell from './components/summaryCell/summaryCell'
import ComboBox from '../comboBox/comboBox'
import {
  AxeFormData,
  AxeFormLegData
} from '../dialog/updateAxeDialog/updateAxeDialog'
import AnimatedFieldStateIcon, {
  FieldStateIconVariant
} from '../fieldStateIcon/fieldStateIcon'
import FixComboBox from '../comboBox/fixComboBox/fixComboBox'

export type RowType = Row<VanillaFormNames | keyof RFQDialogFormValues>

interface FieldTypeProps extends Omit<RowType, 'rowDescriptor'> {
  className?: string
  overflowRowValues?: boolean
}

export const tableValueIsString = (
  value: RowType['rowInputProps']
): value is string => typeof value === 'string'

export const tableValueIsComboBox = (
  rowInputProps: RowType['rowInputProps']
): rowInputProps is ComboBoxProps =>
  !tableValueIsString(rowInputProps) && rowInputProps.type === 'comboBox'

const tableValueIsCutComboBox = (
  rowInputProps: RowType['rowInputProps']
): rowInputProps is ComboBoxProps =>
  !tableValueIsString(rowInputProps) && rowInputProps.name.endsWith('cut')

const tableValueIsFixComboBox = (
  rowInputProps: RowType['rowInputProps']
): rowInputProps is ComboBoxProps =>
  !tableValueIsString(rowInputProps) && rowInputProps.name.endsWith('fix')

const tableValueIsListBox = (
  rowInputProps: RowType['rowInputProps']
): rowInputProps is ListBoxProps =>
  !tableValueIsString(rowInputProps) && rowInputProps.type === 'listBox'

const tableValueIsCallPutListBox = (
  rowInputProps: RowType['rowInputProps']
): rowInputProps is ListBoxProps =>
  !tableValueIsString(rowInputProps) && rowInputProps.name.endsWith('callPut')

export interface RowFormInput extends Omit<FieldTypeProps, 'rowInputProps'> {
  rowInputProps:
    | RowInputValue<VanillaFormNames | keyof RFQDialogFormValues>
    | ComboBoxProps
    | ListBoxProps
    | NumericInputProps
}

type FormValues = toFormValues<Axes>

const RowFormInput: React.FC<RowFormInput> = ({
  rowInputProps,
  rowDisabled,
  rowRefresh,
  className
}) => {
  const { state } = useInputStateContext()
  const { setFieldValue } = useFormikContext<FormValues>()
  const [localValue, setLocalValue] =
    React.useState<(typeof rowInputProps)['value']>()
  const [fieldProps] = useField(rowInputProps.name as string)
  const { isDisabled, overTyped, partyBasedValue } =
    state[rowInputProps.name as string] || {}

  if (tableValueIsCutComboBox(rowInputProps)) {
    return <CutComboBox disabled={rowDisabled} {...rowInputProps} />
  }

  if (tableValueIsFixComboBox(rowInputProps)) {
    return <FixComboBox disabled={rowDisabled} {...rowInputProps} />
  }

  if (tableValueIsComboBox(rowInputProps)) {
    return (
      <ComboBox
        disabled={rowDisabled || isDisabled}
        {...rowInputProps}
        name={rowInputProps.name as string}
        variant={InputVariant.CELL}
        overrideValue={partyBasedValue as string}
      />
    )
  }

  if (tableValueIsCallPutListBox(rowInputProps)) {
    return <CallPutListBox disabled={rowDisabled} {...rowInputProps} />
  }

  if (tableValueIsListBox(rowInputProps)) {
    return <ListBox disabled={rowDisabled} {...rowInputProps} />
  }

  return (
    <Input
      className={className}
      {...rowInputProps}
      disabled={rowDisabled || isDisabled}
      refreshable={rowRefresh}
      value={overTyped ? localValue : fieldProps.value}
      onChange={(e: React.SyntheticEvent, floatValue?: number) => {
        const key = rowInputProps.name
        setLocalValue(floatValue)
        setFieldValue(key, floatValue || (e.target as HTMLInputElement).value)
      }}
    />
  )
}

export const FieldType: React.FC<FieldTypeProps> = ({
  rowInputProps,
  rowRefresh,
  rowDisabled,
  className,
  overflowRowValues
}) => {
  if (tableValueIsString(rowInputProps)) {
    return (
      <span
        className={classNames(
          {
            flex: !overflowRowValues
          },
          'items-center text-textLightGray w-full border-baseGray dark:border-lightBlue focus:border-indigo-500 bg-transparent text-[12px]',
          tableClassNames.cellHeight,
          tableClassNames.cellPadLeft
        )}
      >
        {rowInputProps}
      </span>
    )
  } else {
    return (
      <RowFormInput
        rowInputProps={rowInputProps}
        rowDisabled={rowDisabled}
        rowRefresh={rowRefresh}
        className={className}
      />
    )
  }
}

interface DtProps
  extends Pick<TableRow, 'rowDisabled' | 'rowState' | 'isUnderHeader'> {
  tableInfo: {
    rowIndex: number
    totalRows: number
  }
}

interface DdProps
  extends Pick<TableRow, 'rowDisabled' | 'rowState' | 'isUnderHeader'> {
  error?:
    | string
    | false
    | string[]
    | FormikErrors<Axe_Authors>[]
    | FormikErrors<Axe_Authors_Aggregate>
  tableInfo: {
    rowIndex: number
    totalRows: number
    columnIndex?: number
    totalColumns?: number
  }
  warning?: string | boolean
  updatedValue?: boolean
  overflowRowValues?: boolean
}

const DT = ({
  children,
  rowDisabled,
  rowState,
  tableInfo: { rowIndex, totalRows }
}: React.PropsWithChildren<DtProps>) => {
  const isFirstRow = rowIndex === 0
  const isLastRow = rowIndex + 1 === totalRows

  return (
    <dt
      className={classNames(
        tableClassNames.headingCell,
        tableClassNames.cellHeight,
        {
          'rounded-bl-md': isLastRow,
          'rounded-tl-md': isFirstRow
        },
        {
          [tableClassNames.cellState.default]:
            !rowDisabled && rowState === RowState.DEFAULT,
          [tableClassNames.cellState.disabled]:
            rowDisabled && rowState === RowState.DEFAULT
        }
      )}
    >
      {children}
    </dt>
  )
}

const DD = ({
  children,
  rowDisabled,
  error,
  warning,
  isUnderHeader,
  tableInfo: { rowIndex, totalRows, columnIndex = 0, totalColumns },
  updatedValue,
  overflowRowValues
}: React.PropsWithChildren<DdProps>) => {
  const isFirstRow = rowIndex === 0
  const isLastColumn = columnIndex + 1 === totalColumns
  const isLastRow = rowIndex + 1 === totalRows

  // Ensure 'borderRadiiClassNames' are applied to both the parent DD and its child to enable border radius on error
  const borderRadiiClassNames = classNames({
    'rounded-br-md': isLastRow && isLastColumn,
    'rounded-tr-md': isFirstRow && isLastColumn && !isUnderHeader,
    'overflow-x-auto whitespace-nowrap': overflowRowValues
  })

  return (
    <dd
      className={classNames(borderRadiiClassNames, {
        [tableClassNames.summaryCell]: rowDisabled && !updatedValue,
        'group is-updated': updatedValue,
        [tableClassNames.cellState.warning]: updatedValue
      })}
    >
      <div
        className={classNames('h-full relative', borderRadiiClassNames, {
          'shadow-[0_0_0px_1px] shadow-processYellow': warning,
          'shadow-[0_0_0px_1px] shadow-errorRed': error
        })}
      >
        {children}
      </div>
    </dd>
  )
}

type RowWrapperProps = Pick<
  TableRow,
  | 'index'
  | 'rowState'
  | 'showSummaryOnly'
  | 'totalColumns'
  | 'collapseRowDescriptors'
>

const RowWrapper = ({
  children,
  index,
  showSummaryOnly,
  totalColumns = 1,
  collapseRowDescriptors
}: React.PropsWithChildren<RowWrapperProps>) => {
  return (
    <div
      key={index}
      className={
        collapseRowDescriptors
          ? tableClassNames.rows(gridCols.row.length)
          : tableClassNames.rows(showSummaryOnly ? 1 : totalColumns)
      }
    >
      {children}
    </div>
  )
}

export interface TableRow
  extends Row<VanillaFormNames | keyof RFQDialogFormValues> {
  array: Array<Row<VanillaFormNames | keyof RFQDialogFormValues>>
  index: number
  readonly?: boolean
  totalColumns: number
  fieldArrayName?: FieldArrayName
  isUnderHeader?: boolean
  showSummaryOnly?: boolean
  collapseRowDescriptors?: boolean
  overflowRowValues?: boolean
}

export default function TableRow({
  rowState,
  rowHidden,
  rowDisabled,
  rowDescriptor,
  rowRefresh,
  rowInputProps,
  index,
  array,
  readonly,
  totalColumns = 1,
  fieldArrayName,
  isUnderHeader,
  showSummaryOnly = false,
  collapseRowDescriptors,
  overflowRowValues
}: TableRow) {
  const { state } = useInputStateContext()
  const { errors, touched } = useFormikContext<AxeFormData>()

  const tableValueIsFormInput = !tableValueIsString(rowInputProps)

  return (
    <RowWrapper
      index={index}
      totalColumns={totalColumns}
      showSummaryOnly={showSummaryOnly}
      collapseRowDescriptors={collapseRowDescriptors}
    >
      <DT
        rowState={rowState}
        rowDisabled={rowDisabled}
        isUnderHeader={isUnderHeader}
        tableInfo={{
          rowIndex: index,
          totalRows: array.length
        }}
      >
        <div className={tableClassNames.stringCell}>{rowDescriptor}</div>
      </DT>
      {/* Summary column */}
      {totalColumns > 1 && (
        <DD
          rowDisabled
          isUnderHeader={isUnderHeader}
          tableInfo={{
            rowIndex: index,
            totalRows: array.length,
            columnIndex: 0,
            totalColumns: showSummaryOnly ? 1 : totalColumns
          }}
        >
          {tableValueIsFormInput && (
            <SummaryCell name={rowInputProps.name as keyof AxeFormLegData} />
          )}
        </DD>
      )}
      {!showSummaryOnly &&
        Array.from({ length: totalColumns }, (_, columnIndex) => {
          const modifiedRowInputProps = generateModifiedRowInputProps({
            columnIndex,
            fieldArrayName,
            rowInputProps
          })

          const inputState =
            (typeof modifiedRowInputProps !== 'string' &&
              state[modifiedRowInputProps.name]) ||
            {}

          const error =
            !rowHidden &&
            !rowDisabled &&
            typeof modifiedRowInputProps !== 'string' &&
            get(touched, modifiedRowInputProps.name) &&
            get(errors, modifiedRowInputProps.name)

          return (
            <DD
              key={columnIndex}
              rowDisabled={readonly || rowDisabled}
              error={error}
              warning={inputState.warningMessage}
              isUnderHeader={isUnderHeader}
              overflowRowValues={overflowRowValues}
              tableInfo={{
                rowIndex: index,
                totalRows: array.length,
                columnIndex,
                totalColumns
              }}
              updatedValue={inputState.updatedValue}
            >
              <FieldType
                rowInputProps={
                  modifiedRowInputProps as RowType['rowInputProps']
                }
                overflowRowValues={overflowRowValues}
                rowDisabled={readonly || rowDisabled}
                rowRefresh={rowRefresh}
                className={classNames({
                  'dark:text-lightBlue text-royalBlue font-semibold':
                    rowRefresh &&
                    (inputState || {}).overTyped &&
                    (inputState || {}).containsFetchedValue
                })}
              />

              <AnimatedFieldStateIcon
                message={error ? error : inputState.warningMessage}
                variant={
                  error
                    ? FieldStateIconVariant.ERROR
                    : FieldStateIconVariant.WARNING
                }
              />
            </DD>
          )
        })}
    </RowWrapper>
  )
}

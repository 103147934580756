/**
 * @generated SignedSource<<aaa6851aff50953250507b9d0d8b29aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type axe_authors_constraint = "axe_authors_pkey" | "%future added value";
export type axe_authors_select_column = "axe_id" | "createdAt" | "id" | "organizationId" | "subOrganizationId" | "updatedAt" | "user_id" | "%future added value";
export type axe_authors_update_column = "axe_id" | "createdAt" | "id" | "organizationId" | "subOrganizationId" | "updatedAt" | "user_id" | "%future added value";
export type axe_legs_constraint = "legs_pkey" | "unique_order_per_axe" | "%future added value";
export type axe_legs_select_column = "axeId" | "baseCurrDepo" | "buySell" | "callPut" | "ccyPair" | "createdAt" | "cut" | "deliveryDate" | "delta" | "depoCcy1" | "depoCcy1DayCount" | "depoCcy2" | "depoCcy2DayCount" | "expiryDate" | "fix" | "forward" | "forwardPoints" | "gamma" | "hedgeType" | "id" | "minimumNotionalAmount" | "notional" | "notionalCurrency" | "orderIndex" | "premium" | "premiumCurrency" | "premiumDate" | "premiumType" | "pricingCurrDepo" | "pricingVolatility" | "product" | "spot" | "spotDate" | "strike" | "swaps" | "tenor" | "updatedAt" | "vega" | "volatility" | "%future added value";
export type axe_legs_select_column_axe_legs_aggregate_bool_exp_avg_arguments_columns = "baseCurrDepo" | "depoCcy1" | "depoCcy2" | "forward" | "forwardPoints" | "gamma" | "pricingCurrDepo" | "pricingVolatility" | "spot" | "strike" | "swaps" | "vega" | "volatility" | "%future added value";
export type axe_legs_select_column_axe_legs_aggregate_bool_exp_corr_arguments_columns = "baseCurrDepo" | "depoCcy1" | "depoCcy2" | "forward" | "forwardPoints" | "gamma" | "pricingCurrDepo" | "pricingVolatility" | "spot" | "strike" | "swaps" | "vega" | "volatility" | "%future added value";
export type axe_legs_select_column_axe_legs_aggregate_bool_exp_covar_samp_arguments_columns = "baseCurrDepo" | "depoCcy1" | "depoCcy2" | "forward" | "forwardPoints" | "gamma" | "pricingCurrDepo" | "pricingVolatility" | "spot" | "strike" | "swaps" | "vega" | "volatility" | "%future added value";
export type axe_legs_select_column_axe_legs_aggregate_bool_exp_max_arguments_columns = "baseCurrDepo" | "depoCcy1" | "depoCcy2" | "forward" | "forwardPoints" | "gamma" | "pricingCurrDepo" | "pricingVolatility" | "spot" | "strike" | "swaps" | "vega" | "volatility" | "%future added value";
export type axe_legs_select_column_axe_legs_aggregate_bool_exp_min_arguments_columns = "baseCurrDepo" | "depoCcy1" | "depoCcy2" | "forward" | "forwardPoints" | "gamma" | "pricingCurrDepo" | "pricingVolatility" | "spot" | "strike" | "swaps" | "vega" | "volatility" | "%future added value";
export type axe_legs_select_column_axe_legs_aggregate_bool_exp_stddev_samp_arguments_columns = "baseCurrDepo" | "depoCcy1" | "depoCcy2" | "forward" | "forwardPoints" | "gamma" | "pricingCurrDepo" | "pricingVolatility" | "spot" | "strike" | "swaps" | "vega" | "volatility" | "%future added value";
export type axe_legs_select_column_axe_legs_aggregate_bool_exp_sum_arguments_columns = "baseCurrDepo" | "depoCcy1" | "depoCcy2" | "forward" | "forwardPoints" | "gamma" | "pricingCurrDepo" | "pricingVolatility" | "spot" | "strike" | "swaps" | "vega" | "volatility" | "%future added value";
export type axe_legs_select_column_axe_legs_aggregate_bool_exp_var_samp_arguments_columns = "baseCurrDepo" | "depoCcy1" | "depoCcy2" | "forward" | "forwardPoints" | "gamma" | "pricingCurrDepo" | "pricingVolatility" | "spot" | "strike" | "swaps" | "vega" | "volatility" | "%future added value";
export type axe_legs_update_column = "axeId" | "baseCurrDepo" | "buySell" | "callPut" | "ccyPair" | "createdAt" | "cut" | "deliveryDate" | "delta" | "depoCcy1" | "depoCcy1DayCount" | "depoCcy2" | "depoCcy2DayCount" | "expiryDate" | "fix" | "forward" | "forwardPoints" | "gamma" | "hedgeType" | "id" | "minimumNotionalAmount" | "notional" | "notionalCurrency" | "orderIndex" | "premium" | "premiumCurrency" | "premiumDate" | "premiumType" | "pricingCurrDepo" | "pricingVolatility" | "product" | "spot" | "spotDate" | "strike" | "swaps" | "tenor" | "updatedAt" | "vega" | "volatility" | "%future added value";
export type axe_tier_pricing_constraint = "axe_tier_pricing_pkey" | "%future added value";
export type axe_tier_pricing_select_column = "axeId" | "createdAt" | "id" | "pricingVolatility" | "tier" | "updatedAt" | "%future added value";
export type axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_avg_arguments_columns = "pricingVolatility" | "%future added value";
export type axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_corr_arguments_columns = "pricingVolatility" | "%future added value";
export type axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_covar_samp_arguments_columns = "pricingVolatility" | "%future added value";
export type axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_max_arguments_columns = "pricingVolatility" | "%future added value";
export type axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_min_arguments_columns = "pricingVolatility" | "%future added value";
export type axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_stddev_samp_arguments_columns = "pricingVolatility" | "%future added value";
export type axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_sum_arguments_columns = "pricingVolatility" | "%future added value";
export type axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_var_samp_arguments_columns = "pricingVolatility" | "%future added value";
export type axe_tier_pricing_update_column = "axeId" | "createdAt" | "id" | "pricingVolatility" | "tier" | "updatedAt" | "%future added value";
export type axes_constraint = "axes_pkey" | "%future added value";
export type axes_select_column = "createdAt" | "filled" | "hidden" | "id" | "inRFQ" | "paused" | "state" | "tradedNotional" | "updatedAt" | "%future added value";
export type axes_select_column_axes_aggregate_bool_exp_bool_and_arguments_columns = "hidden" | "inRFQ" | "paused" | "%future added value";
export type axes_select_column_axes_aggregate_bool_exp_bool_or_arguments_columns = "hidden" | "inRFQ" | "paused" | "%future added value";
export type axes_update_column = "createdAt" | "filled" | "hidden" | "id" | "inRFQ" | "paused" | "state" | "tradedNotional" | "updatedAt" | "%future added value";
export type enum_axe_buysell_enum = "BUY" | "SELL" | "%future added value";
export type enum_axe_callput_enum = "CALL" | "PUT" | "%future added value";
export type enum_axe_ccypair_enum = "AUDCAD" | "AUDJPY" | "AUDNZD" | "AUDUSD" | "BRLJPY" | "BRLMXN" | "CADCHF" | "CADJPY" | "CHFJPY" | "CHFNOK" | "CHFSEK" | "CNHJPY" | "EURAUD" | "EURBRL" | "EURCAD" | "EURCHF" | "EURCNH" | "EURCZK" | "EURDKK" | "EURGBP" | "EURHKD" | "EURHUF" | "EURIDR" | "EURINR" | "EURJPY" | "EURKRW" | "EURMXN" | "EURMYR" | "EURNOK" | "EURNZD" | "EURPHP" | "EURPLN" | "EURRUB" | "EURSEK" | "EURSGD" | "EURTRY" | "EURTWD" | "EURUSD" | "EURZAR" | "GBPAUD" | "GBPBRL" | "GBPCAD" | "GBPCHF" | "GBPHKD" | "GBPJPY" | "GBPNOK" | "GBPNZD" | "GBPSEK" | "GBPUSD" | "HKDJPY" | "JPYKRW" | "MXNJPY" | "NOKJPY" | "NOKSEK" | "NZDCAD" | "NZDJPY" | "NZDUSD" | "SEKJPY" | "TRYJPY" | "TRYZAR" | "USDBRL" | "USDCAD" | "USDCHF" | "USDCLP" | "USDCNH" | "USDCOP" | "USDCZK" | "USDHKD" | "USDHUF" | "USDIDR" | "USDILS" | "USDINR" | "USDJPY" | "USDKRW" | "USDMXN" | "USDMYR" | "USDNOK" | "USDPHP" | "USDPLN" | "USDRUB" | "USDSEK" | "USDSGD" | "USDTRY" | "USDTWD" | "USDZAR" | "%future added value";
export type enum_axe_depoccy1daycount_enum = "ACT_360" | "ACT_365" | "%future added value";
export type enum_axe_depoccy2daycount_enum = "ACT_360" | "ACT_365" | "%future added value";
export type enum_axe_hedgetype_enum = "NDF" | "forward" | "spot" | "%future added value";
export type enum_axe_premiumtype_enum = "forward" | "spot" | "%future added value";
export type enum_axe_product_enum = "vanilla" | "%future added value";
export type enum_axe_state_enum = "DELETED" | "EXPIRED" | "LIVE" | "SUSPENDED_BY_ADMIN" | "SUSPENDED_BY_USER" | "TRADED" | "%future added value";
export type enum_color_scheme_enum = "DARK" | "LIGHT" | "%future added value";
export type enum_currency_notional_enum = "AUD" | "BRL" | "CAD" | "CHF" | "CLP" | "CNH" | "COP" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "IDR" | "ILS" | "INR" | "JPY" | "KRW" | "MXN" | "MYR" | "NOK" | "NZD" | "PHP" | "PLN" | "RON" | "RUB" | "SEK" | "SGD" | "TRY" | "TWD" | "USD" | "ZAR" | "%future added value";
export type enum_currency_premium_enum = "AUD" | "BRL" | "CAD" | "CHF" | "CLP" | "CNH" | "COP" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "IDR" | "ILS" | "INR" | "JPY" | "KRW" | "MXN" | "MYR" | "NOK" | "NZD" | "PHP" | "PLN" | "RON" | "RUB" | "SEK" | "SGD" | "TRY" | "TWD" | "USD" | "ZAR" | "%future added value";
export type enum_quote_side_type_enum = "BID" | "EMPTY" | "OFFER" | "%future added value";
export type enum_rfq_option_reason_enum = "EXPIRED" | "%future added value";
export type enum_rfq_status_enum = "Done" | "NewOrder" | "NoQuote" | "OrderReject" | "Quote" | "QuoteCancel" | "QuoteRequest" | "QuoteRequestAck" | "QuoteRequestCancel" | "QuoteRequestReject" | "%future added value";
export type enum_tenor_enum = "O_N" | "t_10M" | "t_10Y" | "t_11M" | "t_1M" | "t_1W" | "t_1Y" | "t_2D" | "t_2M" | "t_2W" | "t_2Y" | "t_3D" | "t_3M" | "t_3W" | "t_3Y" | "t_4D" | "t_4M" | "t_4Y" | "t_5D" | "t_5M" | "t_5Y" | "t_6D" | "t_6M" | "t_6Y" | "t_7M" | "t_7Y" | "t_8M" | "t_8Y" | "t_9M" | "t_9Y" | "%future added value";
export type enum_tiers_enum = "t_1" | "t_2" | "t_3" | "%future added value";
export type enum_users_axe_suspended_state_enum = "SUSPENDED_BY_ADMIN" | "SUSPENDED_BY_USER" | "SUSPENDED_BY_USER_AND_ADMIN" | "%future added value";
export type funds_constraint = "funds_pkey" | "%future added value";
export type funds_update_column = "id" | "lei" | "name" | "organisationId" | "%future added value";
export type give_ups_constraint = "give_ups_email_key" | "give_ups_pkey" | "%future added value";
export type give_ups_organizations_constraint = "give_ups_organizations_pkey" | "%future added value";
export type give_ups_organizations_select_column = "giveupId" | "organizationId" | "%future added value";
export type give_ups_organizations_update_column = "giveupId" | "organizationId" | "%future added value";
export type give_ups_update_column = "email" | "id" | "lei" | "name" | "%future added value";
export type organizations_constraint = "organizations_name_key" | "organizations_pkey" | "%future added value";
export type organizations_select_column = "createdAt" | "displayName" | "fixConnectionString" | "fixConnectionUp" | "fixEnabled" | "id" | "lei" | "name" | "updatedAt" | "%future added value";
export type organizations_select_column_organizations_aggregate_bool_exp_bool_and_arguments_columns = "fixConnectionUp" | "fixEnabled" | "%future added value";
export type organizations_select_column_organizations_aggregate_bool_exp_bool_or_arguments_columns = "fixConnectionUp" | "fixEnabled" | "%future added value";
export type organizations_update_column = "createdAt" | "displayName" | "fixConnectionString" | "fixConnectionUp" | "fixEnabled" | "id" | "lei" | "name" | "updatedAt" | "%future added value";
export type push_subscriptions_constraint = "push_subscriptions_pkey" | "%future added value";
export type push_subscriptions_select_column = "createdAt" | "id" | "subscription" | "updatedAt" | "userId" | "%future added value";
export type push_subscriptions_update_column = "createdAt" | "id" | "subscription" | "updatedAt" | "userId" | "%future added value";
export type rfq_constraint = "rfq_pkey" | "%future added value";
export type rfq_legs_constraint = "rfq_legs_pkey" | "unique_order_per_rfq" | "%future added value";
export type rfq_legs_select_column = "buySell" | "callPut" | "ccyPair" | "createdAt" | "cut" | "deliveryDate" | "expiryDate" | "fix" | "forward" | "hedgeType" | "id" | "minimumNotionalAmount" | "notional" | "notionalCurrency" | "orderIndex" | "premium" | "premiumCurrency" | "premiumDate" | "premiumType" | "product" | "rfqId" | "spot" | "strike" | "swaps" | "updatedAt" | "%future added value";
export type rfq_legs_select_column_rfq_legs_aggregate_bool_exp_avg_arguments_columns = "forward" | "spot" | "strike" | "swaps" | "%future added value";
export type rfq_legs_select_column_rfq_legs_aggregate_bool_exp_corr_arguments_columns = "forward" | "spot" | "strike" | "swaps" | "%future added value";
export type rfq_legs_select_column_rfq_legs_aggregate_bool_exp_covar_samp_arguments_columns = "forward" | "spot" | "strike" | "swaps" | "%future added value";
export type rfq_legs_select_column_rfq_legs_aggregate_bool_exp_max_arguments_columns = "forward" | "spot" | "strike" | "swaps" | "%future added value";
export type rfq_legs_select_column_rfq_legs_aggregate_bool_exp_min_arguments_columns = "forward" | "spot" | "strike" | "swaps" | "%future added value";
export type rfq_legs_select_column_rfq_legs_aggregate_bool_exp_stddev_samp_arguments_columns = "forward" | "spot" | "strike" | "swaps" | "%future added value";
export type rfq_legs_select_column_rfq_legs_aggregate_bool_exp_sum_arguments_columns = "forward" | "spot" | "strike" | "swaps" | "%future added value";
export type rfq_legs_select_column_rfq_legs_aggregate_bool_exp_var_samp_arguments_columns = "forward" | "spot" | "strike" | "swaps" | "%future added value";
export type rfq_legs_update_column = "buySell" | "callPut" | "ccyPair" | "createdAt" | "cut" | "deliveryDate" | "expiryDate" | "fix" | "forward" | "hedgeType" | "id" | "minimumNotionalAmount" | "notional" | "notionalCurrency" | "orderIndex" | "premium" | "premiumCurrency" | "premiumDate" | "premiumType" | "product" | "rfqId" | "spot" | "strike" | "swaps" | "updatedAt" | "%future added value";
export type rfq_options_constraint = "rfq_options_pkey" | "%future added value";
export type rfq_options_legs_constraint = "rfq_options_legs_pkey" | "%future added value";
export type rfq_options_legs_select_column = "bsDelta" | "createdAt" | "delta" | "id" | "premium" | "pricingVolatility" | "rfqLegId" | "rfqOptionId" | "updatedAt" | "%future added value";
export type rfq_options_legs_select_column_rfq_options_legs_aggregate_bool_exp_avg_arguments_columns = "pricingVolatility" | "%future added value";
export type rfq_options_legs_select_column_rfq_options_legs_aggregate_bool_exp_corr_arguments_columns = "pricingVolatility" | "%future added value";
export type rfq_options_legs_select_column_rfq_options_legs_aggregate_bool_exp_covar_samp_arguments_columns = "pricingVolatility" | "%future added value";
export type rfq_options_legs_select_column_rfq_options_legs_aggregate_bool_exp_max_arguments_columns = "pricingVolatility" | "%future added value";
export type rfq_options_legs_select_column_rfq_options_legs_aggregate_bool_exp_min_arguments_columns = "pricingVolatility" | "%future added value";
export type rfq_options_legs_select_column_rfq_options_legs_aggregate_bool_exp_stddev_samp_arguments_columns = "pricingVolatility" | "%future added value";
export type rfq_options_legs_select_column_rfq_options_legs_aggregate_bool_exp_sum_arguments_columns = "pricingVolatility" | "%future added value";
export type rfq_options_legs_select_column_rfq_options_legs_aggregate_bool_exp_var_samp_arguments_columns = "pricingVolatility" | "%future added value";
export type rfq_options_legs_update_column = "bsDelta" | "createdAt" | "delta" | "id" | "premium" | "pricingVolatility" | "rfqLegId" | "rfqOptionId" | "updatedAt" | "%future added value";
export type rfq_options_select_column = "createdAt" | "fixQuoteId" | "id" | "reason" | "rfqId" | "side" | "status" | "updatedAt" | "userId" | "%future added value";
export type rfq_options_update_column = "createdAt" | "fixQuoteId" | "id" | "reason" | "rfqId" | "side" | "status" | "updatedAt" | "userId" | "%future added value";
export type rfq_update_column = "axeCustomerUserId" | "axeId" | "axeOwnerUserId" | "channel" | "createdAt" | "customerOrganizationId" | "customerSubOrganizationId" | "deltaHedge" | "expireAt" | "fundId" | "giveupId" | "id" | "notional" | "ownerOrganizationId" | "ownerSubOrganizationId" | "premiumType" | "side" | "status" | "updatedAt" | "%future added value";
export type sub_organizations_constraint = "sub_organizations_name_key" | "sub_organizations_pkey" | "%future added value";
export type sub_organizations_select_column = "createdAt" | "displayName" | "id" | "name" | "organizationId" | "updatedAt" | "%future added value";
export type sub_organizations_update_column = "createdAt" | "displayName" | "id" | "name" | "organizationId" | "updatedAt" | "%future added value";
export type traded_hedges_select_column = "ccy1" | "ccy2" | "createdAt" | "deliveryDate" | "id" | "isin" | "notionalAmountInEUR" | "notionalCcy1" | "notionalCcy2" | "rate" | "tradeId" | "updatedAt" | "%future added value";
export type traded_hedges_select_column_traded_hedges_aggregate_bool_exp_avg_arguments_columns = "rate" | "%future added value";
export type traded_hedges_select_column_traded_hedges_aggregate_bool_exp_corr_arguments_columns = "rate" | "%future added value";
export type traded_hedges_select_column_traded_hedges_aggregate_bool_exp_covar_samp_arguments_columns = "rate" | "%future added value";
export type traded_hedges_select_column_traded_hedges_aggregate_bool_exp_max_arguments_columns = "rate" | "%future added value";
export type traded_hedges_select_column_traded_hedges_aggregate_bool_exp_min_arguments_columns = "rate" | "%future added value";
export type traded_hedges_select_column_traded_hedges_aggregate_bool_exp_stddev_samp_arguments_columns = "rate" | "%future added value";
export type traded_hedges_select_column_traded_hedges_aggregate_bool_exp_sum_arguments_columns = "rate" | "%future added value";
export type traded_hedges_select_column_traded_hedges_aggregate_bool_exp_var_samp_arguments_columns = "rate" | "%future added value";
export type trades_constraint = "trades_pkey" | "%future added value";
export type trades_leg_constraint = "trades_leg_pkey" | "%future added value";
export type trades_leg_select_column = "buySell" | "callPut" | "ccyPair" | "createdAt" | "cut" | "deliveryDate" | "delta" | "expiryDate" | "forward" | "hedgeType" | "id" | "notional" | "notionalCurrency" | "orderIndex" | "premium" | "premiumCurrency" | "premiumDate" | "pricingVolatility" | "product" | "spot" | "spotDate" | "strike" | "tradeId" | "updatedAt" | "%future added value";
export type trades_leg_select_column_trades_leg_aggregate_bool_exp_avg_arguments_columns = "forward" | "pricingVolatility" | "spot" | "strike" | "%future added value";
export type trades_leg_select_column_trades_leg_aggregate_bool_exp_corr_arguments_columns = "forward" | "pricingVolatility" | "spot" | "strike" | "%future added value";
export type trades_leg_select_column_trades_leg_aggregate_bool_exp_covar_samp_arguments_columns = "forward" | "pricingVolatility" | "spot" | "strike" | "%future added value";
export type trades_leg_select_column_trades_leg_aggregate_bool_exp_max_arguments_columns = "forward" | "pricingVolatility" | "spot" | "strike" | "%future added value";
export type trades_leg_select_column_trades_leg_aggregate_bool_exp_min_arguments_columns = "forward" | "pricingVolatility" | "spot" | "strike" | "%future added value";
export type trades_leg_select_column_trades_leg_aggregate_bool_exp_stddev_samp_arguments_columns = "forward" | "pricingVolatility" | "spot" | "strike" | "%future added value";
export type trades_leg_select_column_trades_leg_aggregate_bool_exp_sum_arguments_columns = "forward" | "pricingVolatility" | "spot" | "strike" | "%future added value";
export type trades_leg_select_column_trades_leg_aggregate_bool_exp_var_samp_arguments_columns = "forward" | "pricingVolatility" | "spot" | "strike" | "%future added value";
export type trades_leg_update_column = "buySell" | "callPut" | "ccyPair" | "createdAt" | "cut" | "deliveryDate" | "delta" | "expiryDate" | "forward" | "hedgeType" | "id" | "notional" | "notionalCurrency" | "orderIndex" | "premium" | "premiumCurrency" | "premiumDate" | "pricingVolatility" | "product" | "spot" | "spotDate" | "strike" | "tradeId" | "updatedAt" | "%future added value";
export type trades_update_column = "axeCustomerUserId" | "axeId" | "axeOwnerUserId" | "channel" | "createdAt" | "customerSubOrganizationId" | "deltaHedge" | "fixQuoteId" | "fundId" | "giveupId" | "id" | "notional" | "notionalAmountInEUR" | "notionalAmountInUSD" | "ownerOrganizationId" | "ownerSubOrganizationId" | "premiumType" | "rfqId" | "side" | "updatedAt" | "%future added value";
export type user_main_tiering_constraint = "user_main_tiering_pkey" | "%future added value";
export type user_main_tiering_select_column = "createdAt" | "id" | "isRemoved" | "organizationId" | "ownerId" | "subOrganizationId" | "tier" | "updatedAt" | "%future added value";
export type user_main_tiering_select_column_user_main_tiering_aggregate_bool_exp_bool_and_arguments_columns = "isRemoved" | "%future added value";
export type user_main_tiering_select_column_user_main_tiering_aggregate_bool_exp_bool_or_arguments_columns = "isRemoved" | "%future added value";
export type user_main_tiering_update_column = "createdAt" | "id" | "isRemoved" | "organizationId" | "ownerId" | "subOrganizationId" | "tier" | "updatedAt" | "%future added value";
export type user_tiers_constraint = "user_tiers_pkey" | "%future added value";
export type user_tiers_update_column = "axeId" | "createdAt" | "id" | "isRemoved" | "organizationId" | "subOrganizationId" | "tierId" | "updatedAt" | "userId" | "%future added value";
export type users_constraint = "users_pkey" | "%future added value";
export type users_select_column = "colorSchemePreference" | "email" | "fullName" | "id" | "last_seen" | "organizationId" | "role" | "subOrganizationId" | "suspended_state" | "%future added value";
export type users_update_column = "colorSchemePreference" | "email" | "fullName" | "id" | "last_seen" | "organizationId" | "role" | "subOrganizationId" | "suspended_state" | "%future added value";
export type rfq_options_insert_input = {
  createdAt?: any | null | undefined;
  fixQuoteId?: string | null | undefined;
  id?: any | null | undefined;
  reason?: enum_rfq_option_reason_enum | null | undefined;
  rfq?: rfq_obj_rel_insert_input | null | undefined;
  rfqId?: any | null | undefined;
  rfq_options_legs?: rfq_options_legs_arr_rel_insert_input | null | undefined;
  side?: enum_quote_side_type_enum | null | undefined;
  status?: enum_rfq_status_enum | null | undefined;
  updatedAt?: any | null | undefined;
  userId?: string | null | undefined;
};
export type rfq_obj_rel_insert_input = {
  data: rfq_insert_input;
  on_conflict?: rfq_on_conflict | null | undefined;
};
export type rfq_insert_input = {
  axe?: axes_arr_rel_insert_input | null | undefined;
  axeCustomer?: users_arr_rel_insert_input | null | undefined;
  axeCustomerUserId?: string | null | undefined;
  axeId?: any | null | undefined;
  axeOwner?: users_arr_rel_insert_input | null | undefined;
  axeOwnerUserId?: string | null | undefined;
  channel?: string | null | undefined;
  createdAt?: any | null | undefined;
  customerOrganization?: organizations_arr_rel_insert_input | null | undefined;
  customerOrganizationId?: string | null | undefined;
  customerSubOrganizationId?: any | null | undefined;
  deltaHedge?: any | null | undefined;
  expireAt?: any | null | undefined;
  fund?: funds_obj_rel_insert_input | null | undefined;
  fundId?: any | null | undefined;
  give_up?: give_ups_obj_rel_insert_input | null | undefined;
  giveupId?: any | null | undefined;
  id?: any | null | undefined;
  notional?: any | null | undefined;
  ownerOrganization?: organizations_arr_rel_insert_input | null | undefined;
  ownerOrganizationId?: string | null | undefined;
  ownerSubOrganization?: sub_organizations_arr_rel_insert_input | null | undefined;
  ownerSubOrganizationId?: any | null | undefined;
  premiumType?: enum_axe_premiumtype_enum | null | undefined;
  rfq_axe_tiers?: user_tiers_obj_rel_insert_input | null | undefined;
  rfq_legs?: rfq_legs_arr_rel_insert_input | null | undefined;
  rfq_options?: rfq_options_arr_rel_insert_input | null | undefined;
  side?: enum_quote_side_type_enum | null | undefined;
  status?: enum_rfq_status_enum | null | undefined;
  trades?: trades_obj_rel_insert_input | null | undefined;
  updatedAt?: any | null | undefined;
};
export type axes_arr_rel_insert_input = {
  data: ReadonlyArray<axes_insert_input>;
  on_conflict?: axes_on_conflict | null | undefined;
};
export type axes_insert_input = {
  axe_authors?: axe_authors_arr_rel_insert_input | null | undefined;
  axe_legs?: axe_legs_arr_rel_insert_input | null | undefined;
  axes_axe_tier_pricings?: axe_tier_pricing_arr_rel_insert_input | null | undefined;
  createdAt?: any | null | undefined;
  filled?: number | null | undefined;
  hidden?: boolean | null | undefined;
  id?: any | null | undefined;
  inRFQ?: boolean | null | undefined;
  paused?: boolean | null | undefined;
  state?: enum_axe_state_enum | null | undefined;
  tradedNotional?: any | null | undefined;
  updatedAt?: any | null | undefined;
};
export type axe_authors_arr_rel_insert_input = {
  data: ReadonlyArray<axe_authors_insert_input>;
  on_conflict?: axe_authors_on_conflict | null | undefined;
};
export type axe_authors_insert_input = {
  axe?: axes_arr_rel_insert_input | null | undefined;
  axe_id?: any | null | undefined;
  createdAt?: any | null | undefined;
  id?: any | null | undefined;
  organizationId?: string | null | undefined;
  subOrganization?: sub_organizations_arr_rel_insert_input | null | undefined;
  subOrganizationId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  user?: users_arr_rel_insert_input | null | undefined;
  user_id?: string | null | undefined;
};
export type sub_organizations_arr_rel_insert_input = {
  data: ReadonlyArray<sub_organizations_insert_input>;
  on_conflict?: sub_organizations_on_conflict | null | undefined;
};
export type sub_organizations_insert_input = {
  createdAt?: any | null | undefined;
  displayName?: string | null | undefined;
  id?: any | null | undefined;
  name?: string | null | undefined;
  organizationId?: string | null | undefined;
  parent_organization?: organizations_obj_rel_insert_input | null | undefined;
  updatedAt?: any | null | undefined;
};
export type organizations_obj_rel_insert_input = {
  data: organizations_insert_input;
  on_conflict?: organizations_on_conflict | null | undefined;
};
export type organizations_insert_input = {
  createdAt?: any | null | undefined;
  displayName?: string | null | undefined;
  fixConnectionString?: string | null | undefined;
  fixConnectionUp?: boolean | null | undefined;
  fixEnabled?: boolean | null | undefined;
  giveups?: give_ups_organizations_arr_rel_insert_input | null | undefined;
  id?: string | null | undefined;
  lei?: string | null | undefined;
  name?: string | null | undefined;
  subOrganizations?: sub_organizations_arr_rel_insert_input | null | undefined;
  updatedAt?: any | null | undefined;
  users?: users_arr_rel_insert_input | null | undefined;
};
export type give_ups_organizations_arr_rel_insert_input = {
  data: ReadonlyArray<give_ups_organizations_insert_input>;
  on_conflict?: give_ups_organizations_on_conflict | null | undefined;
};
export type give_ups_organizations_insert_input = {
  giveup?: give_ups_obj_rel_insert_input | null | undefined;
  giveupId?: any | null | undefined;
  organization?: organizations_obj_rel_insert_input | null | undefined;
  organizationId?: string | null | undefined;
};
export type give_ups_obj_rel_insert_input = {
  data: give_ups_insert_input;
  on_conflict?: give_ups_on_conflict | null | undefined;
};
export type give_ups_insert_input = {
  email?: string | null | undefined;
  id?: any | null | undefined;
  lei?: string | null | undefined;
  name?: string | null | undefined;
  organizations?: give_ups_organizations_arr_rel_insert_input | null | undefined;
};
export type give_ups_on_conflict = {
  constraint: give_ups_constraint;
  update_columns?: ReadonlyArray<give_ups_update_column>;
  where?: give_ups_bool_exp | null | undefined;
};
export type give_ups_bool_exp = {
  _and?: ReadonlyArray<give_ups_bool_exp> | null | undefined;
  _not?: give_ups_bool_exp | null | undefined;
  _or?: ReadonlyArray<give_ups_bool_exp> | null | undefined;
  email?: String_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  lei?: String_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  organizations?: give_ups_organizations_bool_exp | null | undefined;
  organizations_aggregate?: give_ups_organizations_aggregate_bool_exp | null | undefined;
};
export type String_comparison_exp = {
  _eq?: string | null | undefined;
  _gt?: string | null | undefined;
  _gte?: string | null | undefined;
  _ilike?: string | null | undefined;
  _in?: ReadonlyArray<string> | null | undefined;
  _iregex?: string | null | undefined;
  _is_null?: boolean | null | undefined;
  _like?: string | null | undefined;
  _lt?: string | null | undefined;
  _lte?: string | null | undefined;
  _neq?: string | null | undefined;
  _nilike?: string | null | undefined;
  _nin?: ReadonlyArray<string> | null | undefined;
  _niregex?: string | null | undefined;
  _nlike?: string | null | undefined;
  _nregex?: string | null | undefined;
  _nsimilar?: string | null | undefined;
  _regex?: string | null | undefined;
  _similar?: string | null | undefined;
};
export type uuid_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type give_ups_organizations_bool_exp = {
  _and?: ReadonlyArray<give_ups_organizations_bool_exp> | null | undefined;
  _not?: give_ups_organizations_bool_exp | null | undefined;
  _or?: ReadonlyArray<give_ups_organizations_bool_exp> | null | undefined;
  giveup?: give_ups_bool_exp | null | undefined;
  giveupId?: uuid_comparison_exp | null | undefined;
  organization?: organizations_bool_exp | null | undefined;
  organizationId?: String_comparison_exp | null | undefined;
};
export type organizations_bool_exp = {
  _and?: ReadonlyArray<organizations_bool_exp> | null | undefined;
  _not?: organizations_bool_exp | null | undefined;
  _or?: ReadonlyArray<organizations_bool_exp> | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  displayName?: String_comparison_exp | null | undefined;
  fixConnectionString?: String_comparison_exp | null | undefined;
  fixConnectionUp?: Boolean_comparison_exp | null | undefined;
  fixEnabled?: Boolean_comparison_exp | null | undefined;
  giveups?: give_ups_organizations_bool_exp | null | undefined;
  giveups_aggregate?: give_ups_organizations_aggregate_bool_exp | null | undefined;
  id?: String_comparison_exp | null | undefined;
  lei?: String_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  subOrganizations?: sub_organizations_bool_exp | null | undefined;
  subOrganizations_aggregate?: sub_organizations_aggregate_bool_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
  users?: users_bool_exp | null | undefined;
  users_aggregate?: users_aggregate_bool_exp | null | undefined;
};
export type timestamptz_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type Boolean_comparison_exp = {
  _eq?: boolean | null | undefined;
  _gt?: boolean | null | undefined;
  _gte?: boolean | null | undefined;
  _in?: ReadonlyArray<boolean> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: boolean | null | undefined;
  _lte?: boolean | null | undefined;
  _neq?: boolean | null | undefined;
  _nin?: ReadonlyArray<boolean> | null | undefined;
};
export type give_ups_organizations_aggregate_bool_exp = {
  count?: give_ups_organizations_aggregate_bool_exp_count | null | undefined;
};
export type give_ups_organizations_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<give_ups_organizations_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: give_ups_organizations_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type Int_comparison_exp = {
  _eq?: number | null | undefined;
  _gt?: number | null | undefined;
  _gte?: number | null | undefined;
  _in?: ReadonlyArray<number> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: number | null | undefined;
  _lte?: number | null | undefined;
  _neq?: number | null | undefined;
  _nin?: ReadonlyArray<number> | null | undefined;
};
export type sub_organizations_bool_exp = {
  _and?: ReadonlyArray<sub_organizations_bool_exp> | null | undefined;
  _not?: sub_organizations_bool_exp | null | undefined;
  _or?: ReadonlyArray<sub_organizations_bool_exp> | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  displayName?: String_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  organizationId?: String_comparison_exp | null | undefined;
  parent_organization?: organizations_bool_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
};
export type sub_organizations_aggregate_bool_exp = {
  count?: sub_organizations_aggregate_bool_exp_count | null | undefined;
};
export type sub_organizations_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sub_organizations_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: sub_organizations_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type users_bool_exp = {
  _and?: ReadonlyArray<users_bool_exp> | null | undefined;
  _not?: users_bool_exp | null | undefined;
  _or?: ReadonlyArray<users_bool_exp> | null | undefined;
  colorSchemePreference?: enum_color_scheme_enum_comparison_exp | null | undefined;
  email?: String_comparison_exp | null | undefined;
  fullName?: String_comparison_exp | null | undefined;
  id?: String_comparison_exp | null | undefined;
  last_seen?: timestamp_comparison_exp | null | undefined;
  organization?: organizations_bool_exp | null | undefined;
  organizationId?: String_comparison_exp | null | undefined;
  pushSubscriptions?: push_subscriptions_bool_exp | null | undefined;
  pushSubscriptions_aggregate?: push_subscriptions_aggregate_bool_exp | null | undefined;
  role?: String_comparison_exp | null | undefined;
  subOrganization?: sub_organizations_bool_exp | null | undefined;
  subOrganizationId?: uuid_comparison_exp | null | undefined;
  suspended_state?: enum_users_axe_suspended_state_enum_comparison_exp | null | undefined;
  tierings?: user_main_tiering_bool_exp | null | undefined;
  tierings_aggregate?: user_main_tiering_aggregate_bool_exp | null | undefined;
};
export type enum_color_scheme_enum_comparison_exp = {
  _eq?: enum_color_scheme_enum | null | undefined;
  _in?: ReadonlyArray<enum_color_scheme_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_color_scheme_enum | null | undefined;
  _nin?: ReadonlyArray<enum_color_scheme_enum> | null | undefined;
};
export type timestamp_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type push_subscriptions_bool_exp = {
  _and?: ReadonlyArray<push_subscriptions_bool_exp> | null | undefined;
  _not?: push_subscriptions_bool_exp | null | undefined;
  _or?: ReadonlyArray<push_subscriptions_bool_exp> | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  subscription?: jsonb_comparison_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
  user?: users_bool_exp | null | undefined;
  userId?: String_comparison_exp | null | undefined;
};
export type jsonb_comparison_exp = {
  _cast?: jsonb_cast_exp | null | undefined;
  _contained_in?: any | null | undefined;
  _contains?: any | null | undefined;
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _has_key?: string | null | undefined;
  _has_keys_all?: ReadonlyArray<string> | null | undefined;
  _has_keys_any?: ReadonlyArray<string> | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type jsonb_cast_exp = {
  String?: String_comparison_exp | null | undefined;
};
export type push_subscriptions_aggregate_bool_exp = {
  count?: push_subscriptions_aggregate_bool_exp_count | null | undefined;
};
export type push_subscriptions_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<push_subscriptions_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: push_subscriptions_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type enum_users_axe_suspended_state_enum_comparison_exp = {
  _eq?: enum_users_axe_suspended_state_enum | null | undefined;
  _in?: ReadonlyArray<enum_users_axe_suspended_state_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_users_axe_suspended_state_enum | null | undefined;
  _nin?: ReadonlyArray<enum_users_axe_suspended_state_enum> | null | undefined;
};
export type user_main_tiering_bool_exp = {
  _and?: ReadonlyArray<user_main_tiering_bool_exp> | null | undefined;
  _not?: user_main_tiering_bool_exp | null | undefined;
  _or?: ReadonlyArray<user_main_tiering_bool_exp> | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  isRemoved?: Boolean_comparison_exp | null | undefined;
  organizationId?: String_comparison_exp | null | undefined;
  ownerId?: String_comparison_exp | null | undefined;
  subOrganizationId?: uuid_comparison_exp | null | undefined;
  tier?: enum_tiers_enum_comparison_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
  user_main_tiering_organization?: organizations_bool_exp | null | undefined;
  user_main_tiering_subOrganization?: sub_organizations_bool_exp | null | undefined;
};
export type enum_tiers_enum_comparison_exp = {
  _eq?: enum_tiers_enum | null | undefined;
  _in?: ReadonlyArray<enum_tiers_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_tiers_enum | null | undefined;
  _nin?: ReadonlyArray<enum_tiers_enum> | null | undefined;
};
export type user_main_tiering_aggregate_bool_exp = {
  bool_and?: user_main_tiering_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: user_main_tiering_aggregate_bool_exp_bool_or | null | undefined;
  count?: user_main_tiering_aggregate_bool_exp_count | null | undefined;
};
export type user_main_tiering_aggregate_bool_exp_bool_and = {
  arguments: user_main_tiering_select_column_user_main_tiering_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: user_main_tiering_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type user_main_tiering_aggregate_bool_exp_bool_or = {
  arguments: user_main_tiering_select_column_user_main_tiering_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: user_main_tiering_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type user_main_tiering_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<user_main_tiering_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: user_main_tiering_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type users_aggregate_bool_exp = {
  count?: users_aggregate_bool_exp_count | null | undefined;
};
export type users_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<users_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: users_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type give_ups_organizations_on_conflict = {
  constraint: give_ups_organizations_constraint;
  update_columns?: ReadonlyArray<give_ups_organizations_update_column>;
  where?: give_ups_organizations_bool_exp | null | undefined;
};
export type users_arr_rel_insert_input = {
  data: ReadonlyArray<users_insert_input>;
  on_conflict?: users_on_conflict | null | undefined;
};
export type users_insert_input = {
  colorSchemePreference?: enum_color_scheme_enum | null | undefined;
  email?: string | null | undefined;
  fullName?: string | null | undefined;
  id?: string | null | undefined;
  last_seen?: any | null | undefined;
  organization?: organizations_obj_rel_insert_input | null | undefined;
  organizationId?: string | null | undefined;
  pushSubscriptions?: push_subscriptions_arr_rel_insert_input | null | undefined;
  role?: string | null | undefined;
  subOrganization?: sub_organizations_obj_rel_insert_input | null | undefined;
  subOrganizationId?: any | null | undefined;
  suspended_state?: enum_users_axe_suspended_state_enum | null | undefined;
  tierings?: user_main_tiering_arr_rel_insert_input | null | undefined;
};
export type push_subscriptions_arr_rel_insert_input = {
  data: ReadonlyArray<push_subscriptions_insert_input>;
  on_conflict?: push_subscriptions_on_conflict | null | undefined;
};
export type push_subscriptions_insert_input = {
  createdAt?: any | null | undefined;
  id?: any | null | undefined;
  subscription?: any | null | undefined;
  updatedAt?: any | null | undefined;
  user?: users_obj_rel_insert_input | null | undefined;
  userId?: string | null | undefined;
};
export type users_obj_rel_insert_input = {
  data: users_insert_input;
  on_conflict?: users_on_conflict | null | undefined;
};
export type users_on_conflict = {
  constraint: users_constraint;
  update_columns?: ReadonlyArray<users_update_column>;
  where?: users_bool_exp | null | undefined;
};
export type push_subscriptions_on_conflict = {
  constraint: push_subscriptions_constraint;
  update_columns?: ReadonlyArray<push_subscriptions_update_column>;
  where?: push_subscriptions_bool_exp | null | undefined;
};
export type sub_organizations_obj_rel_insert_input = {
  data: sub_organizations_insert_input;
  on_conflict?: sub_organizations_on_conflict | null | undefined;
};
export type sub_organizations_on_conflict = {
  constraint: sub_organizations_constraint;
  update_columns?: ReadonlyArray<sub_organizations_update_column>;
  where?: sub_organizations_bool_exp | null | undefined;
};
export type user_main_tiering_arr_rel_insert_input = {
  data: ReadonlyArray<user_main_tiering_insert_input>;
  on_conflict?: user_main_tiering_on_conflict | null | undefined;
};
export type user_main_tiering_insert_input = {
  createdAt?: any | null | undefined;
  id?: any | null | undefined;
  isRemoved?: boolean | null | undefined;
  organizationId?: string | null | undefined;
  ownerId?: string | null | undefined;
  subOrganizationId?: any | null | undefined;
  tier?: enum_tiers_enum | null | undefined;
  updatedAt?: any | null | undefined;
  user_main_tiering_organization?: organizations_obj_rel_insert_input | null | undefined;
  user_main_tiering_subOrganization?: sub_organizations_obj_rel_insert_input | null | undefined;
};
export type user_main_tiering_on_conflict = {
  constraint: user_main_tiering_constraint;
  update_columns?: ReadonlyArray<user_main_tiering_update_column>;
  where?: user_main_tiering_bool_exp | null | undefined;
};
export type organizations_on_conflict = {
  constraint: organizations_constraint;
  update_columns?: ReadonlyArray<organizations_update_column>;
  where?: organizations_bool_exp | null | undefined;
};
export type axe_authors_on_conflict = {
  constraint: axe_authors_constraint;
  update_columns?: ReadonlyArray<axe_authors_update_column>;
  where?: axe_authors_bool_exp | null | undefined;
};
export type axe_authors_bool_exp = {
  _and?: ReadonlyArray<axe_authors_bool_exp> | null | undefined;
  _not?: axe_authors_bool_exp | null | undefined;
  _or?: ReadonlyArray<axe_authors_bool_exp> | null | undefined;
  axe?: axes_bool_exp | null | undefined;
  axe_aggregate?: axes_aggregate_bool_exp | null | undefined;
  axe_id?: uuid_comparison_exp | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  organizationId?: String_comparison_exp | null | undefined;
  subOrganization?: sub_organizations_bool_exp | null | undefined;
  subOrganizationId?: uuid_comparison_exp | null | undefined;
  subOrganization_aggregate?: sub_organizations_aggregate_bool_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
  user?: users_bool_exp | null | undefined;
  user_aggregate?: users_aggregate_bool_exp | null | undefined;
  user_id?: String_comparison_exp | null | undefined;
};
export type axes_bool_exp = {
  _and?: ReadonlyArray<axes_bool_exp> | null | undefined;
  _not?: axes_bool_exp | null | undefined;
  _or?: ReadonlyArray<axes_bool_exp> | null | undefined;
  axe_authors?: axe_authors_bool_exp | null | undefined;
  axe_authors_aggregate?: axe_authors_aggregate_bool_exp | null | undefined;
  axe_legs?: axe_legs_bool_exp | null | undefined;
  axe_legs_aggregate?: axe_legs_aggregate_bool_exp | null | undefined;
  axes_axe_tier_pricings?: axe_tier_pricing_bool_exp | null | undefined;
  axes_axe_tier_pricings_aggregate?: axe_tier_pricing_aggregate_bool_exp | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  filled?: Int_comparison_exp | null | undefined;
  hidden?: Boolean_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  inRFQ?: Boolean_comparison_exp | null | undefined;
  paused?: Boolean_comparison_exp | null | undefined;
  state?: enum_axe_state_enum_comparison_exp | null | undefined;
  tradedNotional?: bigint_comparison_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
};
export type axe_authors_aggregate_bool_exp = {
  count?: axe_authors_aggregate_bool_exp_count | null | undefined;
};
export type axe_authors_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<axe_authors_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: axe_authors_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type axe_legs_bool_exp = {
  _and?: ReadonlyArray<axe_legs_bool_exp> | null | undefined;
  _not?: axe_legs_bool_exp | null | undefined;
  _or?: ReadonlyArray<axe_legs_bool_exp> | null | undefined;
  axe?: axes_bool_exp | null | undefined;
  axeId?: uuid_comparison_exp | null | undefined;
  axe_aggregate?: axes_aggregate_bool_exp | null | undefined;
  baseCurrDepo?: float8_comparison_exp | null | undefined;
  buySell?: enum_axe_buysell_enum_comparison_exp | null | undefined;
  callPut?: enum_axe_callput_enum_comparison_exp | null | undefined;
  ccyPair?: enum_axe_ccypair_enum_comparison_exp | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  cut?: String_comparison_exp | null | undefined;
  deliveryDate?: date_comparison_exp | null | undefined;
  delta?: bigint_comparison_exp | null | undefined;
  depoCcy1?: float8_comparison_exp | null | undefined;
  depoCcy1DayCount?: enum_axe_depoccy1daycount_enum_comparison_exp | null | undefined;
  depoCcy2?: float8_comparison_exp | null | undefined;
  depoCcy2DayCount?: enum_axe_depoccy2daycount_enum_comparison_exp | null | undefined;
  expiryDate?: date_comparison_exp | null | undefined;
  fix?: String_comparison_exp | null | undefined;
  forward?: float8_comparison_exp | null | undefined;
  forwardPoints?: float8_comparison_exp | null | undefined;
  gamma?: float8_comparison_exp | null | undefined;
  hedgeType?: enum_axe_hedgetype_enum_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  minimumNotionalAmount?: bigint_comparison_exp | null | undefined;
  notional?: bigint_comparison_exp | null | undefined;
  notionalCurrency?: enum_currency_notional_enum_comparison_exp | null | undefined;
  orderIndex?: Int_comparison_exp | null | undefined;
  premium?: Int_comparison_exp | null | undefined;
  premiumCurrency?: enum_currency_premium_enum_comparison_exp | null | undefined;
  premiumDate?: date_comparison_exp | null | undefined;
  premiumType?: enum_axe_premiumtype_enum_comparison_exp | null | undefined;
  pricingCurrDepo?: float8_comparison_exp | null | undefined;
  pricingVolatility?: float8_comparison_exp | null | undefined;
  product?: enum_axe_product_enum_comparison_exp | null | undefined;
  spot?: float8_comparison_exp | null | undefined;
  spotDate?: date_comparison_exp | null | undefined;
  strike?: float8_comparison_exp | null | undefined;
  swaps?: float8_comparison_exp | null | undefined;
  tenor?: enum_tenor_enum_comparison_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
  vega?: float8_comparison_exp | null | undefined;
  volatility?: float8_comparison_exp | null | undefined;
};
export type axes_aggregate_bool_exp = {
  bool_and?: axes_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: axes_aggregate_bool_exp_bool_or | null | undefined;
  count?: axes_aggregate_bool_exp_count | null | undefined;
};
export type axes_aggregate_bool_exp_bool_and = {
  arguments: axes_select_column_axes_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axes_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type axes_aggregate_bool_exp_bool_or = {
  arguments: axes_select_column_axes_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axes_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type axes_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<axes_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: axes_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type float8_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type enum_axe_buysell_enum_comparison_exp = {
  _eq?: enum_axe_buysell_enum | null | undefined;
  _in?: ReadonlyArray<enum_axe_buysell_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_axe_buysell_enum | null | undefined;
  _nin?: ReadonlyArray<enum_axe_buysell_enum> | null | undefined;
};
export type enum_axe_callput_enum_comparison_exp = {
  _eq?: enum_axe_callput_enum | null | undefined;
  _in?: ReadonlyArray<enum_axe_callput_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_axe_callput_enum | null | undefined;
  _nin?: ReadonlyArray<enum_axe_callput_enum> | null | undefined;
};
export type enum_axe_ccypair_enum_comparison_exp = {
  _eq?: enum_axe_ccypair_enum | null | undefined;
  _in?: ReadonlyArray<enum_axe_ccypair_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_axe_ccypair_enum | null | undefined;
  _nin?: ReadonlyArray<enum_axe_ccypair_enum> | null | undefined;
};
export type date_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type bigint_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type enum_axe_depoccy1daycount_enum_comparison_exp = {
  _eq?: enum_axe_depoccy1daycount_enum | null | undefined;
  _in?: ReadonlyArray<enum_axe_depoccy1daycount_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_axe_depoccy1daycount_enum | null | undefined;
  _nin?: ReadonlyArray<enum_axe_depoccy1daycount_enum> | null | undefined;
};
export type enum_axe_depoccy2daycount_enum_comparison_exp = {
  _eq?: enum_axe_depoccy2daycount_enum | null | undefined;
  _in?: ReadonlyArray<enum_axe_depoccy2daycount_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_axe_depoccy2daycount_enum | null | undefined;
  _nin?: ReadonlyArray<enum_axe_depoccy2daycount_enum> | null | undefined;
};
export type enum_axe_hedgetype_enum_comparison_exp = {
  _eq?: enum_axe_hedgetype_enum | null | undefined;
  _in?: ReadonlyArray<enum_axe_hedgetype_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_axe_hedgetype_enum | null | undefined;
  _nin?: ReadonlyArray<enum_axe_hedgetype_enum> | null | undefined;
};
export type enum_currency_notional_enum_comparison_exp = {
  _eq?: enum_currency_notional_enum | null | undefined;
  _in?: ReadonlyArray<enum_currency_notional_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_currency_notional_enum | null | undefined;
  _nin?: ReadonlyArray<enum_currency_notional_enum> | null | undefined;
};
export type enum_currency_premium_enum_comparison_exp = {
  _eq?: enum_currency_premium_enum | null | undefined;
  _in?: ReadonlyArray<enum_currency_premium_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_currency_premium_enum | null | undefined;
  _nin?: ReadonlyArray<enum_currency_premium_enum> | null | undefined;
};
export type enum_axe_premiumtype_enum_comparison_exp = {
  _eq?: enum_axe_premiumtype_enum | null | undefined;
  _in?: ReadonlyArray<enum_axe_premiumtype_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_axe_premiumtype_enum | null | undefined;
  _nin?: ReadonlyArray<enum_axe_premiumtype_enum> | null | undefined;
};
export type enum_axe_product_enum_comparison_exp = {
  _eq?: enum_axe_product_enum | null | undefined;
  _in?: ReadonlyArray<enum_axe_product_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_axe_product_enum | null | undefined;
  _nin?: ReadonlyArray<enum_axe_product_enum> | null | undefined;
};
export type enum_tenor_enum_comparison_exp = {
  _eq?: enum_tenor_enum | null | undefined;
  _in?: ReadonlyArray<enum_tenor_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_tenor_enum | null | undefined;
  _nin?: ReadonlyArray<enum_tenor_enum> | null | undefined;
};
export type axe_legs_aggregate_bool_exp = {
  avg?: axe_legs_aggregate_bool_exp_avg | null | undefined;
  corr?: axe_legs_aggregate_bool_exp_corr | null | undefined;
  count?: axe_legs_aggregate_bool_exp_count | null | undefined;
  covar_samp?: axe_legs_aggregate_bool_exp_covar_samp | null | undefined;
  max?: axe_legs_aggregate_bool_exp_max | null | undefined;
  min?: axe_legs_aggregate_bool_exp_min | null | undefined;
  stddev_samp?: axe_legs_aggregate_bool_exp_stddev_samp | null | undefined;
  sum?: axe_legs_aggregate_bool_exp_sum | null | undefined;
  var_samp?: axe_legs_aggregate_bool_exp_var_samp | null | undefined;
};
export type axe_legs_aggregate_bool_exp_avg = {
  arguments: axe_legs_select_column_axe_legs_aggregate_bool_exp_avg_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_legs_aggregate_bool_exp_corr = {
  arguments: axe_legs_aggregate_bool_exp_corr_arguments;
  distinct?: boolean | null | undefined;
  filter?: axe_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_legs_aggregate_bool_exp_corr_arguments = {
  X: axe_legs_select_column_axe_legs_aggregate_bool_exp_corr_arguments_columns;
  Y: axe_legs_select_column_axe_legs_aggregate_bool_exp_corr_arguments_columns;
};
export type axe_legs_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<axe_legs_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: axe_legs_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type axe_legs_aggregate_bool_exp_covar_samp = {
  arguments: axe_legs_aggregate_bool_exp_covar_samp_arguments;
  distinct?: boolean | null | undefined;
  filter?: axe_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_legs_aggregate_bool_exp_covar_samp_arguments = {
  X: axe_legs_select_column_axe_legs_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: axe_legs_select_column_axe_legs_aggregate_bool_exp_covar_samp_arguments_columns;
};
export type axe_legs_aggregate_bool_exp_max = {
  arguments: axe_legs_select_column_axe_legs_aggregate_bool_exp_max_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_legs_aggregate_bool_exp_min = {
  arguments: axe_legs_select_column_axe_legs_aggregate_bool_exp_min_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_legs_aggregate_bool_exp_stddev_samp = {
  arguments: axe_legs_select_column_axe_legs_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_legs_aggregate_bool_exp_sum = {
  arguments: axe_legs_select_column_axe_legs_aggregate_bool_exp_sum_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_legs_aggregate_bool_exp_var_samp = {
  arguments: axe_legs_select_column_axe_legs_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_tier_pricing_bool_exp = {
  _and?: ReadonlyArray<axe_tier_pricing_bool_exp> | null | undefined;
  _not?: axe_tier_pricing_bool_exp | null | undefined;
  _or?: ReadonlyArray<axe_tier_pricing_bool_exp> | null | undefined;
  axe?: axes_bool_exp | null | undefined;
  axeId?: uuid_comparison_exp | null | undefined;
  axe_aggregate?: axes_aggregate_bool_exp | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  pricingVolatility?: float8_comparison_exp | null | undefined;
  tier?: enum_tiers_enum_comparison_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
};
export type axe_tier_pricing_aggregate_bool_exp = {
  avg?: axe_tier_pricing_aggregate_bool_exp_avg | null | undefined;
  corr?: axe_tier_pricing_aggregate_bool_exp_corr | null | undefined;
  count?: axe_tier_pricing_aggregate_bool_exp_count | null | undefined;
  covar_samp?: axe_tier_pricing_aggregate_bool_exp_covar_samp | null | undefined;
  max?: axe_tier_pricing_aggregate_bool_exp_max | null | undefined;
  min?: axe_tier_pricing_aggregate_bool_exp_min | null | undefined;
  stddev_samp?: axe_tier_pricing_aggregate_bool_exp_stddev_samp | null | undefined;
  sum?: axe_tier_pricing_aggregate_bool_exp_sum | null | undefined;
  var_samp?: axe_tier_pricing_aggregate_bool_exp_var_samp | null | undefined;
};
export type axe_tier_pricing_aggregate_bool_exp_avg = {
  arguments: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_avg_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_tier_pricing_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_tier_pricing_aggregate_bool_exp_corr = {
  arguments: axe_tier_pricing_aggregate_bool_exp_corr_arguments;
  distinct?: boolean | null | undefined;
  filter?: axe_tier_pricing_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_tier_pricing_aggregate_bool_exp_corr_arguments = {
  X: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_corr_arguments_columns;
  Y: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_corr_arguments_columns;
};
export type axe_tier_pricing_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<axe_tier_pricing_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: axe_tier_pricing_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type axe_tier_pricing_aggregate_bool_exp_covar_samp = {
  arguments: axe_tier_pricing_aggregate_bool_exp_covar_samp_arguments;
  distinct?: boolean | null | undefined;
  filter?: axe_tier_pricing_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_tier_pricing_aggregate_bool_exp_covar_samp_arguments = {
  X: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_covar_samp_arguments_columns;
};
export type axe_tier_pricing_aggregate_bool_exp_max = {
  arguments: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_max_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_tier_pricing_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_tier_pricing_aggregate_bool_exp_min = {
  arguments: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_min_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_tier_pricing_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_tier_pricing_aggregate_bool_exp_stddev_samp = {
  arguments: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_tier_pricing_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_tier_pricing_aggregate_bool_exp_sum = {
  arguments: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_sum_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_tier_pricing_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_tier_pricing_aggregate_bool_exp_var_samp = {
  arguments: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_tier_pricing_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type enum_axe_state_enum_comparison_exp = {
  _eq?: enum_axe_state_enum | null | undefined;
  _in?: ReadonlyArray<enum_axe_state_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_axe_state_enum | null | undefined;
  _nin?: ReadonlyArray<enum_axe_state_enum> | null | undefined;
};
export type axe_legs_arr_rel_insert_input = {
  data: ReadonlyArray<axe_legs_insert_input>;
  on_conflict?: axe_legs_on_conflict | null | undefined;
};
export type axe_legs_insert_input = {
  axe?: axes_arr_rel_insert_input | null | undefined;
  axeId?: any | null | undefined;
  baseCurrDepo?: any | null | undefined;
  buySell?: enum_axe_buysell_enum | null | undefined;
  callPut?: enum_axe_callput_enum | null | undefined;
  ccyPair?: enum_axe_ccypair_enum | null | undefined;
  createdAt?: any | null | undefined;
  cut?: string | null | undefined;
  deliveryDate?: any | null | undefined;
  delta?: any | null | undefined;
  depoCcy1?: any | null | undefined;
  depoCcy1DayCount?: enum_axe_depoccy1daycount_enum | null | undefined;
  depoCcy2?: any | null | undefined;
  depoCcy2DayCount?: enum_axe_depoccy2daycount_enum | null | undefined;
  expiryDate?: any | null | undefined;
  fix?: string | null | undefined;
  forward?: any | null | undefined;
  forwardPoints?: any | null | undefined;
  gamma?: any | null | undefined;
  hedgeType?: enum_axe_hedgetype_enum | null | undefined;
  id?: any | null | undefined;
  minimumNotionalAmount?: any | null | undefined;
  notional?: any | null | undefined;
  notionalCurrency?: enum_currency_notional_enum | null | undefined;
  orderIndex?: number | null | undefined;
  premium?: number | null | undefined;
  premiumCurrency?: enum_currency_premium_enum | null | undefined;
  premiumDate?: any | null | undefined;
  premiumType?: enum_axe_premiumtype_enum | null | undefined;
  pricingCurrDepo?: any | null | undefined;
  pricingVolatility?: any | null | undefined;
  product?: enum_axe_product_enum | null | undefined;
  spot?: any | null | undefined;
  spotDate?: any | null | undefined;
  strike?: any | null | undefined;
  swaps?: any | null | undefined;
  tenor?: enum_tenor_enum | null | undefined;
  updatedAt?: any | null | undefined;
  vega?: any | null | undefined;
  volatility?: any | null | undefined;
};
export type axe_legs_on_conflict = {
  constraint: axe_legs_constraint;
  update_columns?: ReadonlyArray<axe_legs_update_column>;
  where?: axe_legs_bool_exp | null | undefined;
};
export type axe_tier_pricing_arr_rel_insert_input = {
  data: ReadonlyArray<axe_tier_pricing_insert_input>;
  on_conflict?: axe_tier_pricing_on_conflict | null | undefined;
};
export type axe_tier_pricing_insert_input = {
  axe?: axes_arr_rel_insert_input | null | undefined;
  axeId?: any | null | undefined;
  createdAt?: any | null | undefined;
  id?: any | null | undefined;
  pricingVolatility?: any | null | undefined;
  tier?: enum_tiers_enum | null | undefined;
  updatedAt?: any | null | undefined;
};
export type axe_tier_pricing_on_conflict = {
  constraint: axe_tier_pricing_constraint;
  update_columns?: ReadonlyArray<axe_tier_pricing_update_column>;
  where?: axe_tier_pricing_bool_exp | null | undefined;
};
export type axes_on_conflict = {
  constraint: axes_constraint;
  update_columns?: ReadonlyArray<axes_update_column>;
  where?: axes_bool_exp | null | undefined;
};
export type organizations_arr_rel_insert_input = {
  data: ReadonlyArray<organizations_insert_input>;
  on_conflict?: organizations_on_conflict | null | undefined;
};
export type funds_obj_rel_insert_input = {
  data: funds_insert_input;
  on_conflict?: funds_on_conflict | null | undefined;
};
export type funds_insert_input = {
  id?: any | null | undefined;
  lei?: string | null | undefined;
  name?: string | null | undefined;
  organisationId?: string | null | undefined;
};
export type funds_on_conflict = {
  constraint: funds_constraint;
  update_columns?: ReadonlyArray<funds_update_column>;
  where?: funds_bool_exp | null | undefined;
};
export type funds_bool_exp = {
  _and?: ReadonlyArray<funds_bool_exp> | null | undefined;
  _not?: funds_bool_exp | null | undefined;
  _or?: ReadonlyArray<funds_bool_exp> | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  lei?: String_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  organisationId?: String_comparison_exp | null | undefined;
};
export type user_tiers_obj_rel_insert_input = {
  data: user_tiers_insert_input;
  on_conflict?: user_tiers_on_conflict | null | undefined;
};
export type user_tiers_insert_input = {
  axe?: axes_arr_rel_insert_input | null | undefined;
  axeId?: any | null | undefined;
  axe_tier?: axe_tier_pricing_obj_rel_insert_input | null | undefined;
  createdAt?: any | null | undefined;
  id?: any | null | undefined;
  isRemoved?: boolean | null | undefined;
  organizationId?: string | null | undefined;
  subOrganizationId?: any | null | undefined;
  tierId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  userId?: string | null | undefined;
  user_main_tiering_user?: users_obj_rel_insert_input | null | undefined;
  user_organization?: organizations_obj_rel_insert_input | null | undefined;
  user_sub_organization?: sub_organizations_obj_rel_insert_input | null | undefined;
};
export type axe_tier_pricing_obj_rel_insert_input = {
  data: axe_tier_pricing_insert_input;
  on_conflict?: axe_tier_pricing_on_conflict | null | undefined;
};
export type user_tiers_on_conflict = {
  constraint: user_tiers_constraint;
  update_columns?: ReadonlyArray<user_tiers_update_column>;
  where?: user_tiers_bool_exp | null | undefined;
};
export type user_tiers_bool_exp = {
  _and?: ReadonlyArray<user_tiers_bool_exp> | null | undefined;
  _not?: user_tiers_bool_exp | null | undefined;
  _or?: ReadonlyArray<user_tiers_bool_exp> | null | undefined;
  axe?: axes_bool_exp | null | undefined;
  axeId?: uuid_comparison_exp | null | undefined;
  axe_aggregate?: axes_aggregate_bool_exp | null | undefined;
  axe_tier?: axe_tier_pricing_bool_exp | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  isRemoved?: Boolean_comparison_exp | null | undefined;
  organizationId?: String_comparison_exp | null | undefined;
  subOrganizationId?: uuid_comparison_exp | null | undefined;
  tierId?: uuid_comparison_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
  userId?: String_comparison_exp | null | undefined;
  user_main_tiering_user?: users_bool_exp | null | undefined;
  user_organization?: organizations_bool_exp | null | undefined;
  user_sub_organization?: sub_organizations_bool_exp | null | undefined;
};
export type rfq_legs_arr_rel_insert_input = {
  data: ReadonlyArray<rfq_legs_insert_input>;
  on_conflict?: rfq_legs_on_conflict | null | undefined;
};
export type rfq_legs_insert_input = {
  buySell?: enum_axe_buysell_enum | null | undefined;
  callPut?: enum_axe_callput_enum | null | undefined;
  ccyPair?: enum_axe_ccypair_enum | null | undefined;
  createdAt?: any | null | undefined;
  cut?: string | null | undefined;
  deliveryDate?: any | null | undefined;
  expiryDate?: any | null | undefined;
  fix?: string | null | undefined;
  forward?: any | null | undefined;
  hedgeType?: enum_axe_hedgetype_enum | null | undefined;
  id?: any | null | undefined;
  minimumNotionalAmount?: any | null | undefined;
  notional?: any | null | undefined;
  notionalCurrency?: enum_currency_notional_enum | null | undefined;
  orderIndex?: number | null | undefined;
  premium?: number | null | undefined;
  premiumCurrency?: enum_currency_premium_enum | null | undefined;
  premiumDate?: any | null | undefined;
  premiumType?: enum_axe_premiumtype_enum | null | undefined;
  product?: enum_axe_product_enum | null | undefined;
  rfq?: rfq_obj_rel_insert_input | null | undefined;
  rfqId?: any | null | undefined;
  rfq_options_legs?: rfq_options_legs_arr_rel_insert_input | null | undefined;
  spot?: any | null | undefined;
  strike?: any | null | undefined;
  swaps?: any | null | undefined;
  updatedAt?: any | null | undefined;
};
export type rfq_options_legs_arr_rel_insert_input = {
  data: ReadonlyArray<rfq_options_legs_insert_input>;
  on_conflict?: rfq_options_legs_on_conflict | null | undefined;
};
export type rfq_options_legs_insert_input = {
  bsDelta?: any | null | undefined;
  createdAt?: any | null | undefined;
  delta?: any | null | undefined;
  id?: any | null | undefined;
  premium?: number | null | undefined;
  pricingVolatility?: any | null | undefined;
  rfqLegId?: any | null | undefined;
  rfqOptionId?: any | null | undefined;
  rfq_leg?: rfq_legs_obj_rel_insert_input | null | undefined;
  rfq_option?: rfq_options_obj_rel_insert_input | null | undefined;
  updatedAt?: any | null | undefined;
};
export type rfq_legs_obj_rel_insert_input = {
  data: rfq_legs_insert_input;
  on_conflict?: rfq_legs_on_conflict | null | undefined;
};
export type rfq_legs_on_conflict = {
  constraint: rfq_legs_constraint;
  update_columns?: ReadonlyArray<rfq_legs_update_column>;
  where?: rfq_legs_bool_exp | null | undefined;
};
export type rfq_legs_bool_exp = {
  _and?: ReadonlyArray<rfq_legs_bool_exp> | null | undefined;
  _not?: rfq_legs_bool_exp | null | undefined;
  _or?: ReadonlyArray<rfq_legs_bool_exp> | null | undefined;
  buySell?: enum_axe_buysell_enum_comparison_exp | null | undefined;
  callPut?: enum_axe_callput_enum_comparison_exp | null | undefined;
  ccyPair?: enum_axe_ccypair_enum_comparison_exp | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  cut?: String_comparison_exp | null | undefined;
  deliveryDate?: date_comparison_exp | null | undefined;
  expiryDate?: date_comparison_exp | null | undefined;
  fix?: String_comparison_exp | null | undefined;
  forward?: float8_comparison_exp | null | undefined;
  hedgeType?: enum_axe_hedgetype_enum_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  minimumNotionalAmount?: bigint_comparison_exp | null | undefined;
  notional?: bigint_comparison_exp | null | undefined;
  notionalCurrency?: enum_currency_notional_enum_comparison_exp | null | undefined;
  orderIndex?: Int_comparison_exp | null | undefined;
  premium?: Int_comparison_exp | null | undefined;
  premiumCurrency?: enum_currency_premium_enum_comparison_exp | null | undefined;
  premiumDate?: date_comparison_exp | null | undefined;
  premiumType?: enum_axe_premiumtype_enum_comparison_exp | null | undefined;
  product?: enum_axe_product_enum_comparison_exp | null | undefined;
  rfq?: rfq_bool_exp | null | undefined;
  rfqId?: uuid_comparison_exp | null | undefined;
  rfq_options_legs?: rfq_options_legs_bool_exp | null | undefined;
  rfq_options_legs_aggregate?: rfq_options_legs_aggregate_bool_exp | null | undefined;
  spot?: float8_comparison_exp | null | undefined;
  strike?: float8_comparison_exp | null | undefined;
  swaps?: float8_comparison_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
};
export type rfq_bool_exp = {
  _and?: ReadonlyArray<rfq_bool_exp> | null | undefined;
  _not?: rfq_bool_exp | null | undefined;
  _or?: ReadonlyArray<rfq_bool_exp> | null | undefined;
  axe?: axes_bool_exp | null | undefined;
  axeCustomer?: users_bool_exp | null | undefined;
  axeCustomerUserId?: String_comparison_exp | null | undefined;
  axeCustomer_aggregate?: users_aggregate_bool_exp | null | undefined;
  axeId?: uuid_comparison_exp | null | undefined;
  axeOwner?: users_bool_exp | null | undefined;
  axeOwnerUserId?: String_comparison_exp | null | undefined;
  axeOwner_aggregate?: users_aggregate_bool_exp | null | undefined;
  axe_aggregate?: axes_aggregate_bool_exp | null | undefined;
  channel?: String_comparison_exp | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  customerOrganization?: organizations_bool_exp | null | undefined;
  customerOrganizationId?: String_comparison_exp | null | undefined;
  customerOrganization_aggregate?: organizations_aggregate_bool_exp | null | undefined;
  customerSubOrganizationId?: uuid_comparison_exp | null | undefined;
  deltaHedge?: bigint_comparison_exp | null | undefined;
  expireAt?: timestamptz_comparison_exp | null | undefined;
  fund?: funds_bool_exp | null | undefined;
  fundId?: uuid_comparison_exp | null | undefined;
  give_up?: give_ups_bool_exp | null | undefined;
  giveupId?: uuid_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  notional?: bigint_comparison_exp | null | undefined;
  ownerOrganization?: organizations_bool_exp | null | undefined;
  ownerOrganizationId?: String_comparison_exp | null | undefined;
  ownerOrganization_aggregate?: organizations_aggregate_bool_exp | null | undefined;
  ownerSubOrganization?: sub_organizations_bool_exp | null | undefined;
  ownerSubOrganizationId?: uuid_comparison_exp | null | undefined;
  ownerSubOrganization_aggregate?: sub_organizations_aggregate_bool_exp | null | undefined;
  premiumType?: enum_axe_premiumtype_enum_comparison_exp | null | undefined;
  rfq_axe_tiers?: user_tiers_bool_exp | null | undefined;
  rfq_legs?: rfq_legs_bool_exp | null | undefined;
  rfq_legs_aggregate?: rfq_legs_aggregate_bool_exp | null | undefined;
  rfq_options?: rfq_options_bool_exp | null | undefined;
  rfq_options_aggregate?: rfq_options_aggregate_bool_exp | null | undefined;
  side?: enum_quote_side_type_enum_comparison_exp | null | undefined;
  status?: enum_rfq_status_enum_comparison_exp | null | undefined;
  trades?: trades_bool_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
};
export type organizations_aggregate_bool_exp = {
  bool_and?: organizations_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: organizations_aggregate_bool_exp_bool_or | null | undefined;
  count?: organizations_aggregate_bool_exp_count | null | undefined;
};
export type organizations_aggregate_bool_exp_bool_and = {
  arguments: organizations_select_column_organizations_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: organizations_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type organizations_aggregate_bool_exp_bool_or = {
  arguments: organizations_select_column_organizations_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: organizations_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type organizations_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<organizations_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: organizations_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type rfq_legs_aggregate_bool_exp = {
  avg?: rfq_legs_aggregate_bool_exp_avg | null | undefined;
  corr?: rfq_legs_aggregate_bool_exp_corr | null | undefined;
  count?: rfq_legs_aggregate_bool_exp_count | null | undefined;
  covar_samp?: rfq_legs_aggregate_bool_exp_covar_samp | null | undefined;
  max?: rfq_legs_aggregate_bool_exp_max | null | undefined;
  min?: rfq_legs_aggregate_bool_exp_min | null | undefined;
  stddev_samp?: rfq_legs_aggregate_bool_exp_stddev_samp | null | undefined;
  sum?: rfq_legs_aggregate_bool_exp_sum | null | undefined;
  var_samp?: rfq_legs_aggregate_bool_exp_var_samp | null | undefined;
};
export type rfq_legs_aggregate_bool_exp_avg = {
  arguments: rfq_legs_select_column_rfq_legs_aggregate_bool_exp_avg_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: rfq_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type rfq_legs_aggregate_bool_exp_corr = {
  arguments: rfq_legs_aggregate_bool_exp_corr_arguments;
  distinct?: boolean | null | undefined;
  filter?: rfq_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type rfq_legs_aggregate_bool_exp_corr_arguments = {
  X: rfq_legs_select_column_rfq_legs_aggregate_bool_exp_corr_arguments_columns;
  Y: rfq_legs_select_column_rfq_legs_aggregate_bool_exp_corr_arguments_columns;
};
export type rfq_legs_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<rfq_legs_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: rfq_legs_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type rfq_legs_aggregate_bool_exp_covar_samp = {
  arguments: rfq_legs_aggregate_bool_exp_covar_samp_arguments;
  distinct?: boolean | null | undefined;
  filter?: rfq_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type rfq_legs_aggregate_bool_exp_covar_samp_arguments = {
  X: rfq_legs_select_column_rfq_legs_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: rfq_legs_select_column_rfq_legs_aggregate_bool_exp_covar_samp_arguments_columns;
};
export type rfq_legs_aggregate_bool_exp_max = {
  arguments: rfq_legs_select_column_rfq_legs_aggregate_bool_exp_max_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: rfq_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type rfq_legs_aggregate_bool_exp_min = {
  arguments: rfq_legs_select_column_rfq_legs_aggregate_bool_exp_min_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: rfq_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type rfq_legs_aggregate_bool_exp_stddev_samp = {
  arguments: rfq_legs_select_column_rfq_legs_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: rfq_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type rfq_legs_aggregate_bool_exp_sum = {
  arguments: rfq_legs_select_column_rfq_legs_aggregate_bool_exp_sum_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: rfq_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type rfq_legs_aggregate_bool_exp_var_samp = {
  arguments: rfq_legs_select_column_rfq_legs_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: rfq_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type rfq_options_bool_exp = {
  _and?: ReadonlyArray<rfq_options_bool_exp> | null | undefined;
  _not?: rfq_options_bool_exp | null | undefined;
  _or?: ReadonlyArray<rfq_options_bool_exp> | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  fixQuoteId?: String_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  reason?: enum_rfq_option_reason_enum_comparison_exp | null | undefined;
  rfq?: rfq_bool_exp | null | undefined;
  rfqId?: uuid_comparison_exp | null | undefined;
  rfq_options_legs?: rfq_options_legs_bool_exp | null | undefined;
  rfq_options_legs_aggregate?: rfq_options_legs_aggregate_bool_exp | null | undefined;
  side?: enum_quote_side_type_enum_comparison_exp | null | undefined;
  status?: enum_rfq_status_enum_comparison_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
  userId?: String_comparison_exp | null | undefined;
};
export type enum_rfq_option_reason_enum_comparison_exp = {
  _eq?: enum_rfq_option_reason_enum | null | undefined;
  _in?: ReadonlyArray<enum_rfq_option_reason_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_rfq_option_reason_enum | null | undefined;
  _nin?: ReadonlyArray<enum_rfq_option_reason_enum> | null | undefined;
};
export type rfq_options_legs_bool_exp = {
  _and?: ReadonlyArray<rfq_options_legs_bool_exp> | null | undefined;
  _not?: rfq_options_legs_bool_exp | null | undefined;
  _or?: ReadonlyArray<rfq_options_legs_bool_exp> | null | undefined;
  bsDelta?: bigint_comparison_exp | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  delta?: bigint_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  premium?: Int_comparison_exp | null | undefined;
  pricingVolatility?: float8_comparison_exp | null | undefined;
  rfqLegId?: uuid_comparison_exp | null | undefined;
  rfqOptionId?: uuid_comparison_exp | null | undefined;
  rfq_leg?: rfq_legs_bool_exp | null | undefined;
  rfq_option?: rfq_options_bool_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
};
export type rfq_options_legs_aggregate_bool_exp = {
  avg?: rfq_options_legs_aggregate_bool_exp_avg | null | undefined;
  corr?: rfq_options_legs_aggregate_bool_exp_corr | null | undefined;
  count?: rfq_options_legs_aggregate_bool_exp_count | null | undefined;
  covar_samp?: rfq_options_legs_aggregate_bool_exp_covar_samp | null | undefined;
  max?: rfq_options_legs_aggregate_bool_exp_max | null | undefined;
  min?: rfq_options_legs_aggregate_bool_exp_min | null | undefined;
  stddev_samp?: rfq_options_legs_aggregate_bool_exp_stddev_samp | null | undefined;
  sum?: rfq_options_legs_aggregate_bool_exp_sum | null | undefined;
  var_samp?: rfq_options_legs_aggregate_bool_exp_var_samp | null | undefined;
};
export type rfq_options_legs_aggregate_bool_exp_avg = {
  arguments: rfq_options_legs_select_column_rfq_options_legs_aggregate_bool_exp_avg_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: rfq_options_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type rfq_options_legs_aggregate_bool_exp_corr = {
  arguments: rfq_options_legs_aggregate_bool_exp_corr_arguments;
  distinct?: boolean | null | undefined;
  filter?: rfq_options_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type rfq_options_legs_aggregate_bool_exp_corr_arguments = {
  X: rfq_options_legs_select_column_rfq_options_legs_aggregate_bool_exp_corr_arguments_columns;
  Y: rfq_options_legs_select_column_rfq_options_legs_aggregate_bool_exp_corr_arguments_columns;
};
export type rfq_options_legs_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<rfq_options_legs_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: rfq_options_legs_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type rfq_options_legs_aggregate_bool_exp_covar_samp = {
  arguments: rfq_options_legs_aggregate_bool_exp_covar_samp_arguments;
  distinct?: boolean | null | undefined;
  filter?: rfq_options_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type rfq_options_legs_aggregate_bool_exp_covar_samp_arguments = {
  X: rfq_options_legs_select_column_rfq_options_legs_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: rfq_options_legs_select_column_rfq_options_legs_aggregate_bool_exp_covar_samp_arguments_columns;
};
export type rfq_options_legs_aggregate_bool_exp_max = {
  arguments: rfq_options_legs_select_column_rfq_options_legs_aggregate_bool_exp_max_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: rfq_options_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type rfq_options_legs_aggregate_bool_exp_min = {
  arguments: rfq_options_legs_select_column_rfq_options_legs_aggregate_bool_exp_min_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: rfq_options_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type rfq_options_legs_aggregate_bool_exp_stddev_samp = {
  arguments: rfq_options_legs_select_column_rfq_options_legs_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: rfq_options_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type rfq_options_legs_aggregate_bool_exp_sum = {
  arguments: rfq_options_legs_select_column_rfq_options_legs_aggregate_bool_exp_sum_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: rfq_options_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type rfq_options_legs_aggregate_bool_exp_var_samp = {
  arguments: rfq_options_legs_select_column_rfq_options_legs_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: rfq_options_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type enum_quote_side_type_enum_comparison_exp = {
  _eq?: enum_quote_side_type_enum | null | undefined;
  _in?: ReadonlyArray<enum_quote_side_type_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_quote_side_type_enum | null | undefined;
  _nin?: ReadonlyArray<enum_quote_side_type_enum> | null | undefined;
};
export type enum_rfq_status_enum_comparison_exp = {
  _eq?: enum_rfq_status_enum | null | undefined;
  _in?: ReadonlyArray<enum_rfq_status_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_rfq_status_enum | null | undefined;
  _nin?: ReadonlyArray<enum_rfq_status_enum> | null | undefined;
};
export type rfq_options_aggregate_bool_exp = {
  count?: rfq_options_aggregate_bool_exp_count | null | undefined;
};
export type rfq_options_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<rfq_options_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: rfq_options_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type trades_bool_exp = {
  _and?: ReadonlyArray<trades_bool_exp> | null | undefined;
  _not?: trades_bool_exp | null | undefined;
  _or?: ReadonlyArray<trades_bool_exp> | null | undefined;
  axeCustomerUserId?: String_comparison_exp | null | undefined;
  axeId?: uuid_comparison_exp | null | undefined;
  axeOwner?: users_bool_exp | null | undefined;
  axeOwnerUserId?: String_comparison_exp | null | undefined;
  axeOwner_aggregate?: users_aggregate_bool_exp | null | undefined;
  channel?: String_comparison_exp | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  customerSubOrganizationId?: uuid_comparison_exp | null | undefined;
  deltaHedge?: bigint_comparison_exp | null | undefined;
  fixQuoteId?: String_comparison_exp | null | undefined;
  fundId?: uuid_comparison_exp | null | undefined;
  giveupId?: uuid_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  notional?: bigint_comparison_exp | null | undefined;
  notionalAmountInEUR?: bigint_comparison_exp | null | undefined;
  notionalAmountInUSD?: bigint_comparison_exp | null | undefined;
  ownerOrganizationId?: String_comparison_exp | null | undefined;
  ownerSubOrganizationId?: uuid_comparison_exp | null | undefined;
  premiumType?: enum_axe_premiumtype_enum_comparison_exp | null | undefined;
  rfqId?: uuid_comparison_exp | null | undefined;
  side?: String_comparison_exp | null | undefined;
  traded_hedges?: traded_hedges_bool_exp | null | undefined;
  traded_hedges_aggregate?: traded_hedges_aggregate_bool_exp | null | undefined;
  trades_legs?: trades_leg_bool_exp | null | undefined;
  trades_legs_aggregate?: trades_leg_aggregate_bool_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
};
export type traded_hedges_bool_exp = {
  _and?: ReadonlyArray<traded_hedges_bool_exp> | null | undefined;
  _not?: traded_hedges_bool_exp | null | undefined;
  _or?: ReadonlyArray<traded_hedges_bool_exp> | null | undefined;
  ccy1?: String_comparison_exp | null | undefined;
  ccy2?: String_comparison_exp | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  deliveryDate?: date_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  isin?: String_comparison_exp | null | undefined;
  notionalAmountInEUR?: bigint_comparison_exp | null | undefined;
  notionalCcy1?: bigint_comparison_exp | null | undefined;
  notionalCcy2?: bigint_comparison_exp | null | undefined;
  rate?: float8_comparison_exp | null | undefined;
  trade?: trades_bool_exp | null | undefined;
  tradeId?: uuid_comparison_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
};
export type traded_hedges_aggregate_bool_exp = {
  avg?: traded_hedges_aggregate_bool_exp_avg | null | undefined;
  corr?: traded_hedges_aggregate_bool_exp_corr | null | undefined;
  count?: traded_hedges_aggregate_bool_exp_count | null | undefined;
  covar_samp?: traded_hedges_aggregate_bool_exp_covar_samp | null | undefined;
  max?: traded_hedges_aggregate_bool_exp_max | null | undefined;
  min?: traded_hedges_aggregate_bool_exp_min | null | undefined;
  stddev_samp?: traded_hedges_aggregate_bool_exp_stddev_samp | null | undefined;
  sum?: traded_hedges_aggregate_bool_exp_sum | null | undefined;
  var_samp?: traded_hedges_aggregate_bool_exp_var_samp | null | undefined;
};
export type traded_hedges_aggregate_bool_exp_avg = {
  arguments: traded_hedges_select_column_traded_hedges_aggregate_bool_exp_avg_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: traded_hedges_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type traded_hedges_aggregate_bool_exp_corr = {
  arguments: traded_hedges_aggregate_bool_exp_corr_arguments;
  distinct?: boolean | null | undefined;
  filter?: traded_hedges_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type traded_hedges_aggregate_bool_exp_corr_arguments = {
  X: traded_hedges_select_column_traded_hedges_aggregate_bool_exp_corr_arguments_columns;
  Y: traded_hedges_select_column_traded_hedges_aggregate_bool_exp_corr_arguments_columns;
};
export type traded_hedges_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<traded_hedges_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: traded_hedges_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type traded_hedges_aggregate_bool_exp_covar_samp = {
  arguments: traded_hedges_aggregate_bool_exp_covar_samp_arguments;
  distinct?: boolean | null | undefined;
  filter?: traded_hedges_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type traded_hedges_aggregate_bool_exp_covar_samp_arguments = {
  X: traded_hedges_select_column_traded_hedges_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: traded_hedges_select_column_traded_hedges_aggregate_bool_exp_covar_samp_arguments_columns;
};
export type traded_hedges_aggregate_bool_exp_max = {
  arguments: traded_hedges_select_column_traded_hedges_aggregate_bool_exp_max_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: traded_hedges_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type traded_hedges_aggregate_bool_exp_min = {
  arguments: traded_hedges_select_column_traded_hedges_aggregate_bool_exp_min_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: traded_hedges_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type traded_hedges_aggregate_bool_exp_stddev_samp = {
  arguments: traded_hedges_select_column_traded_hedges_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: traded_hedges_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type traded_hedges_aggregate_bool_exp_sum = {
  arguments: traded_hedges_select_column_traded_hedges_aggregate_bool_exp_sum_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: traded_hedges_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type traded_hedges_aggregate_bool_exp_var_samp = {
  arguments: traded_hedges_select_column_traded_hedges_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: traded_hedges_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type trades_leg_bool_exp = {
  _and?: ReadonlyArray<trades_leg_bool_exp> | null | undefined;
  _not?: trades_leg_bool_exp | null | undefined;
  _or?: ReadonlyArray<trades_leg_bool_exp> | null | undefined;
  buySell?: enum_axe_buysell_enum_comparison_exp | null | undefined;
  callPut?: enum_axe_callput_enum_comparison_exp | null | undefined;
  ccyPair?: enum_axe_ccypair_enum_comparison_exp | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  cut?: String_comparison_exp | null | undefined;
  deliveryDate?: date_comparison_exp | null | undefined;
  delta?: bigint_comparison_exp | null | undefined;
  expiryDate?: date_comparison_exp | null | undefined;
  forward?: float8_comparison_exp | null | undefined;
  hedgeType?: enum_axe_hedgetype_enum_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  notional?: bigint_comparison_exp | null | undefined;
  notionalCurrency?: enum_currency_notional_enum_comparison_exp | null | undefined;
  orderIndex?: Int_comparison_exp | null | undefined;
  premium?: Int_comparison_exp | null | undefined;
  premiumCurrency?: enum_currency_premium_enum_comparison_exp | null | undefined;
  premiumDate?: date_comparison_exp | null | undefined;
  pricingVolatility?: float8_comparison_exp | null | undefined;
  product?: enum_axe_product_enum_comparison_exp | null | undefined;
  spot?: float8_comparison_exp | null | undefined;
  spotDate?: date_comparison_exp | null | undefined;
  strike?: float8_comparison_exp | null | undefined;
  trade?: trades_bool_exp | null | undefined;
  tradeId?: uuid_comparison_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
};
export type trades_leg_aggregate_bool_exp = {
  avg?: trades_leg_aggregate_bool_exp_avg | null | undefined;
  corr?: trades_leg_aggregate_bool_exp_corr | null | undefined;
  count?: trades_leg_aggregate_bool_exp_count | null | undefined;
  covar_samp?: trades_leg_aggregate_bool_exp_covar_samp | null | undefined;
  max?: trades_leg_aggregate_bool_exp_max | null | undefined;
  min?: trades_leg_aggregate_bool_exp_min | null | undefined;
  stddev_samp?: trades_leg_aggregate_bool_exp_stddev_samp | null | undefined;
  sum?: trades_leg_aggregate_bool_exp_sum | null | undefined;
  var_samp?: trades_leg_aggregate_bool_exp_var_samp | null | undefined;
};
export type trades_leg_aggregate_bool_exp_avg = {
  arguments: trades_leg_select_column_trades_leg_aggregate_bool_exp_avg_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: trades_leg_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type trades_leg_aggregate_bool_exp_corr = {
  arguments: trades_leg_aggregate_bool_exp_corr_arguments;
  distinct?: boolean | null | undefined;
  filter?: trades_leg_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type trades_leg_aggregate_bool_exp_corr_arguments = {
  X: trades_leg_select_column_trades_leg_aggregate_bool_exp_corr_arguments_columns;
  Y: trades_leg_select_column_trades_leg_aggregate_bool_exp_corr_arguments_columns;
};
export type trades_leg_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<trades_leg_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: trades_leg_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type trades_leg_aggregate_bool_exp_covar_samp = {
  arguments: trades_leg_aggregate_bool_exp_covar_samp_arguments;
  distinct?: boolean | null | undefined;
  filter?: trades_leg_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type trades_leg_aggregate_bool_exp_covar_samp_arguments = {
  X: trades_leg_select_column_trades_leg_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: trades_leg_select_column_trades_leg_aggregate_bool_exp_covar_samp_arguments_columns;
};
export type trades_leg_aggregate_bool_exp_max = {
  arguments: trades_leg_select_column_trades_leg_aggregate_bool_exp_max_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: trades_leg_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type trades_leg_aggregate_bool_exp_min = {
  arguments: trades_leg_select_column_trades_leg_aggregate_bool_exp_min_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: trades_leg_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type trades_leg_aggregate_bool_exp_stddev_samp = {
  arguments: trades_leg_select_column_trades_leg_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: trades_leg_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type trades_leg_aggregate_bool_exp_sum = {
  arguments: trades_leg_select_column_trades_leg_aggregate_bool_exp_sum_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: trades_leg_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type trades_leg_aggregate_bool_exp_var_samp = {
  arguments: trades_leg_select_column_trades_leg_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: trades_leg_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type rfq_options_obj_rel_insert_input = {
  data: rfq_options_insert_input;
  on_conflict?: rfq_options_on_conflict | null | undefined;
};
export type rfq_options_on_conflict = {
  constraint: rfq_options_constraint;
  update_columns?: ReadonlyArray<rfq_options_update_column>;
  where?: rfq_options_bool_exp | null | undefined;
};
export type rfq_options_legs_on_conflict = {
  constraint: rfq_options_legs_constraint;
  update_columns?: ReadonlyArray<rfq_options_legs_update_column>;
  where?: rfq_options_legs_bool_exp | null | undefined;
};
export type rfq_options_arr_rel_insert_input = {
  data: ReadonlyArray<rfq_options_insert_input>;
  on_conflict?: rfq_options_on_conflict | null | undefined;
};
export type trades_obj_rel_insert_input = {
  data: trades_insert_input;
  on_conflict?: trades_on_conflict | null | undefined;
};
export type trades_insert_input = {
  axeCustomerUserId?: string | null | undefined;
  axeId?: any | null | undefined;
  axeOwner?: users_arr_rel_insert_input | null | undefined;
  axeOwnerUserId?: string | null | undefined;
  channel?: string | null | undefined;
  createdAt?: any | null | undefined;
  customerSubOrganizationId?: any | null | undefined;
  deltaHedge?: any | null | undefined;
  fixQuoteId?: string | null | undefined;
  fundId?: any | null | undefined;
  giveupId?: any | null | undefined;
  id?: any | null | undefined;
  notional?: any | null | undefined;
  notionalAmountInEUR?: any | null | undefined;
  notionalAmountInUSD?: any | null | undefined;
  ownerOrganizationId?: string | null | undefined;
  ownerSubOrganizationId?: any | null | undefined;
  premiumType?: enum_axe_premiumtype_enum | null | undefined;
  rfqId?: any | null | undefined;
  side?: string | null | undefined;
  traded_hedges?: traded_hedges_arr_rel_insert_input | null | undefined;
  trades_legs?: trades_leg_arr_rel_insert_input | null | undefined;
  updatedAt?: any | null | undefined;
};
export type traded_hedges_arr_rel_insert_input = {
  data: ReadonlyArray<traded_hedges_insert_input>;
};
export type traded_hedges_insert_input = {
  ccy1?: string | null | undefined;
  ccy2?: string | null | undefined;
  createdAt?: any | null | undefined;
  deliveryDate?: any | null | undefined;
  id?: any | null | undefined;
  isin?: string | null | undefined;
  notionalAmountInEUR?: any | null | undefined;
  notionalCcy1?: any | null | undefined;
  notionalCcy2?: any | null | undefined;
  rate?: any | null | undefined;
  trade?: trades_obj_rel_insert_input | null | undefined;
  tradeId?: any | null | undefined;
  updatedAt?: any | null | undefined;
};
export type trades_leg_arr_rel_insert_input = {
  data: ReadonlyArray<trades_leg_insert_input>;
  on_conflict?: trades_leg_on_conflict | null | undefined;
};
export type trades_leg_insert_input = {
  buySell?: enum_axe_buysell_enum | null | undefined;
  callPut?: enum_axe_callput_enum | null | undefined;
  ccyPair?: enum_axe_ccypair_enum | null | undefined;
  createdAt?: any | null | undefined;
  cut?: string | null | undefined;
  deliveryDate?: any | null | undefined;
  delta?: any | null | undefined;
  expiryDate?: any | null | undefined;
  forward?: any | null | undefined;
  hedgeType?: enum_axe_hedgetype_enum | null | undefined;
  id?: any | null | undefined;
  notional?: any | null | undefined;
  notionalCurrency?: enum_currency_notional_enum | null | undefined;
  orderIndex?: number | null | undefined;
  premium?: number | null | undefined;
  premiumCurrency?: enum_currency_premium_enum | null | undefined;
  premiumDate?: any | null | undefined;
  pricingVolatility?: any | null | undefined;
  product?: enum_axe_product_enum | null | undefined;
  spot?: any | null | undefined;
  spotDate?: any | null | undefined;
  strike?: any | null | undefined;
  trade?: trades_obj_rel_insert_input | null | undefined;
  tradeId?: any | null | undefined;
  updatedAt?: any | null | undefined;
};
export type trades_leg_on_conflict = {
  constraint: trades_leg_constraint;
  update_columns?: ReadonlyArray<trades_leg_update_column>;
  where?: trades_leg_bool_exp | null | undefined;
};
export type trades_on_conflict = {
  constraint: trades_constraint;
  update_columns?: ReadonlyArray<trades_update_column>;
  where?: trades_bool_exp | null | undefined;
};
export type rfq_on_conflict = {
  constraint: rfq_constraint;
  update_columns?: ReadonlyArray<rfq_update_column>;
  where?: rfq_bool_exp | null | undefined;
};
export type rfq_updates = {
  _inc?: rfq_inc_input | null | undefined;
  _set?: rfq_set_input | null | undefined;
  where: rfq_bool_exp;
};
export type rfq_inc_input = {
  deltaHedge?: any | null | undefined;
  notional?: any | null | undefined;
};
export type rfq_set_input = {
  axeCustomerUserId?: string | null | undefined;
  axeId?: any | null | undefined;
  axeOwnerUserId?: string | null | undefined;
  channel?: string | null | undefined;
  createdAt?: any | null | undefined;
  customerOrganizationId?: string | null | undefined;
  customerSubOrganizationId?: any | null | undefined;
  deltaHedge?: any | null | undefined;
  expireAt?: any | null | undefined;
  fundId?: any | null | undefined;
  giveupId?: any | null | undefined;
  id?: any | null | undefined;
  notional?: any | null | undefined;
  ownerOrganizationId?: string | null | undefined;
  ownerSubOrganizationId?: any | null | undefined;
  premiumType?: enum_axe_premiumtype_enum | null | undefined;
  side?: enum_quote_side_type_enum | null | undefined;
  status?: enum_rfq_status_enum | null | undefined;
  updatedAt?: any | null | undefined;
};
export type rfq_public_queue_bool_exp = {
  _and?: ReadonlyArray<rfq_public_queue_bool_exp> | null | undefined;
  _not?: rfq_public_queue_bool_exp | null | undefined;
  _or?: ReadonlyArray<rfq_public_queue_bool_exp> | null | undefined;
  axeId?: uuid_comparison_exp | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  rfqId?: uuid_comparison_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
};
export type clearExpiredRfqsMutation$variables = {
  rfqOptions: ReadonlyArray<rfq_options_insert_input>;
  rfqPublicQueue: ReadonlyArray<rfq_public_queue_bool_exp>;
  rfqs: ReadonlyArray<rfq_updates>;
};
export type clearExpiredRfqsMutation$data = {
  readonly delete_rfq_public_queue: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
  readonly insert_rfq_options: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
  readonly update_rfq_many: ReadonlyArray<{
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined> | null | undefined;
};
export type clearExpiredRfqsMutation = {
  response: clearExpiredRfqsMutation$data;
  variables: clearExpiredRfqsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rfqOptions"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rfqPublicQueue"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rfqs"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "rfqOptions"
      }
    ],
    "concreteType": "rfq_options_mutation_response",
    "kind": "LinkedField",
    "name": "insert_rfq_options",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "rfq_options",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "updates",
        "variableName": "rfqs"
      }
    ],
    "concreteType": "rfq_mutation_response",
    "kind": "LinkedField",
    "name": "update_rfq_many",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "rfq",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_or",
            "variableName": "rfqPublicQueue"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "rfq_public_queue_mutation_response",
    "kind": "LinkedField",
    "name": "delete_rfq_public_queue",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "rfq_public_queue",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "clearExpiredRfqsMutation",
    "selections": (v4/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "clearExpiredRfqsMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "a09ba73ca9a78c30df284271549531cf",
    "id": null,
    "metadata": {},
    "name": "clearExpiredRfqsMutation",
    "operationKind": "mutation",
    "text": "mutation clearExpiredRfqsMutation(\n  $rfqOptions: [rfq_options_insert_input!]!\n  $rfqs: [rfq_updates!]!\n  $rfqPublicQueue: [rfq_public_queue_bool_exp!]!\n) {\n  insert_rfq_options(objects: $rfqOptions) {\n    returning {\n      id\n    }\n  }\n  update_rfq_many(updates: $rfqs) {\n    returning {\n      id\n    }\n  }\n  delete_rfq_public_queue(where: {_or: $rfqPublicQueue}) {\n    returning {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b946674a7ba0f1f491e392eb2b143b7c";

export default node;

export default function Loader() {
  return (
    <section
      data-testid="loader"
      className="loader-logo-background"
      role="progressbar"
      aria-label="Loading animation"
    >
      <figure className="loader-logo-container-wrapper">
        <div className="loader-logo-container">
          <div className="loader-white-lines line-1" />
          <div className="loader-white-lines line-2" />
          <div className="loader-blue-lines line-1" />
          <div className="loader-blue-lines line-2" />
        </div>
      </figure>
    </section>
  )
}

/* eslint-disable relay/unused-fields */
import {
  ArrowLongLeftIcon,
  ArrowUturnDownIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/20/solid'
import * as Yup from 'yup'
import { ValidationError } from 'yup'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ButtonGroup from '../../buttonGroup'
import Button, { ButtonSize, ButtonVariant } from '../../buttons/button/button'
import Numeric from '../../input/numeric/numeric'
import Search, { SearchVariant } from '../../search/search'
import TierSelectorContextProvider, {
  OrganizationState,
  useTierSelectorContext
} from '../../tierSelector/hooks/tierSelectorContext'
import TierDisclosure from '../../tierSelector/tierDisclosure/tierDisclosure'
import { RemovedTierDisclosure, Tier } from '../../tierSelector/tierSelector'
import { useField, useFormikContext } from 'formik'
import React from 'react'
import InfoLink from '../../infoLink/infoLink'
import { PricingVolatility } from '../../input/input'
import { Entity } from '../../tierSelector/tierList/tierList'
import { AxeFormData } from '../../dialog/updateAxeDialog/updateAxeDialog'
import { tierPricingSchema } from '../../buttons/createAxeDialogButton/schema.yup'
import classNames from 'classnames'
import AnimatedFieldStateIcon, {
  FieldStateIconPosition,
  FieldStateIconVariant
} from '../../fieldStateIcon/fieldStateIcon'
import { PreloadedQuery } from 'react-relay'
import useSubOrgUserData from '../../tierSelector/hooks/useSubOrgUserData'
import { useSubOrgUserDataSubOrganizationUsersQuery } from '../../tierSelector/hooks/__generated__/useSubOrgUserDataSubOrganizationUsersQuery.graphql'

export enum TierNames {
  Tier1 = 'Tier 1',
  Tier2 = 'Tier 2',
  Tier3 = 'Tier 3'
}

const legPrefix = 'legs[0]'

function getPricingVolFieldName(index: number) {
  return (`${legPrefix}.pricingVolatility` +
    (index === 0 ? '' : 'T' + (index + 1))) as PricingVolatility
}

type FieldWarningKey = keyof Yup.InferType<typeof tierPricingSchema>

type FieldWarnings = Partial<Record<FieldWarningKey, string>>

interface CreateAxeTiersProps {
  disabled: boolean
  handleClick: (removed: string[], submitAction?: boolean) => void
  backAction: () => void
  tierData: Tier[]
  removedSubOrgs: Entity[]
  subOrgUsersQueryRef: PreloadedQuery<useSubOrgUserDataSubOrganizationUsersQuery>
}

export default function CreateAxeTiers({
  disabled,
  handleClick,
  backAction,
  tierData,
  removedSubOrgs,
  subOrgUsersQueryRef
}: CreateAxeTiersProps) {
  const buildEntityWithUserData = useSubOrgUserData(subOrgUsersQueryRef)
  const tiersWithUsers: Tier[] = tierData.map((tier) => {
    const entities = buildEntityWithUserData(tier.entities)

    return {
      ...tier,
      entities
    }
  })

  const { values } = useFormikContext<AxeFormData>()
  const [tierLists, setTiersLists] = React.useState<Tier[]>(tiersWithUsers)
  const [tierQuery, setTierQuery] = React.useState<string>('')
  const [fieldWarnings, setFieldWarnings] = React.useState<FieldWarnings>({})

  // Populate pricing tier fields with values from the first leg.
  const [pricingVolatility, , pricingVolatilityHelper] = useField<number>(
    `${legPrefix}.pricingVolatility`
  )
  const [volatility] = useField<number>(`${legPrefix}.volatility`)
  const [buySell] = useField<string>(`${legPrefix}.buySell`)

  // Values pricingVolatilityT2 and pricingVolatilityT3 are not saved in the Axe table
  // They are used used in AxeTierPricing
  const [pricingVolatilityT2, , pricingVolatilityT2Helper] = useField<number>(
    `${legPrefix}.pricingVolatilityT2`
  )
  const [pricingVolatilityT3, , pricingVolatilityT3Helper] = useField<number>(
    `${legPrefix}.pricingVolatilityT3`
  )

  const midVol = (volatility.value || '').toString() || '-'

  React.useEffect(() => {
    if (!pricingVolatilityT2.value) {
      pricingVolatilityT2Helper.setValue(pricingVolatility.value || 0)
    }

    if (!pricingVolatilityT3.value) {
      pricingVolatilityT3Helper.setValue(pricingVolatility.value || 0)
    }
  }, [
    pricingVolatilityT3.value,
    pricingVolatilityT2.value,
    pricingVolatilityT3Helper,
    pricingVolatilityT2Helper,
    pricingVolatility.value,
    buySell.value
  ])

  const pricingVolFields = [
    pricingVolatility.value ? Number(pricingVolatility.value).toFixed(2) : '',
    pricingVolatilityT2.value
      ? Number(pricingVolatilityT2.value).toFixed(2)
      : '',
    pricingVolatilityT3.value
      ? Number(pricingVolatilityT3.value).toFixed(2)
      : ''
  ]

  const validatePricingVolatility = React.useCallback(() => {
    const leg1 = values.legs[0]

    try {
      tierPricingSchema.validateSync(leg1, { abortEarly: false })

      setFieldWarnings({})
    } catch (err) {
      const errors = (err as ValidationError).inner.reduce<FieldWarnings>(
        (acc, error) => {
          if (error.path) {
            acc[`${legPrefix}.${error.path}` as FieldWarningKey] = error.message
          }

          return acc
        },
        {}
      )

      setFieldWarnings(errors)
    }
  }, [values.legs])

  React.useEffect(() => {
    validatePricingVolatility()
  }, [validatePricingVolatility])

  return (
    <DndProvider debugMode backend={HTML5Backend}>
      <TierSelectorContextProvider
        tiers={tierLists}
        removedSubOrgs={removedSubOrgs}
      >
        <div className="flex flex-col gap-5 mb-6">
          <div className="gap-4 overflow-y-auto max-h-[60vh] -mx-3 px-3">
            <div className="grid grid-cols-11 gap-4 mt-5">
              <div className="col-span-8">
                <Search
                  onInputChange={(event) => {
                    setTierQuery(event.target.value)
                  }}
                  name="search"
                  variant={SearchVariant.Tier}
                  placeholder={'Search all groups/clients'}
                  value={tierQuery}
                />
              </div>
              <div className="col-span-3 -ml-[6px]">
                <Button
                  size={ButtonSize.SMALL}
                  styleVariant={ButtonVariant.CLEAR}
                  className="text-sm flex "
                  onClick={() => {
                    pricingVolatilityHelper.setValue(parseFloat(midVol))
                    pricingVolatilityT2Helper.setValue(parseFloat(midVol))
                    pricingVolatilityT3Helper.setValue(parseFloat(midVol))
                  }}
                >
                  <ArrowUturnDownIcon width={'15px'} />
                  <span className="pl-2 cursor-pointer font-normal">
                    Reset to mid ({midVol}%)
                  </span>
                </Button>
              </div>
            </div>
            {tierLists.map((tier, index) => {
              const fieldName = getPricingVolFieldName(index)
              const fieldWarning = fieldWarnings[fieldName]

              return (
                <div className="grid grid-cols-11 gap-4 mt-5" key={index}>
                  <div className="col-span-8" data-testid={`tier-${index}`}>
                    <TierDisclosure
                      {...tier}
                      setTiers={setTiersLists}
                      tierId={tier.id}
                      key={tier.id}
                      query={tierQuery}
                    />
                  </div>
                  <div
                    className={classNames(
                      'col-span-3 rounded-xl p-1 h-[37.5px] relative',
                      fieldWarning
                        ? 'bg-processYellow'
                        : 'bg-lightGray dark:bg-lightBlue'
                    )}
                  >
                    <Numeric
                      key={tier.id}
                      max={100}
                      min={0}
                      name={fieldName}
                      suffix="%"
                      value={pricingVolFields[index].toString() || ''}
                      step={0.1}
                      type="number"
                      onValueChange={validatePricingVolatility}
                    />
                    <AnimatedFieldStateIcon
                      message={fieldWarning}
                      position={FieldStateIconPosition.RIGHT}
                      variant={FieldStateIconVariant.WARNING}
                    />
                  </div>
                </div>
              )
            })}
          </div>
          <div className="grid grid-cols-11 gap-4 mt-1">
            <span className="col-span-8">
              <RemovedTierDisclosure tiers={tierLists} />
            </span>
          </div>
          <div className="flex justify-center">
            {values.legs.length > 1 && (
              <p className="text-processYellow dark:text-processYellowDarkMode text-sm font-semibold flex items-center gap-2">
                <ExclamationTriangleIcon className="h-[24px] w-[24px]" />
                <span>
                  Pricing Volatility set here will only apply to leg 1
                </span>
              </p>
            )}
          </div>
        </div>
        <CreateAxeTierButtons
          backAction={backAction}
          handleClick={handleClick}
          disabled={disabled}
          tierLists={tierLists}
        />
        <InfoLink
          wrapperClassName="flex font-bold"
          linkText="What is this?"
          popOverText="This is some help text"
          anchor="right"
        />
      </TierSelectorContextProvider>
    </DndProvider>
  )
}

const getUpdatedSubOrgIds = (removed: OrganizationState[]) => {
  const updatedSubOrgIdsHelpers: string[] = []

  removed.forEach((entity) => {
    entity.entities.forEach((subOrganizationState) => {
      updatedSubOrgIdsHelpers.push(subOrganizationState.id)
    })
  })

  return updatedSubOrgIdsHelpers
}

function CreateAxeTierButtons({
  backAction,
  handleClick,
  disabled,
  tierLists
}: {
  backAction: () => void
  handleClick: (removed: string[], submitAction?: boolean) => void
  disabled: boolean
  tierLists: Tier[]
}) {
  const [, , tiersHelpers] = useField<Tier[]>('tiers')
  const [, , removedSubOrgIdsHelpers] = useField<string[]>('removedSubOrgIds')

  const { submitForm } = useFormikContext()
  const {
    state: { removed }
  } = useTierSelectorContext()

  return (
    <div className="flex justify-between items-center pb-2 cursor-pointer">
      <span className="flex font-normal " onClick={backAction}>
        <ArrowLongLeftIcon width={'20px'} className="mr-2" />
        Back
      </span>

      <ButtonGroup
        className="justify-end"
        buttons={[
          <Button
            key="cancel"
            type="reset"
            onClick={() => {
              handleClick(getUpdatedSubOrgIds(removed))
            }}
            styleVariant={ButtonVariant.SECONDARY}
          >
            Cancel
          </Button>,
          <Button
            key="submitForm"
            type="submit"
            disabled={disabled}
            onClick={async () => {
              const updatedSubOrgIdsHelpers = getUpdatedSubOrgIds(removed)
              await tiersHelpers.setValue(tierLists)
              await removedSubOrgIdsHelpers.setValue(updatedSubOrgIdsHelpers)
              await submitForm()
              handleClick(updatedSubOrgIdsHelpers, true)
            }}
          >
            Submit
          </Button>
        ]}
      />
    </div>
  )
}

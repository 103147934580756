import { InputProps } from '../../../../input/input'
import isEmpty from 'lodash/isEmpty'
import set from 'lodash/set'
import { AxeFormData } from '../../../../dialog/updateAxeDialog/updateAxeDialog'
import { OnValuesDiffParams } from '../../config'
import findMatchingDateIndexes from '../processOnExpiryDateDiff/utils/findMatchingDateIndexes/findMatchingDateIndexes'
import isLegFieldPath from '../../../utils/isLegFieldPath/isLegFieldPath'
import { getRowValues } from '../../../../../utils/axeCalculations/axeCalculations'

type SetForwardAndSwapsSameForSameExpiryLegsParams = {
  formik: { values: OnValuesDiffParams['formik']['values'] }
  inputStateContext: OnValuesDiffParams['inputStateContext']
  path: OnValuesDiffParams['path']
}

/**
 * Identify and handle matching expiry dates. For any matches found, disable the swaps and forward fields and set them
 * to match the source leg values. Update the state to ensure these fields align with the source leg values whenever
 * changes are made elsewhere. All overtyped and disabled states are managed accordingly.
 */
export function setForwardAndSwapsSameForSameExpiryLegs({
  formik: { values },
  inputStateContext: { dispatch, state },
  path
}: SetForwardAndSwapsSameForSameExpiryLegsParams) {
  if (!isLegFieldPath(path)) return null

  const rowValues = getRowValues(values.legs, ['expiryDate'])

  if (!rowValues.expiryDate || rowValues.expiryDate.length <= 1) return null

  const valuesToUpdate = {} as AxeFormData
  const [arrayName] = path

  const matchingDatesIndexes = findMatchingDateIndexes(rowValues.expiryDate)
  const sourceLegIndex = matchingDatesIndexes[0]
  const sourceLegValues = values.legs[sourceLegIndex]
  const targetLegIndexes = matchingDatesIndexes.filter(
    (index) => index !== sourceLegIndex
  )

  const updateTargetInputOverTypedState = (
    fieldName: 'forward' | 'swaps',
    index: number
  ) => {
    const isSourceOverTyped = Boolean(
      state[`${arrayName}[${sourceLegIndex}].${fieldName}`]?.overTyped
    )

    const key = `${arrayName}[${index}].${fieldName}` as InputProps['name']

    if (isSourceOverTyped) {
      dispatch({
        type: 'set_over_typed',
        key,
        payload: sourceLegValues[fieldName]
      })
      dispatch({ type: 'set_contains_fetched_value', key })
    } else {
      dispatch({ type: 'unset_reset_request', key })
    }
  }

  /**
   * Make bulk state updates across swaps and forward fields based on matching dates. If matching dates are found,
   * disable the swaps and forward fields for the target legs and set the target fields to track future updates from the
   * source leg. In all other cases, ensure the swaps and forward fields are enabled, and the target fields for the
   * source leg are unset.
   */
  const stateUpdates = values.legs.map((_, index) => ({
    isDisabled: targetLegIndexes.includes(index),
    hasTargetFields: index === sourceLegIndex,
    targetFields: index === sourceLegIndex ? targetLegIndexes : []
  }))

  stateUpdates.forEach((stateUpdate, index) => {
    const { isDisabled, hasTargetFields, targetFields } = stateUpdate
    const forwardName = `${arrayName}[${index}].forward` as InputProps['name']
    const swapsName = `${arrayName}[${index}].swaps` as InputProps['name']

    if (hasTargetFields) {
      const forwardTargetFields = targetFields.map((legIndex) => ({
        name: `${arrayName}[${legIndex}].forward`,
        legIndex
      }))
      const swapsTargetFields = targetFields.map((legIndex) => ({
        name: `${arrayName}[${legIndex}].swaps`,
        legIndex
      }))

      dispatch({
        type: 'set_target_fields',
        key: forwardName,
        payload: forwardTargetFields
      })
      dispatch({
        type: 'set_target_fields',
        key: swapsName,
        payload: swapsTargetFields
      })
    } else {
      dispatch({ type: 'unset_target_fields', key: forwardName })
      dispatch({ type: 'unset_target_fields', key: swapsName })
    }

    if (isDisabled) {
      dispatch({ type: 'set_is_disabled', key: forwardName })
      dispatch({ type: 'set_is_disabled', key: swapsName })
    } else {
      dispatch({ type: 'unset_is_disabled', key: forwardName })
      dispatch({ type: 'unset_is_disabled', key: swapsName })
    }
  })

  if (isEmpty(matchingDatesIndexes)) {
    return null
  }

  /**
   * If there are matching dates, set the swaps and forward values for the target legs to match those of the source leg.
   * Note that this is done here to ensure the swaps and forward values are updated when the expiry date changes. Any
   * further changes to spot, swaps, and forward values will be managed by the associated configuration.
   */
  targetLegIndexes.forEach((index) => {
    set(valuesToUpdate, [arrayName, index, 'forward'], sourceLegValues.forward)
    set(valuesToUpdate, [arrayName, index, 'swaps'], sourceLegValues.swaps)

    // Dispatch actions to set or unset this leg's forward/swaps as overtyped, based on whether the source leg forward/swaps is overtyped.
    updateTargetInputOverTypedState('forward', index)
    updateTargetInputOverTypedState('swaps', index)
  })

  return {
    values: valuesToUpdate
  }
}

import { Entity } from '../../tierList/tierList'
import { includesQuery } from '../includesQuery/includesQuery'

export const entityIncludesQuery = (
  entity: Entity,
  query?: string
): boolean => {
  if (!query) return false

  const isIncludedInQuery = includesQuery(query)

  return (
    isIncludedInQuery(entity.name) ||
    entity.members.some((member) => isIncludedInQuery(member.name)) ||
    entity.members.some((member) =>
      member.users?.some((user) => isIncludedInQuery(user.name))
    )
  )
}

import deepDiff from 'deep-diff'
import isNil from 'lodash/isNil'
import { FieldArrayFieldName, InputProps } from '../../../../../input/input'

/**
 * Returns an array of keys derived from the path of a deep-diff `diff` object.
 */
function getDiffKeys(valuesDiff: deepDiff.Diff<unknown, unknown>[] | null) {
  if (!valuesDiff) return []

  return valuesDiff.reduce<InputProps['name'][]>((acc, diff) => {
    const [legIndex, name] = diff.path || []

    if (isNil(legIndex) || isNil(name)) return acc

    acc.push(`legs[${legIndex}].${name}` as FieldArrayFieldName)

    return acc
  }, [])
}

export default getDiffKeys

import React from 'react'
import ComboBox, { ComboBoxProps } from '../comboBox'
import { useField } from 'formik'
import { usePrevious } from 'react-use'
import fixes, { FixCurrencies } from '../../../utils/fixes/fixes'
import { InputSelectOption } from '../../../types/inputSelect'
import generateFieldName from '../../../utils/generateFieldName/generateFieldName'
import useFieldSync from '../../../hooks/useFieldSync/useFieldSync'
import { InputVariant } from '../../input/input'

const FixComboBox = ({ fieldArrayPrefix, ...props }: ComboBoxProps) => {
  const [ccyPairField] = useField(
    generateFieldName('ccyPair', fieldArrayPrefix)
  )
  const [options, setOptions] = React.useState(props.options)
  const previousCcyPair = usePrevious(ccyPairField.value)

  React.useEffect(() => {
    if (!ccyPairField.value || ccyPairField.value === previousCcyPair) return

    const ccyPairFixes = fixes[ccyPairField.value as FixCurrencies]
    let options: InputSelectOption[] = []

    if (!ccyPairFixes) {
      options = [
        {
          name: 'Error: No fixes found for this currency pair',
          value: 'error'
        }
      ]
    } else {
      options = ccyPairFixes.map((fix: string) => ({
        name: fix,
        value: fix
      }))
    }

    setOptions([...options])
  }, [ccyPairField.value, options, previousCcyPair])

  const { isDisabled } = useFieldSync(props.name)

  return (
    <ComboBox
      {...props}
      options={options}
      disabled={props.disabled || isDisabled}
      variant={InputVariant.CELL}
    />
  )
}

export default FixComboBox

import { InputProps } from '../../../../input/input'
import isNil from 'lodash/isNil'
import { LegFieldPath } from '../../../utils/isLegFieldPath/isLegFieldPath'
import { OnValuesDiffParams } from '../../config'
import { setPartyBasedValue } from '../setPartyBasedValue/setPartyBasedValue'

type PartialOnValuesDiffParams = Pick<
  OnValuesDiffParams,
  'formik' | 'inputStateContext' | 'path' | 'isUserCounterParty'
>

export default function processOnDeltaOrPremiumDiff(
  suffixCb: (
    partyBasedValue: number,
    onValuesDiffParams: Omit<PartialOnValuesDiffParams, 'path'> & {
      path: LegFieldPath
    }
  ) => string,
  onValuesDiffParams: PartialOnValuesDiffParams
) {
  const { inputStateContext, path } = onValuesDiffParams

  // Override the delta or premium value if the user viewing the form is the counterparty.
  const partyBasedValue = setPartyBasedValue(onValuesDiffParams)

  if (isNil(partyBasedValue)) return null

  // Append the suffix to the delta or premium. As the calculations differ across the two fields, this is a callback.
  const suffix = suffixCb(partyBasedValue, {
    ...onValuesDiffParams,
    path: onValuesDiffParams.path as LegFieldPath
  })

  const [arrayName, legIndex, name] = path
  const key = `${arrayName}[${legIndex}].${name}` as InputProps['name']

  inputStateContext.dispatch({ type: 'set_suffix', key, payload: suffix })

  // We're not actually updating any Formik values, so we return null regardless.
  return null
}

import classNames from 'classnames'
import { Field } from 'formik'

const DialogInput = ({
  name,
  label,
  disabled,
  placeholder,
  className,
  error
}: {
  name: string
  label: string
  disabled?: boolean
  placeholder: string
  className?: string
  error?: string
  defaultValue?: string
}) => {
  return (
    <div className={classNames(className, 'flex flex-col')}>
      <label htmlFor={name}>{label}</label>
      <Field
        disabled={disabled}
        id={name}
        name={name}
        placeholder={placeholder}
        className={classNames(
          {
            'dark:border-darkBlueGray/40 dark:bg-darkBlueGray/40 disabled:text-textLightGray/80 disabled:cursor-not-allowed':
              disabled
          },
          'rounded-lg mt-3 border-borderGray text-sm dark:border-darkBlueGray dark:bg-darkBlueGray'
        )}
      />
      {error && <div className="text-red mt-2">{error}</div>}
    </div>
  )
}

export default DialogInput

'use client'

import { useUser } from '@auth0/nextjs-auth0/client'
import { SunIcon, MoonIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { useQueryLoader } from 'react-relay'
import { useColorSchemeContext } from '../../context/colorSchemeContext/colorSchemeContext'
import Button, { ButtonVariant } from '../buttons/button/button'

import { QuickAxeGetUserQuery, QuickAxeButton } from './quickaxe/quickAxe'
import { quickAxeGetUserQuery } from './quickaxe/__generated__/quickAxeGetUserQuery.graphql'
import { useEnvVariablesContext } from '../../context/variablesContext/variablesContext'
import { hasOptaxeAdminRole } from '../../utils/roles/roles'
import ClearExpiredRfqsButton from './clearExpiredRfqs/clearExpiredRfqs'

const ToggleDarkMode = () => {
  const { colorScheme, handleDarkModeChange } = useColorSchemeContext()
  const darkMode = colorScheme === 'DARK'

  return (
    <Button
      className="flex justify-center rounded-lg px-2 h-[44px] items-center"
      key="quickAxeButton"
      styleVariant={ButtonVariant.PRIMARY}
      onClick={handleDarkModeChange}
    >
      {!darkMode && (
        <SunIcon className="flex" height="24px" width="24px" color="white" />
      )}
      {darkMode && (
        <MoonIcon className="flex" height="24px" width="24px" color="white" />
      )}
    </Button>
  )
}

export default function DebugModeComponent() {
  const { user, isLoading } = useUser()
  const variablesContext = useEnvVariablesContext()

  const [queryRef, loadQuery] =
    useQueryLoader<quickAxeGetUserQuery>(QuickAxeGetUserQuery)

  const isOptAxeAdmin = hasOptaxeAdminRole(
    user,
    variablesContext?.rolesKey || ''
  )
  const userId = user?.sub || ''
  React.useEffect(() => {
    if (!isLoading && user?.email) {
      loadQuery({ email: user.email, userId })
    }
  }, [user?.email, loadQuery, isLoading, userId])

  return (
    <div className="bg-pathGray rounded-xl dark:bg-deepBlue flex flex-col gap-4 p-2 w-[61px]">
      <span className="flex justify-center rounded-lg px-2 h-[44px] items-center">
        🐛
      </span>
      <ClearExpiredRfqsButton />
      {queryRef && (
        <QuickAxeButton
          queryRef={queryRef}
          currency={'EURGBP'}
          disable={isOptAxeAdmin}
          userId={userId}
        />
      )}
      {queryRef && (
        <QuickAxeButton
          queryRef={queryRef}
          multiLeg
          disable={isOptAxeAdmin}
          userId={userId}
        />
      )}
      {queryRef && (
        <QuickAxeButton
          queryRef={queryRef}
          disable={isOptAxeAdmin}
          userId={userId}
        />
      )}
      <ToggleDarkMode />
    </div>
  )
}

import { Entity } from '../../tierList/tierList'
import { includesQuery } from '../includesQuery/includesQuery'
import { entityIncludesQuery } from '../entityIncludesQuery/entityIncludesQuery'

/**
 * Returns an array of entities (orgs) with the entity open if the members (sub-orgs) contain the query
 * string, or if the users in the sub-orgs contain the query string. The entity members are filtered to only
 * include members (or users) that contain the query string.
 */

export const queriedEntities = (
  entities: Entity[],
  query?: string
): Entity[] => {
  return entities.reduce((acc: Entity[], tier): Entity[] => {
    const isIncludedInQuery = includesQuery(query)

    if (entityIncludesQuery(tier, query)) {
      const membersThatContainQuery = tier.members.filter(
        (member) =>
          isIncludedInQuery(member.name) ||
          member.users?.some((user) => isIncludedInQuery(user.name))
      )
      const entityNameContainsQuery = isIncludedInQuery(tier.name)
      const containsQuery =
        !entityNameContainsQuery && membersThatContainQuery.length > 0

      return [
        ...acc,
        {
          ...tier,
          open: true,
          members: containsQuery
            ? tier.members
                .filter(
                  (member) =>
                    isIncludedInQuery(member.name) ||
                    member.users?.some((user) => isIncludedInQuery(user.name))
                )
                .map((member) => ({
                  ...member,
                  users:
                    member.users?.filter((user) => isIncludedInQuery(user.name))
                      .length === 0
                      ? member.users
                      : member.users?.filter((user) =>
                          isIncludedInQuery(user.name)
                        )
                }))
            : tier.members
        }
      ]
    }

    return [
      ...acc,
      {
        ...tier,
        open: false
      }
    ]
  }, [])
}

import { FieldHelperProps, FieldInputProps } from 'formik'
import { NumberFormatValues, NumericFormat } from 'react-number-format'
import React from 'react'
import classNames from 'classnames'
import { tableClassNames } from '../../../table/table'

export interface NumericBaseProps {
  disabled: boolean | undefined
  refreshable: boolean | undefined
  placeholder: string | undefined
  field: FieldInputProps<number | string>
  max: number | string | undefined
  min: number | string | undefined
  suffix: string | undefined
  prefix: string | undefined
  decimalScale: number | undefined
  onValueChange: (values: NumberFormatValues) => void
  onFocus: ((event: React.FocusEvent<HTMLInputElement>) => void) | undefined
  onBlur: ((event: React.FocusEvent<HTMLInputElement>) => void) | undefined
  onChange: (e: React.SyntheticEvent<HTMLOrSVGElement>) => void
  allowed: ({ floatValue }: NumberFormatValues) => boolean
  thousandSeparator?: string
  helpers?: FieldHelperProps<number>
}

export function NumericBase({
  disabled,
  allowed,
  field,
  max,
  min,
  refreshable,
  onBlur,
  onValueChange,
  onFocus,
  suffix,
  onChange,
  prefix,
  decimalScale,
  placeholder
}: NumericBaseProps) {
  const inputRef = React.useRef<HTMLInputElement>(null)

  return (
    <NumericFormat
      className={classNames(
        'w-full border-none text-[12px] p-0 disabled:text-textLightGray disabled:cursor-not-allowed',
        'group-[.is-updated]:disabled:text-textGray group-[.is-updated]:disabled:dark:text-white',
        tableClassNames.cellHeight,
        tableClassNames.cellPadLeft,
        { 'bg-transparent': !disabled },
        {
          [`${tableClassNames.cellPadRight} ${tableClassNames.summaryCell}`]:
            disabled
        },
        { [tableClassNames.cellControlPad]: refreshable }
      )}
      placeholder={placeholder}
      thousandSeparator=","
      displayType="input"
      valueIsNumericString
      id={field.name}
      max={max}
      min={min}
      suffix={suffix}
      prefix={prefix}
      decimalScale={decimalScale}
      {...field}
      getInputRef={inputRef}
      onValueChange={onValueChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      disabled={disabled}
      isAllowed={allowed}
    />
  )
}

import {
  PlusIcon,
  RocketLaunchIcon,
  CurrencyPoundIcon
} from '@heroicons/react/20/solid'
import {
  PreloadedQuery,
  usePreloadedQuery,
  useMutation,
  useFragment
} from 'react-relay'
import { graphql } from 'relay-runtime'
import Button, { ButtonVariant } from '../../buttons/button/button'
import {
  CreateAxeDialogButtonTiersFragment,
  createAxeMutation,
  parseAxeLegValues,
  parseCreateAxeValues
} from '../../buttons/createAxeDialogButton/createAxeDialogButton'
import { createAxeDialogButtonMutation } from '../../buttons/createAxeDialogButton/__generated__/createAxeDialogButtonMutation.graphql'
import { AxeFormLegData } from '../../dialog/updateAxeDialog/updateAxeDialog'
import { quickAxeGetUserQuery } from './__generated__/quickAxeGetUserQuery.graphql'
import { Tier } from '../../tierSelector/tierSelector'
import { v4 as uuidv4 } from 'uuid'
import { createAxePricingTiers } from '../../../utils/createAxePricingTiers/createAxePricingTiers'
import { getTierData } from '../../../app/counterparties/components/userTierComponent'
import { createAxeDialogButtonTiersFragment$key } from '../../buttons/createAxeDialogButton/__generated__/createAxeDialogButtonTiersFragment.graphql'
import { omitNonAxeSpecificFormFields } from '../../../utils/omitNonAxeSpecificFormFields/omitNonAxeSpecificFormFields'
import { eurGBPQuickAxeLegDataLeg, quickAxeLegData } from './quickAxeLegData'
import { createAxeUserTiersForAxe } from '../../../utils/createAxeUserTiersForAxe/createAxeUserTiersForAxe'

export const QuickAxeGetUserQuery = graphql`
  query quickAxeGetUserQuery($email: String!, $userId: String!) {
    users_connection(where: { email: { _eq: $email } }) {
      edges {
        node {
          id
          # eslint-disable-next-line relay/unused-fields
          organizationId
          subOrganizationId
        }
      }
    }
    user_main_tiering_connection(where: { ownerId: { _eq: $userId } }) {
      ...createAxeDialogButtonTiersFragment
    }
  }
`

export function QuickAxeButton({
  multiLeg,
  queryRef,
  currency,
  disable,
  userId
}: {
  multiLeg?: boolean
  currency?: string
  readonly queryRef: PreloadedQuery<quickAxeGetUserQuery>
  disable: boolean
  userId?: string
}) {
  /**
   * The mutations and queries here are only to allow us to test the app quickly
   */
  const data = usePreloadedQuery<quickAxeGetUserQuery>(
    QuickAxeGetUserQuery,
    queryRef
  )

  const orgDataForTiers = useFragment(
    CreateAxeDialogButtonTiersFragment,
    data.user_main_tiering_connection as createAxeDialogButtonTiersFragment$key
  )

  const tiers: Tier[] = getTierData(orgDataForTiers.edges)

  const [commitMutation] =
    useMutation<createAxeDialogButtonMutation>(createAxeMutation)

  const removedEdges = orgDataForTiers.edges.filter(
    (edge) => edge.node.isRemoved
  )
  const removedSubOrgIds =
    removedEdges.map((edge) => edge.node.subOrganizationId) ?? []

  return (
    <Button
      className="flex justify-center rounded-lg px-2 h-[44px] items-center relative"
      key="quickAxeButton"
      styleVariant={ButtonVariant.PRIMARY}
      disabled={disable}
      onClick={() => {
        const axeId = uuidv4()
        const leg1Values = quickAxeLegData.legs[0] as AxeFormLegData

        const axeValues = parseCreateAxeValues({
          axeAuthor: data.users_connection.edges[0]?.node.id || '',
          axeAuthorSubOrganizationId:
            data.users_connection.edges[0]?.node.subOrganizationId || '',
          axeAuthorOrganizationId:
            data.users_connection.edges[0]?.node.organizationId || '',
          id: axeId
        })
        let axeLegs = []

        if (multiLeg) {
          axeLegs = quickAxeLegData.legs.map((leg) => {
            return omitNonAxeSpecificFormFields(parseAxeLegValues(leg))
          })
        } else if (currency === 'EURGBP') {
          axeLegs = [
            omitNonAxeSpecificFormFields(
              parseAxeLegValues(eurGBPQuickAxeLegDataLeg)
            )
          ]
        } else {
          axeLegs = [
            omitNonAxeSpecificFormFields(parseAxeLegValues(leg1Values))
          ]
        }
        commitMutation({
          variables: {
            axe: {
              ...axeValues,
              axe_legs: {
                data: axeLegs
              }
            },
            axePricingTiers: createAxePricingTiers(
              axeId,
              leg1Values.pricingVolatility,
              quickAxeLegData.pricingVolatilityT2 ??
                leg1Values.pricingVolatility,
              quickAxeLegData.pricingVolatilityT3 ??
                leg1Values.pricingVolatility,
              tiers
            ),
            userTiers: createAxeUserTiersForAxe(
              axeId,
              userId || '',
              tiers,
              removedSubOrgIds
            )
          },
          onError: (err) => {
            console.error(err)
          }
        })
      }}
    >
      <RocketLaunchIcon
        className="flex"
        height="24px"
        width="24px"
        color="white"
      />
      {multiLeg && (
        <PlusIcon
          className="absolute right-1 bottom-1"
          height="12px"
          width="12px"
          color="white"
        />
      )}
      {currency && (
        <CurrencyPoundIcon
          className="absolute right-1 bottom-1"
          height="12px"
          width="12px"
          color="white"
        />
      )}
    </Button>
  )
}

import React from 'react'
import { Tier } from '../tierSelector'
import { deleteProperty, getProperty, setProperty } from 'dot-prop'
import { Entity } from '../tierList/tierList'

export enum EntityType {
  TIER = 'tier',
  ORGANIZATION = 'ORGANIZATION',
  SUB_ORGANIZATION = 'SUB_ORGANIZATION'
}

type EntityState = {
  id: string
  checked: boolean
  idMap: string
}

export interface SubOrganizationState extends EntityState {
  type: EntityType.SUB_ORGANIZATION
}

export interface OrganizationState extends EntityState {
  type: EntityType.ORGANIZATION
  entities: SubOrganizationState[]
  open: boolean
}

export interface TierState extends Omit<EntityState, 'checked'> {
  type: EntityType.TIER
  entities: OrganizationState[]
}

export interface TierSelectorState {
  tiers: TierState[]
  removed: OrganizationState[]
}

export type ReducerCheckedAction = {
  type: 'update'
  payload: {
    id: string
    checked: boolean
  }
}

export type ReducerOpenAction = {
  type: 'open_entity'
  payload: {
    id: string
    open: boolean
  }
}

export type ReducerRefreshAction = {
  type: 'refresh_tiers'
  payload: {
    tiers: Tier[]
  }
}

export type ReducerAction =
  | ReducerCheckedAction
  | ReducerRefreshAction
  | ReducerOpenAction

interface TierSelectorContextProps {
  state: TierSelectorState
  dispatch: React.Dispatch<ReducerAction>
}

export const TierSelectorContext =
  React.createContext<TierSelectorContextProps>({
    state: {
      tiers: [],
      removed: []
    },
    dispatch: () => undefined
  })

export const useTierSelectorContext = () =>
  React.useContext(TierSelectorContext)

type ArrayGroupTypes =
  | Array<TierState>
  | Array<OrganizationState>
  | Array<SubOrganizationState>

type SingularGroupTypes = TierState | OrganizationState | SubOrganizationState

type GroupTypes = ArrayGroupTypes | SingularGroupTypes

const isTierState = (group: SingularGroupTypes): group is TierState =>
  group.type === EntityType.TIER
const isOrganizationState = (
  group: SingularGroupTypes
): group is OrganizationState => group.type === EntityType.ORGANIZATION
const isSubOrganizationState = (
  group: SingularGroupTypes
): group is SubOrganizationState => group.type === EntityType.SUB_ORGANIZATION

const allSubMembersHaveSameState = (
  checked: boolean,
  id: string,
  groups: OrganizationState
) =>
  groups.entities.every((entity) => {
    // we want to check if all the children are checked, including the id from the function argument
    return entity.checked === checked || entity.id === id
  })

export const recursivelyUpdateCheckedState = (
  groups: GroupTypes,
  id: string,
  checked: boolean,
  setCheckedStateAndUpdateRemovedArray: (
    path: string,
    checked: boolean,
    allSubMembersHaveTheSameStatus?: boolean
  ) => void
): GroupTypes => {
  if (Array.isArray(groups)) {
    return groups.map((group) =>
      recursivelyUpdateCheckedState(
        group,
        id,
        checked,
        setCheckedStateAndUpdateRemovedArray
      )
    ) as
      | Array<TierState>
      | Array<OrganizationState>
      | Array<SubOrganizationState>
  }

  if (isSubOrganizationState(groups)) {
    const isChecked = groups.id === id

    if (isChecked) {
      setCheckedStateAndUpdateRemovedArray(groups.idMap, checked)
    }

    return {
      ...groups,
      checked: isChecked ? checked : groups.checked
    }
  }

  if (isOrganizationState(groups)) {
    const isChecked = groups.id === id

    const allSubMembersHaveTheSameStatus = allSubMembersHaveSameState(
      checked,
      id,
      groups
    )

    if (isChecked && !allSubMembersHaveTheSameStatus) {
      setCheckedStateAndUpdateRemovedArray(groups.idMap, checked)
    } else if (allSubMembersHaveTheSameStatus) {
      setCheckedStateAndUpdateRemovedArray(
        groups.idMap,
        checked,
        allSubMembersHaveTheSameStatus
      )
    }

    return {
      ...groups,
      checked:
        isChecked || allSubMembersHaveTheSameStatus ? checked : groups.checked,
      entities:
        groups.id === id
          ? groups.entities.map((entity) => ({ ...entity, checked }))
          : (recursivelyUpdateCheckedState(
              groups.entities,
              id,
              checked,
              setCheckedStateAndUpdateRemovedArray
            ) as Array<SubOrganizationState>)
    }
  }

  if (isTierState(groups)) {
    return {
      ...groups,
      entities: recursivelyUpdateCheckedState(
        groups.entities,
        id,
        checked,
        setCheckedStateAndUpdateRemovedArray
      ) as Array<OrganizationState>
    }
  }

  return groups
}

/**
 * Recursively update the idMaps using dot notation
 * @param obj
 * @param path
 */
const updateNestedIdMap = (
  obj: TierState | OrganizationState | SubOrganizationState,
  path: string
): TierState | OrganizationState | SubOrganizationState => {
  if (isTierState(obj) || isOrganizationState(obj)) {
    return {
      ...obj,
      idMap: path,
      entities: obj.entities.map((entity, index) =>
        updateNestedIdMap(entity, `${path}.entities[${index}]`)
      )
    } as TierState | OrganizationState
  }

  return {
    ...obj,
    idMap: path
  }
}

/**
 * Returns object that contains the id
 * if it is a child node, then the top level parent is returned, with the filtered children
 * @param removed
 * @param id
 */
const findPathOfNestedObjWithId = (
  removed: Array<OrganizationState>,
  id: string
): undefined | string => {
  // using UUIDs, so we can do a quick check to see if the id is even in the array
  const stringified = JSON.stringify(removed)

  // if the id is found, then lets do the expensive tree traversal
  if (stringified.includes(id)) {
    const checkIfItsAPod = removed.find((pod) => pod.id === id)
    if (checkIfItsAPod) return checkIfItsAPod.idMap

    for (const pod of removed) {
      const entity = pod.entities.find((entity) => entity.id === id)

      if (entity) {
        return entity.idMap
      }
    }
  }
}

/**
 * Remove 'empty' values after deletion, and then rebuild the idMaps
 * @param removed
 */
export const removeEmptyValuesFromRemovedState = (
  removed: Array<OrganizationState>
): Array<OrganizationState> => {
  return removed
    .filter((pod: OrganizationState) => {
      if (pod) return true
    })
    .map((pod: OrganizationState, podIndex) => {
      const entityIdMap = pod.idMap.replace(
        /removed\[\d]/,
        `removed[${podIndex.toString()}]`
      )

      return {
        ...pod,
        idMap: entityIdMap,
        entities: pod.entities
          .filter((user) => {
            if (user) return true
          })
          .map((entity, entityIndex) => ({
            ...entity,
            idMap: `${entityIdMap}.${entity.idMap
              .slice(entityIdMap.length + 1)
              .replace(/entities\[\d]/, `entities[${entityIndex.toString()}]`)}`
          }))
      }
    })
}

const getParentObj = (
  state: TierSelectorState,
  idMap: string
): OrganizationState | undefined => {
  const paths = idMap.split('.')

  return getProperty(state, `${paths[0]}.${paths[1]}`)
}

const addFullNestedObject = (
  state: TierSelectorState,
  obj: OrganizationState | SubOrganizationState
) => {
  if (isOrganizationState(obj)) {
    return obj
  }

  // if the user state, get the pod and filter the entities to get the one we want only
  const stateObj = getParentObj(state, obj.idMap)

  if (stateObj) {
    return {
      ...stateObj,
      entities: [obj]
    }
  }
}

export const updateRemoved =
  (state: TierSelectorState) =>
  (
    idMap: string,
    checked: boolean,
    allSubMembersHaveTheSameStatus?: boolean
  ): void => {
    // use the path of the required obj to pull the object from state
    let stateObject: undefined | OrganizationState | SubOrganizationState =
      getProperty(state, idMap)

    // now we need to check if the id already exists in the removed array
    if (stateObject) {
      stateObject = stateObject as OrganizationState | SubOrganizationState
      const pathOfRemoved = findPathOfNestedObjWithId(
        state.removed,
        stateObject.id
      )

      // if the nodePath exists, then find the id and remove the associated object
      if (checked && pathOfRemoved) {
        deleteProperty(state, pathOfRemoved)
        // once deleted, the array location is "empty", so filter these out
        state.removed = removeEmptyValuesFromRemovedState(state.removed)

        return
      }

      // if the nodePath isn't found, check whether there is a pod to push the entity into
      // so get the id of the parent object and check whether it is in the removed array
      const obj = getParentObj(state, stateObject.idMap)
      const parentNodePath =
        obj && findPathOfNestedObjWithId(state.removed, obj.id)

      if (!checked && parentNodePath) {
        let existingProperty: undefined | OrganizationState = getProperty(
          state,
          parentNodePath
        )
        if (!existingProperty) return

        existingProperty = existingProperty as OrganizationState

        if (!isOrganizationState(stateObject)) {
          // update the idMap so that we can find and update easily
          const objWithUpdatedIdMap = updateNestedIdMap(
            stateObject,
            `${parentNodePath}.entities[${existingProperty.entities.length}]`
          )
          setProperty(
            state,
            `${parentNodePath}.entities[${existingProperty.entities.length}]`,
            objWithUpdatedIdMap
          )
        } else if (
          isOrganizationState(stateObject) &&
          !allSubMembersHaveTheSameStatus
        ) {
          // update the idMap so that we can find and update easily
          const objWithUpdatedIdMap = updateNestedIdMap(
            stateObject,
            parentNodePath
          )
          setProperty(state, parentNodePath, objWithUpdatedIdMap)
        }

        return
      }

      // if not found, then add the whole nested object to array
      if (!checked && !pathOfRemoved) {
        const nestedObject = addFullNestedObject(state, stateObject)
        // update the idMap so that we can find and update easily
        const objWithUpdatedIdMap = updateNestedIdMap(
          nestedObject as OrganizationState,
          `removed[${state.removed.length}]`
        )
        // update idMaps starting with location in array
        state.removed.push(objWithUpdatedIdMap as OrganizationState)
      }
    }
  }

const tierSelectorReducer = (
  state: TierSelectorState,
  action: ReducerAction
): TierSelectorState => {
  switch (action.type) {
    case 'open_entity': {
      const tiers = state.tiers.map((tier) => {
        return {
          ...tier,
          entities: tier.entities.map((entity) => {
            if (entity.id === action.payload.id) {
              return {
                ...entity,
                open: action.payload.open
              }
            }

            return entity
          })
        }
      })

      return {
        ...state,
        tiers
      }
    }

    case 'update': {
      const updateRemovedArray = updateRemoved(state)

      const tiers = recursivelyUpdateCheckedState(
        state.tiers,
        action.payload.id,
        action.payload.checked,
        updateRemovedArray
      ) as Array<TierState>

      return {
        ...state,
        tiers,
        removed: state.removed
      }
    }

    case 'refresh_tiers': {
      const tiers = action.payload.tiers.map((tier, tierIndex) => ({
        id: tier.id,
        type: EntityType.TIER,
        idMap: `tiers[${tierIndex}]`,
        entities: tier.entities.map((payloadEntity, entityIndex) => {
          const idMap = `tiers[${tierIndex}].entities[${entityIndex}]`
          const resultingEntity: OrganizationState = {
            id: payloadEntity.id,
            checked: false,
            open: false,
            type: EntityType.ORGANIZATION,
            entities: [],
            idMap: idMap
          }

          // We compare the current state with the new one.
          // We need to compare the users across all tiers and all entities.
          // As an entities users might be spread across multiple tiers.
          state.tiers.forEach((tier) => {
            tier.entities.forEach((stateEntity) => {
              // org comparison
              if (payloadEntity.id === stateEntity.id) {
                resultingEntity.checked = stateEntity.checked || false
                resultingEntity.open = stateEntity.open || false

                stateEntity.entities.forEach((user) => {
                  const isUserInNewState = payloadEntity.members.find(
                    (member) => member.id === user.id
                  )

                  if (isUserInNewState) {
                    const proposedIdMap = `tiers[${tierIndex}].entities[${entityIndex}].entities[${resultingEntity.entities.length}]`

                    resultingEntity.entities.push({
                      ...user,
                      idMap: proposedIdMap
                    })
                  }
                })
              }
            })
          })

          return resultingEntity
        })
      }))

      return {
        tiers,
        removed: state.removed
      } as TierSelectorState
    }
    default:
      return state
  }
}

export const buildInitialStateFromTiers = (
  tiers: Tier[],
  removed: Entity[]
): TierSelectorState => {
  return {
    tiers: tiers.map((tier, tierIndex) => ({
      id: tier.id,
      type: EntityType.TIER,
      idMap: `tiers[${tierIndex}]`,
      entities: tier.entities.map((entity, entityIndex) => {
        const isEntityRemoved = entity.members.every(
          (member) => member.isRemoved && member.isRemoved === true
        )

        return {
          id: entity.id,
          checked: !isEntityRemoved,
          open: Boolean(entity.open),
          type: EntityType.ORGANIZATION,
          idMap: `tiers[${tierIndex}].entities[${entityIndex}]`,
          entities: entity.members.map((member, memberIndex) => ({
            id: member.id,
            type: EntityType.SUB_ORGANIZATION,
            checked: !(member.isRemoved && member.isRemoved === true),
            idMap: `tiers[${tierIndex}].entities[${entityIndex}].entities[${memberIndex}]`
          }))
        }
      })
    })),
    removed: removed.map((entity, entityIndex) => ({
      id: entity.id,
      checked: false,
      open: Boolean(entity.open),
      type: EntityType.ORGANIZATION,
      idMap: `removed[${entityIndex}]`,
      entities: entity.members.map((member, memberIndex) => ({
        id: member.id,
        type: EntityType.SUB_ORGANIZATION,
        checked: false,
        idMap: `removed[${entityIndex}].entities[${memberIndex}]`
      }))
    }))
  }
}

export default function TierSelectorContextProvider({
  children,
  tiers,
  removedSubOrgs
}: React.PropsWithChildren<{
  tiers?: Tier[]
  removedSubOrgs: Entity[]
}>) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [state, dispatch] = React.useReducer(
    tierSelectorReducer,
    buildInitialStateFromTiers(tiers || [], removedSubOrgs || [])
  )

  return (
    <TierSelectorContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      {children}
    </TierSelectorContext.Provider>
  )
}

'use client'
import { UserProvider, useUser } from '@auth0/nextjs-auth0/client'
import {
  graphql,
  PreloadedQuery,
  RelayEnvironmentProvider,
  usePreloadedQuery,
  useQueryLoader
} from 'react-relay'
import React from 'react'
import ColorSchemeForwardRef from '../../components/colorSchemeForwardRef/colorSchemeForwardRef'
import ColorSchemeContextProvider from '../../context/colorSchemeContext/colorSchemeContext'
import { clientSideLayoutQuery } from './__generated__/clientSideLayoutQuery.graphql'
import classNames from 'classnames'
import { getCurrentEnvironment } from '../../gql/relayEnvironment'
import { Toaster } from 'react-hot-toast'
import DebugModeComponent from '../../components/debug/debugComponent'
import { AnalyticsProvider } from '../../context/analyticsContext/analyticsContext'
import NotificationsProvider from '../../context/notificationsWorkerContext/notificationsWorkerContext'
import useSWR from 'swr'
import { fetcher } from '../../utils/fetcher'
import { EnvVariablesContextProvider } from '../../context/variablesContext/variablesContext'
import ConfirmLeavePageDialog from '../../components/dialog/confirmLeavePageDialog/confirmLeavePageDialog'
import { urlState } from './topLevelLeavePageConfirmation'
import { useRouter } from 'next/navigation'
import { NavigationContextProvider } from '../../context/navigationContext/navigationContext'
import Loader from './loader'

const ClientSideLayoutQuery = graphql`
  query clientSideLayoutQuery($id: String!) {
    users_connection(where: { id: { _eq: $id } }) {
      ...colorSchemeForwardRefFragment
      ...colorSchemeContextFragment
    }
  }
`

function QueryProvider({
  children,
  rootRef
}: React.PropsWithChildren<{
  rootRef: React.RefObject<HTMLElement>
}>) {
  const { user } = useUser()
  const [queryRef, loadQuery] = useQueryLoader<clientSideLayoutQuery>(
    ClientSideLayoutQuery
  )

  React.useEffect(() => {
    if (user?.sub) {
      loadQuery({ id: user.sub })
    }
  }, [user?.sub, loadQuery])

  if (!queryRef) {
    return (
      <div className="bg-white dark:bg-deepBlue dark:text-white h-full">
        {children}
      </div>
    )
  }

  return (
    <RootWithData rootRef={rootRef} queryRef={queryRef}>
      {children}
    </RootWithData>
  )
}

interface RootWithDataProps {
  queryRef: PreloadedQuery<clientSideLayoutQuery>
  rootRef: React.RefObject<HTMLElement>
}

export const RootWithData: React.FC<
  React.PropsWithChildren<RootWithDataProps>
> = ({ children, queryRef, rootRef }) => {
  const divRef = React.useRef(null)
  const data = usePreloadedQuery<clientSideLayoutQuery>(
    ClientSideLayoutQuery,
    queryRef
  )

  return (
    <ColorSchemeContextProvider
      divRef={divRef}
      rootRef={rootRef}
      data={data.users_connection}
    >
      <ColorSchemeForwardRef
        ref={divRef}
        data={data.users_connection}
        rootRef={rootRef}
      >
        {children}
      </ColorSchemeForwardRef>
    </ColorSchemeContextProvider>
  )
}

export default function ClientSideLayout({
  children,
  theme,
  isDialogOpen = false,
  setIsDialogOpen
}: React.PropsWithChildren<{
  theme: string | undefined
  isDialogOpen: boolean
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
}>) {
  const router = useRouter()

  const { data, isLoading } = useSWR('/api/variables', fetcher)
  const rootRef = React.useRef(null)

  if (isLoading) {
    return (
      <main ref={rootRef} className={classNames({ dark: theme !== 'LIGHT' })}>
        <Loader />
      </main>
    )
  }

  const environment = getCurrentEnvironment(data?.relayHttp, data?.relayWs)

  return (
    <main ref={rootRef} className={classNames({ dark: theme !== 'LIGHT' })}>
      <RelayEnvironmentProvider environment={environment}>
        <UserProvider>
          <QueryProvider rootRef={rootRef}>
            <AnalyticsProvider>
              <NotificationsProvider>
                <NavigationContextProvider>
                  <EnvVariablesContextProvider
                    variables={{
                      rolesKey: data?.rolesKey,
                      relayHttp: data?.relayHttp,
                      appUrl: data?.appUrl
                    }}
                  >
                    {children}
                    {process.env.NEXT_PUBLIC_LOCAL_DEBUG === 'true' && (
                      <div className="fixed bottom-[71px] right-4">
                        <DebugModeComponent />
                      </div>
                    )}
                    <Toaster position="bottom-right" reverseOrder={false} />
                    <ConfirmLeavePageDialog
                      open={isDialogOpen}
                      onConfirm={() => {
                        urlState.isDirty = false
                        router.push(urlState.currentUrl)
                        setIsDialogOpen(false)
                      }}
                      onCancel={() => {
                        history.forward()
                        setIsDialogOpen(false)
                      }}
                      setOpen={() => {
                        setIsDialogOpen(false)
                      }}
                    />
                  </EnvVariablesContextProvider>
                </NavigationContextProvider>
              </NotificationsProvider>
            </AnalyticsProvider>
          </QueryProvider>
        </UserProvider>
      </RelayEnvironmentProvider>
    </main>
  )
}

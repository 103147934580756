import React, { createContext } from 'react'

interface ContextVariables {
  rolesKey: string
  relayHttp: string
  appUrl: string
}
interface ContextProviderProps {
  variables: ContextVariables
}

const VariablesContext = createContext<ContextVariables | null>(null)

export const useEnvVariablesContext = () => React.useContext(VariablesContext)

export const EnvVariablesContextProvider = ({
  children,
  variables
}: React.PropsWithChildren<ContextProviderProps>) => {
  return (
    <VariablesContext.Provider value={{ ...variables }}>
      {children}
    </VariablesContext.Provider>
  )
}

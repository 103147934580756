import { useQueryLoader } from 'react-relay'
import { useMemo, useEffect } from 'react'
import { userTierComponentQuery$data } from '../../../app/counterparties/components/__generated__/userTierComponentQuery.graphql'
import { useSubOrgUserDataQuery } from './useSubOrgUserData'
import { useSubOrgUserDataSubOrganizationUsersQuery } from './__generated__/useSubOrgUserDataSubOrganizationUsersQuery.graphql'
import { updateAxeDialogQuery$data } from '../../dialog/updateAxeDialog/__generated__/updateAxeDialogQuery.graphql'

const useSubOrgUserRef = (
  tierData:
    | userTierComponentQuery$data['user_main_tiering_connection']
    | updateAxeDialogQuery$data['user_tiers_connection']
) => {
  const [subOrgUsersQueryRef, loadSubOrgUsersQuery] =
    useQueryLoader<useSubOrgUserDataSubOrganizationUsersQuery>(
      useSubOrgUserDataQuery
    )

  const subOrganizationUUIDs = useMemo(
    () => tierData.edges.map(({ node }) => node.subOrganizationId),
    [tierData]
  )

  useEffect(() => {
    if (subOrganizationUUIDs.length > 0) {
      loadSubOrgUsersQuery({ subOrganizationUUIDs })
    }
  }, [loadSubOrgUsersQuery, subOrganizationUUIDs])

  return subOrgUsersQueryRef
}

export default useSubOrgUserRef

import { useFormikContext } from 'formik'
import { useInputStateContext } from '../../../../context/inputStateContext/inputStateContext'
import { FormDataProcessorConfig } from '../../config/config'
import processFieldConfig from '../../utils/processFieldConfig/processFieldConfig'
import { FormData } from '../../config/config'
import merge from 'lodash/merge'
import { AxeFormData } from '../../../dialog/updateAxeDialog/updateAxeDialog'
import { FormDataProcessorFormType } from '../../formDataProcessor'
import { rfqAxeDialog_axeFragment$data } from '../../../dialog/rfqAxeDialog/__generated__/rfqAxeDialog_axeFragment.graphql'

const useProcessFieldConfig = (
  config: FormDataProcessorConfig,
  formType?: FormDataProcessorFormType,
  axeData?: rfqAxeDialog_axeFragment$data
) => {
  const formik = useFormikContext<FormData>()
  const inputStateContext = useInputStateContext()

  return (path: unknown[] | undefined, latestValues?: Partial<AxeFormData>) => {
    return processFieldConfig({
      config,
      formik: merge({}, formik, {
        values: latestValues
      }),
      inputStateContext,
      path,
      formType,
      axeData
    })
  }
}

export default useProcessFieldConfig

import isNil from 'lodash/isNil'

export function getDecimalPointAndZeros(str: string | undefined | null) {
  const hasDecimalPoint = str?.includes('.')

  // If the string is null, undefined, or lacks a decimal point, return an empty string
  if (isNil(str) || !hasDecimalPoint) return ''

  let suffix = ''

  // Iterate through the string in reverse
  for (let i = str.length - 1; i >= 0; i--) {
    const char = str[i]

    if (char === '0') {
      // If character is zero, we add it to the suffix.
      suffix = '0' + suffix
    } else if (char === '.') {
      // If character is a decimal point, we add it to the suffix and then exit the loop, returning the suffix.
      return '.' + suffix
    } else {
      // If the character is neither a zero nor a decimal point, exit the loop and return the accumulated suffix.
      return suffix
    }
  }

  return suffix
}

import { InputProps, SummaryName } from '../../components/input/input'

export type ReducerAction = {
  type:
    | 'reset'
    | 'set_over_typed'
    | 'set_reset_request'
    | 'set_reset_request_all'
    | 'unset_reset_request'
    | 'set_contains_fetched_value'
    | 'set_to_changing'
    | 'unset_from_changing'
    | 'set_warning'
    | 'unset_warning'
    | 'set_is_disabled'
    | 'unset_is_disabled'
    | 'set_target_fields'
    | 'unset_target_fields'
    | 'set_updated_value'
    | 'unset_updated_value'
    | 'set_suffix'
    | 'set_party_based_value'
  key: InputProps['name'] | 'all' | SummaryName
  payload?: string | number | TargetField[] | null
}

export type TargetField = {
  name: string
  legIndex: number
}

export type InputState = {
  overTyped?: boolean
  overTypedValue?: string | number
  resetRequest?: boolean
  containsFetchedValue?: boolean
  updatesDisabled?: boolean
  changing?: boolean
  warning?: boolean
  warningMessage?: string
  isDisabled?: boolean
  targetFields?: TargetField[] | null
  updatedValue?: boolean
  suffix?: string
  partyBasedValue?: string | number
}

export type InputStateReducerState = {
  [key: string]: InputState
}

export default function inputStateReducer(
  prevState: InputStateReducerState,
  action: ReducerAction
): InputStateReducerState {
  const key = action.key

  switch (action.type) {
    case 'set_target_fields':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          targetFields: action.payload as TargetField[]
        }
      }
    case 'unset_target_fields':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          targetFields: null
        }
      }
    case 'set_is_disabled':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          isDisabled: true
        }
      }
    case 'unset_is_disabled':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          isDisabled: false
        }
      }
    case 'set_over_typed':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          overTyped: true,
          overTypedValue: action.payload as string
        }
      }
    case 'set_warning':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          warning: true,
          warningMessage: action.payload as string
        }
      }
    case 'unset_warning':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          warning: false,
          warningMessage: undefined
        }
      }
    case 'set_reset_request':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          resetRequest: true
        }
      }
    case 'set_reset_request_all':
      return Object.keys(prevState).reduce(
        (acc, key) => {
          if (prevState[key].overTyped) {
            acc[key] = {
              ...prevState[key],
              resetRequest: true
            }
          }

          return acc
        },
        { ...prevState }
      )
    case 'unset_reset_request':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          resetRequest: false,
          overTyped: false,
          overTypedValue: undefined
        }
      }
    case 'set_contains_fetched_value':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          containsFetchedValue: true
        }
      }
    case 'set_to_changing':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          changing: true
        }
      }
    case 'unset_from_changing':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          changing: false
        }
      }
    case 'set_updated_value':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          updatedValue: true
        }
      }
    case 'unset_updated_value':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          updatedValue: undefined
        }
      }
    case 'set_suffix':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          suffix: action.payload as string
        }
      }
    case 'set_party_based_value':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          partyBasedValue: action.payload as string | number
        }
      }
    case 'reset':
      return {}
    default:
      throw Error('Unknown action.')
  }
}

import React from 'react'
import {
  Popover as HeadlessUiPopover,
  PopoverPanel,
  Transition,
  PopoverButton
} from '@headlessui/react'
import classNames from 'classnames'
import { AnchorProps } from '@headlessui/react/dist/internal/floating'
import { useColorSchemeContext } from '../../context/colorSchemeContext/colorSchemeContext'

export type GenericPopOverProps = {
  button?: React.ReactNode
  anchor?: AnchorProps
  portal?: boolean
  wrapperClassName?: string
  buttonClassName?: string
  panelClassName?: string
  variant?: Variant
  buttonAs?: React.ElementType
  openOnLoad?: boolean
}

export enum Variant {
  DEFAULT = 'default',
  ERROR = 'error',
  INFO = 'info'
}

export default function PopOver({
  button,
  /**
   * Setting the anchor property will automatically render the popover in a portal
   * and position it relative to the anchor element.
   */
  anchor,
  portal,
  wrapperClassName,
  buttonClassName,
  panelClassName,
  variant = Variant.DEFAULT,
  buttonAs = 'button',
  children,
  openOnLoad
}: React.PropsWithChildren & GenericPopOverProps) {
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  // We need to use the colorScheme context as the popover is rendered
  // in a React.Portal outside of `main` which sets the theme class
  const { colorScheme } = useColorSchemeContext()

  React.useEffect(() => {
    if (buttonRef.current && openOnLoad) {
      buttonRef.current.click()
    }
  }, [openOnLoad])

  return (
    <HeadlessUiPopover
      className={classNames(wrapperClassName, 'flex items-center relative')}
    >
      <PopoverButton ref={buttonRef} as={buttonAs} className={buttonClassName}>
        {button}
      </PopoverButton>
      <Transition
        as="div"
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        className="z-50"
      >
        <PopoverPanel
          className={classNames(
            { dark: colorScheme !== 'LIGHT' },
            'shadow-2xl z-30 [--anchor-gap:4px]'
          )}
          anchor={anchor}
          portal={portal}
        >
          <div
            className={classNames(
              'py-3 px-4 rounded-xl shadow-2xl text-sm w-[200px] flex flex-col',
              { 'bg-white text-black': variant === Variant.DEFAULT },
              { 'bg-fadedRed text-black': variant === Variant.ERROR },
              { 'bg-white text-black': variant === Variant.INFO },
              panelClassName
            )}
          >
            {children}
          </div>
        </PopoverPanel>
      </Transition>
    </HeadlessUiPopover>
  )
}

import { calculateNotionalRatio, roundToDecimal } from '@optaxe/options-utils'
import { rfqAxeDialog_axeFragment$data } from '../../components/dialog/rfqAxeDialog/__generated__/rfqAxeDialog_axeFragment.graphql'
import { RFQLegFormValues } from '../../components/forms/rfq/rfqAxeFormWrapper/rfqAxeFormWrapper'

/**
 * If the underlying axe has been traded, we use the remaining notional to calculate the remaining notional for each leg
 * based on the ratio derived from the underlying axe leg notionals. Additionally, if the remaining notional is less
 * than the leg’s minimum notional, adjust the minimum notional accordingly. This adjustment is based on
 * the leg’s minimum notional and the same ratio.
 */
export function calculateAdjustedNotionalValues(
  axeLegs: rfqAxeDialog_axeFragment$data['axe_legs'],
  remainingNotional: number
) {
  const axeLeg1 = axeLegs[0]

  const notionals = axeLegs.map((leg) => leg.notional || 0)
  const notionalRatio = notionals.map(calculateNotionalRatio)

  const isMinNotionalMoreThanRemainingNotional =
    axeLeg1.minimumNotionalAmount > remainingNotional

  return {
    getLegNotional: (index: number) => {
      return roundToDecimal(remainingNotional * notionalRatio[index], 0)
    },
    getLegMinNotional: (index: number) => {
      return isMinNotionalMoreThanRemainingNotional
        ? roundToDecimal(remainingNotional * notionalRatio[index], 0)
        : axeLegs[index].minimumNotionalAmount
    }
  }
}

export type RowValues = {
  [key in keyof RFQLegFormValues]: RFQLegFormValues[key][]
}

/**
 * Groups values for each specified field across all legs into corresponding arrays. EG:
 * {
 *   expiryDate: ['2022-01-01', '2023-01-02'],
 *   notional: [1000000, 2000000],
 * }
 */
export function getRowValues(
  legs: RFQLegFormValues[],
  rowNames: Array<keyof RFQLegFormValues>
): RowValues {
  return legs.reduce(
    (acc, leg) => {
      rowNames.forEach((rowName) => {
        if (!acc[rowName]) {
          acc[rowName] = []
        }

        const value = leg[rowName]

        acc[rowName]?.push(value as string | number)
      })

      return acc
    },
    rowNames.reduce(
      (acc, rowName) => ({ ...acc, [rowName]: [] }),
      {} as RowValues
    )
  )
}

import get from 'lodash/get'
import set from 'lodash/set'
import { InputStateContextProps } from '../../../context/inputStateContext/inputStateContext'
import setSameAcrossAllLegs from './utils/setSameAcrossAllLegs/setSameAcrossAllLegs'
import { FormikContextType } from 'formik'
import {
  AxeFormData,
  AxeFormLegData
} from '../../dialog/updateAxeDialog/updateAxeDialog'
import { RFQFormValues } from '../../forms/rfq/rfqAxeFormWrapper/rfqAxeFormWrapper'
import processOnExpiryDateDiff from './utils/processOnExpiryDateDiff/processOnExpiryDateDiff'
import processOnForwardDiff from './utils/processOnForwardDiff/processOnForwardDiff'
import processOnSpotDiff from './utils/processOnSpotDiff/processOnSpotDiff'
import processOnSwapsDiff from './utils/processOnSwapsDiff/processOnSwapsDiff'
import merge from 'lodash/merge'
import isLegFieldPath, {
  LegFieldPath
} from '../utils/isLegFieldPath/isLegFieldPath'
import isNonLegFieldPath, {
  NonLegFieldPath
} from '../utils/isNonLegFieldPath/isNonLegFieldPath'
import {
  Enum_Axe_Hedgetype_Enum,
  Enum_Axe_Premiumtype_Enum
} from '../../../gql'
import processOnSpecifyDeltaHedgeDiff from './utils/processOnSpecifyDeltaHedgeDiff/processOnSpecifyDeltaHedgeDiff'
import { FormDataProcessorFormType } from '../formDataProcessor'
import processOnCcyPairDiff from './utils/processOnCcyPairDiff/processOnCcyPairDiff'
import processOnDeltaDiff from './utils/processOnDeltaDiff/processOnDeltaDiff'
import processOnPremiumDiff from './utils/processOnPremiumDiff/processOnPremiumDiff'
import processOnBuySellDiff from './utils/processOnBuySellDiff/processOnBuySellDiff'
import { setPartyBasedValue } from './utils/setPartyBasedValue/setPartyBasedValue'
import setMinimumNotionalAmounts from './utils/setMinimumNotionalAmounts/setMinimumNotionalAmounts'
import { rfqAxeDialog_axeFragment$data } from '../../dialog/rfqAxeDialog/__generated__/rfqAxeDialog_axeFragment.graphql'
import setSecondaryLegNotionalsBasedOnRatio from './utils/setSecondaryLegNotionalsBasedOnRatio/setSecondaryLegNotionalsBasedOnRatio'

type DataManipulationObject = {
  values: FormData
}

export type OnValuesDiffParams = {
  formik: {
    setErrors: FormikContextType<FormData>['setErrors']
    setTouched: FormikContextType<FormData>['setTouched']
    touched: FormikContextType<FormData>['touched']
    values: FormData
  }
  inputStateContext: InputStateContextProps
  path: NonLegFieldPath | LegFieldPath
  formType?: FormDataProcessorFormType
  isUserCounterParty: boolean
  axeData?: rfqAxeDialog_axeFragment$data | null
}

export type FormDataProcessorConfigField = {
  onValuesDiff?: (params: OnValuesDiffParams) => DataManipulationObject | null
}

export type FormDataProcessorConfig = Record<
  string,
  FormDataProcessorConfigField
>

export type FormData = AxeFormData | RFQFormValues

const config: FormDataProcessorConfig = {
  buySell: { onValuesDiff: processOnBuySellDiff },
  ccyPair: { onValuesDiff: processOnCcyPairDiff },
  product: { onValuesDiff: setSameAcrossAllLegs },
  expiryDate: { onValuesDiff: processOnExpiryDateDiff },
  spot: { onValuesDiff: processOnSpotDiff },
  swaps: { onValuesDiff: processOnSwapsDiff },
  forward: { onValuesDiff: processOnForwardDiff },
  volatility: {
    onValuesDiff: ({ formik: { touched, values }, path, formType }) => {
      if (!isLegFieldPath(path) || formType !== 'createAxe') return null

      const [arrayName, legIndex] = path

      // When the volatility value changes, update the pricingVolatility value unless the field has been touched.
      if (get(touched, [arrayName, legIndex, 'pricingVolatility'])) return null

      const valuesToUpdate = {} as AxeFormData

      set(
        valuesToUpdate,
        [arrayName, legIndex, 'pricingVolatility'],
        (values.legs[legIndex] as AxeFormLegData).volatility
      )

      return {
        values: valuesToUpdate
      }
    }
  },
  hedgeType: {
    onValuesDiff: (onValuesDiffParams) => {
      const {
        formik: { values },
        path
      } = onValuesDiffParams

      if (!isLegFieldPath(path)) return null

      const [arrayName] = path

      const valuesToUpdate = {} as AxeFormData

      // Set the hedgeType to be the same across all legs
      merge(valuesToUpdate, setSameAcrossAllLegs(onValuesDiffParams)?.values)

      // Set the premiumType for all legs to match the hedgeType value.
      // If the hedgeType is NDF, set the premiumType to Forward; otherwise, use the hedgeType value.
      const hedgeType = get(values, [arrayName, 0, 'hedgeType'])

      const premiumType =
        hedgeType === Enum_Axe_Hedgetype_Enum.Ndf
          ? Enum_Axe_Premiumtype_Enum.Forward
          : hedgeType

      values.legs.forEach((_, index) => {
        set(valuesToUpdate, [arrayName, index, 'premiumType'], premiumType)
      })

      return {
        values: valuesToUpdate
      }
    }
  },
  premiumType: {
    onValuesDiff: ({ formik: { values }, path }) => {
      if (!isNonLegFieldPath(path)) return null

      const valuesToUpdate = {} as AxeFormData

      // Changes to `premiumType` field should update individual `legs[x].premiumType` values.
      values.legs.forEach((_, legIndex) => {
        set(
          valuesToUpdate,
          ['legs', legIndex, 'premiumType'],
          (values as RFQFormValues).premiumType
        )
      })

      return {
        values: valuesToUpdate
      }
    }
  },
  specifyDeltaHedge: { onValuesDiff: processOnSpecifyDeltaHedgeDiff },
  minimumNotionalAmount: { onValuesDiff: setMinimumNotionalAmounts },
  notional: {
    onValuesDiff: (onValuesDiffParams) => {
      if (onValuesDiffParams.formType === 'rfq') {
        return setSecondaryLegNotionalsBasedOnRatio(onValuesDiffParams)
      } else {
        return setMinimumNotionalAmounts(onValuesDiffParams)
      }
    }
  },
  premiumCurrency: { onValuesDiff: setSameAcrossAllLegs },
  notionalCurrency: { onValuesDiff: setSameAcrossAllLegs },
  premium: { onValuesDiff: processOnPremiumDiff },
  delta: { onValuesDiff: processOnDeltaDiff },
  bsDelta: { onValuesDiff: processOnDeltaDiff },
  gamma: {
    onValuesDiff: (onValuesDiffParams) => {
      setPartyBasedValue(onValuesDiffParams)

      return null
    }
  },
  vega: {
    onValuesDiff: (onValuesDiffParams) => {
      setPartyBasedValue(onValuesDiffParams)

      return null
    }
  }
}

export default config

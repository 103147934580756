import deepDiff from 'deep-diff'

function isDiffNewArray(
  diff: deepDiff.Diff<unknown, unknown>,
  arrayName: string
): diff is deepDiff.DiffArray<unknown, unknown> {
  return (
    diff.kind === 'A' &&
    diff.path?.[0] === arrayName &&
    diff.item.kind === 'N' &&
    diff.path?.[1] === undefined
  )
}

export default isDiffNewArray

'use client'

import React from 'react'
import { motion, Variants } from 'framer-motion'
import { ButtonProps } from '../buttons/button/button'
import classNames from 'classnames'

const variants = {
  open: {
    opacity: 1,
    transition: {
      delay: 0.1,
      when: 'beforeChildren',
      staggerChildren: 0.3
    }
  },
  closed: {
    opacity: 0,
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
}

const buttonVariants = {
  open: (i: number) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.08
    }
  }),
  closed: { y: -6, opacity: 0 }
}

const animationProps = (animateEntry: boolean) => (variants: Variants) =>
  animateEntry
    ? {
        initial: 'closed',
        animate: 'open',
        variants: variants
      }
    : {}

interface ButtonGroupProps {
  className?: string
  animateEntry?: boolean
  buttons: Array<React.ReactElement<ButtonProps>>
}

export default function ButtonGroup({
  buttons,
  className,
  animateEntry = false
}: ButtonGroupProps) {
  const animation = animationProps(animateEntry)

  return (
    <motion.div
      className={classNames('flex flex-row gap-4', className)}
      {...animation(variants)}
    >
      {buttons?.map((element, index: number) => {
        return React.cloneElement(element, {
          key: index,
          custom: index,
          ...animation(buttonVariants)
        })
      })}
    </motion.div>
  )
}

import {
  ExclamationTriangleIcon,
  MinusCircleIcon,
  PlusIcon,
  CheckIcon
} from '@heroicons/react/20/solid'
import toast from 'react-hot-toast'
import { useMutation } from 'react-relay'
import Button, {
  ButtonSize,
  ButtonVariant
} from '../../../components/buttons/button/button'
import {
  OrganizationState,
  useTierSelectorContext
} from '../../../components/tierSelector/hooks/tierSelectorContext'
import { userTierComponentQuery$data } from './__generated__/userTierComponentQuery.graphql'
import { UserTierComponentMutation } from './userTierComponent'
import { Enum_Tiers_Enum } from '../../../gql'
import getIDFromBase64 from '../../../utils/getIDFromBase64/getIDFromBase64'
import { user_main_tiering_updates } from './__generated__/userTierComponentMutation.graphql'
import React, { useEffect } from 'react'
import TradersList from './tradersList'
import { useUser } from '@auth0/nextjs-auth0/client'

import { urlState } from '../../components/topLevelLeavePageConfirmation'
import { getSubOrgOnClient } from '../../../utils/getSubOrg/getSubOrg'
import { Tier } from '../../../components/tierSelector/tierSelector'

const organizationTierDiffStatement = (
  tierData: userTierComponentQuery$data,
  tierLists: Tier[],
  removed: OrganizationState[]
): user_main_tiering_updates[] => {
  const statement: user_main_tiering_updates[] = []

  tierData.user_main_tiering_connection.edges.forEach((edge) => {
    const node = edge.node
    const currentSubOrganizationId = node.subOrganizationId

    const previousUserTier: string = node.tier
    let currentTierForUser = undefined

    for (let index = 0; index < tierLists.length; index++) {
      for (const entity of tierLists[index].entities) {
        const userFound = entity.members.find(
          (member) => member.id === currentSubOrganizationId
        )

        if (userFound) {
          currentTierForUser = `t_${index + 1}`
          break
        }
      }

      if (currentTierForUser) {
        break
      }
    }

    const userIsRemoved = removed.find((entity) => {
      const userFound = entity.entities.find(
        (member) => member.id === currentSubOrganizationId
      )

      if (userFound) {
        return true
      }
    })

    if (
      (currentTierForUser && currentTierForUser !== previousUserTier) ||
      !!userIsRemoved !== node.isRemoved
    ) {
      statement.push({
        where: {
          id: {
            _eq: getIDFromBase64(node.id)
          }
        },
        _set: {
          tier: currentTierForUser as Enum_Tiers_Enum,
          isRemoved: !!userIsRemoved
        }
      })
    }
  })

  return statement
}

interface TopOfTierPageProps {
  colorScheme: string
  tierData: userTierComponentQuery$data
  tierLists: Tier[]
  setOpenRemovedTiers: React.Dispatch<React.SetStateAction<boolean>>
  setOpenSuspend: React.Dispatch<React.SetStateAction<boolean>>
  setOpenSendQuery: React.Dispatch<React.SetStateAction<boolean>>
  isSales: boolean
  onTraderSelect: (data: { id: string; fullName: string }) => void
}

export const TopOfTierPage = ({
  colorScheme,
  tierData,
  tierLists,
  setOpenRemovedTiers,
  setOpenSuspend,
  setOpenSendQuery,
  isSales,
  onTraderSelect
}: TopOfTierPageProps) => {
  const { user } = useUser()

  const {
    state: { removed }
  } = useTierSelectorContext()
  const [commitUpdateMutation, mutationInFlight] = useMutation(
    UserTierComponentMutation
  )
  const statement = organizationTierDiffStatement(tierData, tierLists, removed)

  useEffect(() => {
    urlState.isDirty = statement.length > 0
  }, [statement.length])

  return (
    <div className="flex px-4 pt-5 justify-between items-center">
      <span className="flex text-lg font-bold gap-4 items-center">
        <span>Counterparty Settings</span>
        {isSales && user && (
          <TradersList
            onTraderSelect={onTraderSelect}
            subOrganizationId={getSubOrgOnClient(user)}
          />
        )}
      </span>

      <span className="flex text-xs">
        {!isSales && (
          <Button
            size={ButtonSize.DEFAULT}
            className="flex font-normal justify-between items-center w-[175px]"
            styleVariant={
              colorScheme === 'DARK'
                ? ButtonVariant.ALERT
                : ButtonVariant.REPORT
            }
            onClick={() => {
              setOpenSuspend((openSuspend) => !openSuspend)
            }}
          >
            <ExclamationTriangleIcon width={'20px'} /> Suspend Member
          </Button>
        )}
        <Button
          size={ButtonSize.DEFAULT}
          className="flex font-normal w-[231px] justify-between items-center mx-2"
          onClick={() => {
            setOpenRemovedTiers((openRemovedTiers) => !openRemovedTiers)
          }}
        >
          <MinusCircleIcon width={'20px'} /> Removed entities/members
        </Button>
        <Button
          size={ButtonSize.DEFAULT}
          className="flex font-normal justify-between items-center w-[140px] mr-2"
          onClick={() => {
            setOpenSendQuery((openSendQuery) => !openSendQuery)
          }}
        >
          <PlusIcon width={'20px'} />
          Send Query
        </Button>
        <Button
          size={ButtonSize.DEFAULT}
          disabled={statement.length < 1 || mutationInFlight}
          className="flex font-normal justify-between items-center w-[150px]"
          onClick={() => {
            toast.promise(
              new Promise((resolve, reject) => {
                commitUpdateMutation({
                  variables: {
                    objects: statement
                  },
                  onError: (error) => {
                    console.error(error)
                    reject(error)
                  },
                  onCompleted: (value) => {
                    resolve(value)
                  }
                })
              }),
              {
                loading: 'Updating Tiers',
                success: 'Tiers updated',
                error: 'Update failed'
              }
            )
          }}
          type="submit"
        >
          <CheckIcon width={'20px'} />
          Save Changes
        </Button>
      </span>
    </div>
  )
}

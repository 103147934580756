import { InputProps } from '../../../../input/input'
import isNil from 'lodash/isNil'
import { AxeFormLegData } from '../../../../dialog/updateAxeDialog/updateAxeDialog'
import { RFQLegFormValues } from '../../../../forms/rfq/rfqAxeFormWrapper/rfqAxeFormWrapper'
import isLegFieldPath from '../../../utils/isLegFieldPath/isLegFieldPath'
import { OnValuesDiffParams } from '../../config'
import { invertSignUnlessInAuthorSubOrg } from '@optaxe/options-utils'

export function setPartyBasedValue(
  onValuesDiffParams: Pick<
    OnValuesDiffParams,
    'formik' | 'inputStateContext' | 'path' | 'isUserCounterParty'
  >
) {
  const { formik, inputStateContext, path, isUserCounterParty } =
    onValuesDiffParams

  if (!isLegFieldPath(path)) return null

  const [arrayName, legIndex, name] = path
  const key = `${arrayName}[${legIndex}].${name}` as InputProps['name']

  const value =
    formik.values.legs[legIndex][
      name as keyof (AxeFormLegData | RFQLegFormValues)
    ]

  if (isNil(value)) return null

  let partyBasedValue = value

  if (isUserCounterParty) {
    partyBasedValue = invertSignUnlessInAuthorSubOrg(
      value || 0,
      !isUserCounterParty
    )

    inputStateContext.dispatch({
      type: 'set_party_based_value',
      key,
      payload: partyBasedValue
    })
  }

  return partyBasedValue
}
